import styled from "styled-components";

export const Label = styled.div`
  width: 96px;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  gap: 4px;

  text-transform: uppercase;
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #abbadb;
`;

export const Root = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  background: ${({ active }) => (active ? "#00B2FF0D" : "#141722")};
  border: 1px solid ${({ active }) => (active ? "#00B2FF" : "#1b2035")};
  border-radius: 8px;
  height: 48px;

  ${Label} {
    border-right: 1px solid ${({ active }) => (active ? "#00B2FF" : "#1b2035")};
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background: #1b2035;
  border-radius: 50%;
  flex-shrink: 0;
`;
