import React, { useEffect, useMemo } from "react";
import { createContext, memo, PropsWithChildren, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { GenericState } from "types/games";
import { LS_NEWS_KEY } from "types/local-storage";
import { useLocalStorage } from "usehooks-ts";

export type IPageLayoutContext = {
  showNavSidebar: boolean;
  setShowNavSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  showChatSidebar: boolean;
  setShowChatSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  isReviewed: GenericState<boolean>;
};

export const PageLayoutInitialValues: IPageLayoutContext = {
  showNavSidebar: false,
  setShowNavSidebar: () => {},
  showChatSidebar: false,
  setShowChatSidebar: () => {},
  isReviewed: {
    value: false,
    setValue: () => {},
  },
};

const ShowPageLayoutContext = createContext<IPageLayoutContext>(PageLayoutInitialValues);

const ShowPageLayoutProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1400px)",
  });

  const [showNavSidebar, setShowNavSidebar] = useState<boolean>(false);
  const [showChatSidebar, setShowChatSidebar] = useState<boolean>(false);
  const [isReviewedValue, seIsReviewed] = useLocalStorage(LS_NEWS_KEY, false, {
    initializeWithValue: true,
  });

  useEffect(() => {
    setShowNavSidebar(isDesktop);
  }, [isDesktop]);

  const isReviewed = useMemo<GenericState<boolean>>(() => {
    return {
      value: isReviewedValue,
      setValue: seIsReviewed,
    };
  }, [isReviewedValue]);

  return (
    <ShowPageLayoutContext.Provider
      value={{
        showNavSidebar,
        setShowNavSidebar,
        showChatSidebar,
        setShowChatSidebar,
        isReviewed,
      }}
    >
      {children}
    </ShowPageLayoutContext.Provider>
  );
};

export function useShowPageLayoutContext() {
  const context = React.useContext(ShowPageLayoutContext);
  if (context === undefined) {
    throw new Error("usePageLayoutContext must be used within a ShowPageLayoutContext");
  }
  return context;
}

const ShowPageLayoutProviderMemo = memo(ShowPageLayoutProvider);

export default ShowPageLayoutProviderMemo;
