import React from "react";
import ReactCodeInput from "react-code-input";

interface CodeInputProps {
  isValid: boolean;
  setPayload: (value: string) => void;
  payload: string;
}

const CodeInput: React.FC<CodeInputProps> = ({ isValid, setPayload, payload }) => {
  return (
    <ReactCodeInput
      name="input"
      inputMode="numeric"
      type="number"
      inputStyle={{
        fontFamily: "monospace",
        margin: "4px",
        height: "55px",
        width: "55px",
        backgroundColor: "#0b101b",
        color: "#0c8ce9",
        borderRadius: "8px",
        border: "1px solid #0c8ce9",
        textAlign: "center",
        outline: "none",
        fontSize: 30,
      }}
      inputStyleInvalid={{
        fontFamily: "monospace",
        margin: "4px",
        MozAppearance: "textfield",
        height: "55px",
        width: "55px",
        backgroundColor: "#0b101b",
        color: "#0c8ce9",
        borderRadius: "8px",
        border: "1px solid red",
        textAlign: "center",
        outline: "none",
        fontSize: 30,
      }}
      isValid={isValid}
      fields={6}
      onChange={(value) => setPayload(value)}
      value={payload}
    />
  );
};

export default CodeInput;
