import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles } from "./$styles";

interface IHr {
  gap?: number | string | null;
  width?: number | string | null;
  height?: number | string | null;
}

interface IHrProps extends IHr {
  text?: JSX.Element | string | null;
}

/**
 * Create horizontal delimiter
 *
 * @param text? JSX.Element | string | null
 * @param width? number | string | null
 * @param height? number | string | null
 * @returns React.ReactElement
 *
 */
export const Hr: React.FC<IStyles & IHrProps> = ({
  text,
  gap,
  width,
  height = 1,
  ...props
}): React.ReactElement => {
  const hrProps = { height, width };
  const hr = <Line {...hrProps} />;
  if (text) {
    return (
      <Box {...props}>
        {hr}
        <Title>{text}</Title>
        {hr}
      </Box>
    );
  }
  return <Box {...props}>{hr}</Box>;
};

const Box = styled.div<IStyles & IHr>`
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  ${({ gap: _ }) =>
    typeof _ === "string"
      ? css`
          gap: ${_};
        `
      : typeof _ === "number"
      ? css`
          gap: ${_}px;
        `
      : css`
          gap: 16px;
        `}
  padding: 0 4px;
  ${({ width: _ }) =>
    typeof _ === "string"
      ? css`
          width: ${_};
        `
      : typeof _ === "number"
      ? css`
          width: ${_}px;
        `
      : null}
  ${({ styles }) => styles}
`;

const Line = styled.div<IStyles & IHr>`
  box-sizing: border-box;
  flex-grow: 1;
  flex-shrink: 1;
  display: inline-block;
  ${({ height: _ }) =>
    typeof _ === "string"
      ? css`
          height: ${_};
        `
      : typeof _ === "number"
      ? css`
          height: ${_}px;
        `
      : null}
  background-color: rgba(218, 248, 255, 0.05);
  ${({ styles }) => styles}
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const Title = styled(Text)`
  flex-grow: 0;
  flex-shrink: 0;
  display: inline-block;
`;
