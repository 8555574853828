import { SVGProps } from "react";

export type ProfileProps = SVGProps<SVGSVGElement>;

const Profile: React.FC<ProfileProps> = (props) => {
    return (
        <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="fi_666201">
                <g id="Group">
                    <g id="Group_2">
                        <g id="Group_3">
                            <g id="Vector">
                                <path d="M12.0013 12.347C14.5548 12.347 16.6422 10.2449 16.6422 7.67348C16.6422 5.10203 14.5548 3 12.0013 3C9.44774 3 7.36038 5.10203 7.36038 7.67348C7.36038 10.2449 9.44777 12.347 12.0013 12.347Z" fill={props.fill ?? "#ABBADB"}/>
                                <path d="M19.9659 16.0816C19.8443 15.7755 19.6821 15.4898 19.4998 15.2245C18.5675 13.8367 17.1286 12.9184 15.5074 12.6939C15.3047 12.6735 15.0818 12.7143 14.9196 12.8367C14.0685 13.4694 13.0552 13.7959 12.0013 13.7959C10.9475 13.7959 9.93417 13.4694 9.08299 12.8367C8.92084 12.7143 8.69791 12.653 8.49527 12.6939C6.87398 12.9184 5.41484 13.8367 4.50287 15.2245C4.32048 15.4898 4.15834 15.7959 4.03677 16.0816C3.97598 16.2041 3.99623 16.347 4.05702 16.4694C4.21916 16.7551 4.4218 17.0409 4.6042 17.2857C4.88791 17.6735 5.19191 18.0204 5.53645 18.347C5.82016 18.6327 6.14441 18.898 6.4687 19.1633C8.0697 20.3674 9.99499 21 11.9811 21C13.9671 21 15.8924 20.3673 17.4934 19.1633C17.8177 18.9184 18.1419 18.6327 18.4257 18.347C18.7499 18.0204 19.0742 17.6735 19.3579 17.2857C19.5606 17.0204 19.743 16.7551 19.9051 16.4694C20.0064 16.347 20.0266 16.2041 19.9659 16.0816Z" fill={props.fill ?? "#ABBADB"}/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default Profile;
