import React, { useCallback, useState } from "react";
import {
  RateBlock,
  Title,
  SubTitle,
  HeaderBlock,
  Modal,
  RateBanner,
  RateItem,
  Equal,
  CoinTitle,
  CoinValue,
  ModalBody,
  ExchangeFieldSet,
  Label,
  InputWrapper,
  BalanceCard,
  Input,
  Balance,
  Button,
  RateTitle,
} from "./styles";
import Image from "next/image";
import { ButtonBlue, Flex } from "components/common";
import {  useAppContext } from "contexts";
import { ThePointsBalance } from "components/TheComponents/ThePointsBalance";
import { triggerNotification } from "components/TheComponents/Notification";

const ExchangeModal: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const { accessFetch, accessURL, profile, rates, setUserBalance } = useAppContext();

  const [value, setValue] = useState<number>(0);
  const rate = rates?.point_gpoint_rate ?? 0;

  const exchange = useCallback(
    async (amount: number) => {
      try {
        const url = accessURL(`/balance/exchange`) as URL;
        const response = await accessFetch(url.href, {
          method: "POST",
          body: JSON.stringify({
            amount,
            source: "POINTS",
            dest: "G_POINT",
          }),
        });
        const data = await response?.json();

        if (data.destBalance) {
          setUserBalance({
            gPointsBalance: data.destBalance.amount,
            pointsBalance: data.sourceBalance.amount,
          });
          onClose();
        } else {
          const message = data.code ? data.message : "Something went wrong. Try later";
          triggerNotification({ type: "error", text: message });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [accessFetch, accessURL, onClose, setUserBalance]
  );

  return (
    <Modal>
      <HeaderBlock>
        <Image src="/images/coins/w-g-coin.svg" alt="w-g-swap" width={92} height={92} />
        <Title>Points exchange</Title>
      </HeaderBlock>
      <SubTitle>Exchange your W-POINTS for G-POINTS to play the wrewards modes</SubTitle>
      <Flex justify="center" width="100%" gap={3}>
        <Image src="/images/PointShopComponent/warning.svg" alt="warning" width={20} height={20} />
        <SubTitle color="orange">This is a one way exchange W-POINTS to G-POINTS</SubTitle>
      </Flex>
      <RateBlock>
        <RateTitle style={{ margin: 10 }}>exchange rate</RateTitle>
        <RateBanner>
          <RateItem>
            <Image src="/images/coins/w-coin.svg" alt="w-coin" width={24} height={24} />
            <CoinTitle>
              <CoinValue>1</CoinValue>
              <div>w-point</div>
            </CoinTitle>
          </RateItem>
          <Equal>=</Equal>
          <RateItem>
            <Image src="/images/coins/g-coin.svg" alt="g-coin" width={24} height={24} />
            <CoinTitle>
              <CoinValue>{1 * rate}</CoinValue>
              <div>g-point</div>
            </CoinTitle>
          </RateItem>
        </RateBanner>
      </RateBlock>
      <ModalBody>
        <ExchangeFieldSet>
          <Label>you give</Label>
          <InputWrapper active={true}>
            <Image src="/images/coins/w-coin.svg" alt="w-coin" width={24} height={24} />
            <Input
              type="text"
              min="0"
              step="1"
              pattern="[0-9]"
              value={value}
              onChange={(e) => {
                const value = e.target.value;
                const regex = /^[0-9]+$/;
                if (regex.test(value)) {
                  setValue(parseInt(e.target.value));
                }
                if (value === "") {
                  setValue(0);
                }
              }}
            />
            <BalanceCard>
              balance <Image src="/images/coins/w-coin.svg" alt="w-coin" width={16} height={16} />{" "}
              <Balance>
                <ThePointsBalance value={profile?.pointsBalance} isFixed />
              </Balance>
            </BalanceCard>
          </InputWrapper>
        </ExchangeFieldSet>
        <ExchangeFieldSet>
          <Label>you WILL RECEIVE</Label>
          <InputWrapper>
            <Image src="/images/coins/g-coin.svg" alt="g-coin" width={24} height={24} />
            <Input disabled={true} type="number" value={Number(value) * rate} />
            <BalanceCard>
              balance <Image src="/images/coins/g-coin.svg" alt="g-coin" width={16} height={16} />
              <Balance>
                <ThePointsBalance value={profile?.gPointsBalance} />
              </Balance>
            </BalanceCard>
          </InputWrapper>
        </ExchangeFieldSet>
      </ModalBody>
      <Button
        onClick={() => {
          const amount = Number(value);
          const isValid = amount > 0;
          const isEnoughBalance = amount <= Number(profile?.pointsBalance);
          if (isValid && isEnoughBalance) {
            exchange(amount);
          } else {
            if (!isEnoughBalance) {
              triggerNotification({ text: "Not enough points", type: "error" });
            } else {
              triggerNotification({ text: "Invalid amount", type: "error" });
            }
          }
        }}
      >
        <ButtonBlue>confirm</ButtonBlue>
      </Button>
    </Modal>
  );
};

export default ExchangeModal;
