import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "contexts";
import { ExceptionHandlingType } from "types/apiServiceTypes";

function usePachicoStats() {
  //   const { take = 10, skip = 0 } = props ?? {};
  const { apiService } = useAppContext();

  return useQuery<{
    totalPrize: number;
    count: number;
  }>({
    queryKey: ["pachiko-winners-stats"],
    queryFn: async () => {
      const response = await apiService.request(
        `/pachinko-winner/total-and-count`,
        {},
        ExceptionHandlingType.AUTOMATIC
      );
      const data = await response?.json();

      return data?.count ? data : { totalPrize: 0, count: 0 };
    },
    initialData: { totalPrize: 0, count: 0 },
  });
}

export default usePachicoStats;
