import { SVGProps } from "react";

export type RouletteProps = SVGProps<SVGSVGElement>;

const Roulette: React.FC<RouletteProps> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="roulette-3 1">
        <path
          id="Vector"
          d="M11.4118 9.52402C7.44199 9.61626 4.35294 10.6446 4.35294 12.3103C4.35294 14.0575 7.75138 15.1034 12 15.1034C16.2486 15.1034 19.6471 14.0575 19.6471 12.3103C19.6471 10.6446 16.558 9.61626 12.5882 9.52402V7.97234C17.8204 8.09417 22 9.83673 22 12.3103C22 14.876 17.5036 16.6552 12 16.6552C6.4964 16.6552 2 14.876 2 12.3103C2 9.83673 6.17959 8.09417 11.4118 7.97234V9.52402ZM22 16.6552C22 19.2208 17.5036 21 12 21C6.4964 21 2 19.2208 2 16.6552V14.5524C3.65401 16.4659 7.46607 17.5862 12 17.5862C16.5339 17.5862 20.346 16.4659 22 14.5524V16.6552ZM11.4118 12C11.4118 12.3428 11.6751 12.6207 12 12.6207C12.3249 12.6207 12.5882 12.3428 12.5882 12V10.7663C14.209 10.8086 15.7037 11.0245 16.8266 11.3701C17.9062 11.7023 18.4706 12.113 18.4706 12.3103C18.4706 12.5077 17.9062 12.9184 16.8266 13.2506C15.569 13.6377 13.8451 13.8621 12 13.8621C10.1549 13.8621 8.43102 13.6377 7.17336 13.2506C6.09379 12.9184 5.52941 12.5077 5.52941 12.3103C5.52941 12.113 6.09379 11.7023 7.17336 11.3701C8.29632 11.0245 9.79101 10.8086 11.4118 10.7663V12ZM12 7.96552C11.8026 7.96552 11.6064 7.9678 11.4118 7.97234V6.61818C10.7264 6.36255 10.2353 5.67283 10.2353 4.86207C10.2353 3.83368 11.0254 3 12 3C12.9746 3 13.7647 3.83368 13.7647 4.86207C13.7647 5.67283 13.2736 6.36255 12.5882 6.61818V7.97234C12.3936 7.9678 12.1974 7.96552 12 7.96552Z"
          fill={`
            ${props.fill ?? "#ABBADB"}
          `}
        />
      </g>
    </svg>
  );
};

export default Roulette;
