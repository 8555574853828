import React from "react";
import Image from "next/image";
import styled, { css } from "styled-components";
import { $styles, Button, ButtonBlue, Flex, IStyles } from "../common";
import { TheFieldset, TheIcon } from ".";
import GalleryDropdown from "../common/GalleryDropdown";
import { useDropzone } from "react-dropzone";

export type TheImageInputProps = {
  imageUrl: string;
  setImageUrl: (value: string) => void;
  setFile: (file: File) => void;
  errors?: any;
};

const TheImageInput: React.FC<TheImageInputProps> = ({
  imageUrl,
  setImageUrl,
  setFile,
  errors,
}) => {
  const [filePreview, setFilePreview] = React.useState<string | null>(null);
  const numProps = { pattern: "^[d]*$", type: "text" };
  const { acceptedFiles, open, getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    onDrop: (acceptedFiles) => {
      setFilePreview(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }).preview
      );
    },
    maxFiles: 1,
  });

  React.useEffect(() => {
    setFile(acceptedFiles[0]);
  }, [setFile, acceptedFiles]);

  return (
    <>
      <Title>IMAGE</Title>
      <BoxItem style={{ marginBottom: 20 }}>
        <Flex width="100%">
          <div
            {...getRootProps({ className: "dropzone" })}
            style={{ width: "30%", height: "112px" }}
          >
            <input {...getInputProps()} />
            {(filePreview && <Image src={filePreview} alt="image" width={120} height={112} />) ||
              (imageUrl && (
                <div
                  style={{
                    width: 120,
                    height: 112,
                    backgroundImage: `url(${imageUrl})`,
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: " center 20%",
                    backgroundClip: "border-box",
                    borderRadius: 8,
                  }}
                />
              )) || (
                <div
                  style={{
                    width: 120,
                    height: 112,
                    backgroundColor: "#101622",
                    borderRadius: 8,
                  }}
                />
              )}
          </div>
          <Flex style={{ width: "70%" }} column>
            <TheFieldset
              valid={!errors?.imageUrl}
              styles={css`
                width: 100%;
                margin: 0;
              `}
              title="Image"
            >
              <input
                {...numProps}
                placeholder={errors?.imageUrl ?? "Image (Link)"}
                value={imageUrl}
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </TheFieldset>
            <Flex width="100%">
              <BoxItem
                styles={css`
                  margin-top: 8px;
                  width: 80%;
                `}
              >
                <Button
                  styles={css`
                    height: 50px;
                    background: #181f2e;
                    color: #99abbd;
                    box-shadow: none;
                  `}
                  onClick={open}
                  isDark
                >
                  <ButtonBlue isDark>Choose file</ButtonBlue>
                </Button>
              </BoxItem>

              <BoxItem
                styles={css`
                  margin-top: 8px;
                  width: 20%;
                `}
              >
                <Button
                  onClick={() => {
                    setFilePreview(null);
                  }}
                  style={{ padding: "6px 16px", height: 50, marginLeft: 10 }}
                  isDark
                >
                  <TheIcon icon="action:remove" size={16} />
                </Button>
              </BoxItem>
            </Flex>
            <GalleryDropdown
              onSelect={(value: string) => {
                setImageUrl(value);
                setFilePreview(value);
              }}
            />
          </Flex>
        </Flex>
      </BoxItem>
    </>
  );
};

export default TheImageInput;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;

  ${$styles}
`;

export const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: var(--font-family-golos);
  font-size: 18px;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;
