import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  padding: 32px;
`;

export const Title = styled.h1`
  font-family: Exo;
  font-size: 22px;
  font-style: italic;
  font-weight: 800;
  line-height: 26px;
  color: var(--daylight-daylight-main, #d5e0f5);
  text-align: center;
`;

export const Description = styled.span`
  font-family: Golos Text;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  text-align: center;
  color: #abbadb;
`;
