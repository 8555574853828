import { useCallback, useMemo } from "react";
import { MAX_BET_VALUE, MIN_BET_VALUE } from "types/games/constants";

export default function useWrewardsNumbers(): {
  getValueNormalized: (value: number, min?: number, max?: number) => number;
  formatNumber: (value: number) => string;
  roundValue: (value: number | string) => number;
} {
  const roundValue = useCallback((value: number | string) => {
    const input = Number(value);
    return Math.round(input * 100) / 100;
  }, []);

  const getValueNormalized = useCallback(
    (value: number, min: number = MIN_BET_VALUE, max: number = MAX_BET_VALUE): number => {
      const normalizedValue = value < min ? min : value > max ? max : value;
      return roundValue(normalizedValue);
    },
    [roundValue]
  );

  const formatNumber = useCallback((value: number | string) => {
    return new Intl.NumberFormat("en-US", {
      style: "decimal",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Number(value));
  }, []);

  return {
    getValueNormalized,
    roundValue,
    formatNumber,
  };
}
