import { useAppContext } from "contexts";
import { useQuery } from "@tanstack/react-query";
import { ExceptionHandlingType } from "types/apiServiceTypes";

export default function useLeaderboardTotalPrize() {
  const { apiService } = useAppContext();

  return useQuery<number>({
    queryKey: ["leaderboard", "total-prize"],
    queryFn: async () => {
      const response = await apiService.request(
        `/leaderboard/total-prize`,
        undefined,
        ExceptionHandlingType.AUTOMATIC
      );
      const data = await response?.json();
      if (!response?.ok) throw new Error(data);

      return data > 1000 ? `${Math.floor(data / 1000)}K` : data.toNumber();
    },
    retry: false,
  });
}
