import WrewardsButton from "components/wrewards-ui-kit/Button";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import Plus from "assets/notifications/plus.svg";
import Info from "assets/notifications/info.svg";
import { useCallback, useMemo } from "react";
import { NotificationCardProps } from "types/notification";
import { useRouter } from "next/router";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";

const CustomNotificationCard = (props: NotificationCardProps) => {
  const router = useRouter();
  const { setShowChatSidebar } = useShowPageLayoutContext();

  const { title, info, description, onActionButtonClick, containerStyles } = props;

  const titleParts = useMemo(() => {
    const parts = title?.split(" ");
    let first = parts ? parts[0] : "";
    let second = parts ? parts.slice(1).join(" ") : "";

    return [first, second];
  }, [title]);

  const onActionButtonClickInner = useCallback(() => {
    if (info.onActionClickLink) {
      onActionButtonClick && onActionButtonClick();
      router.push(info.onActionClickLink);
      setShowChatSidebar(false);
    }
  }, [info.onActionClickLink, onActionButtonClick, router, setShowChatSidebar]);

  return (
    <NotificationCardContainer styles={containerStyles}>
      <NotificationCardHeader>
        {Info()}
        <NotificationTitleContainer>
          <span>
            {titleParts[0]} {titleParts[1]}
          </span>
        </NotificationTitleContainer>
      </NotificationCardHeader>
      <NotificationCardContent>{description}</NotificationCardContent>
      {info.onActionClickLink && (
        <CustomWrewardsButton onClick={onActionButtonClickInner} variant="copper">
          {Plus()}
          {info.actionButtonText}
        </CustomWrewardsButton>
      )}
    </NotificationCardContainer>
  );
};
export default CustomNotificationCard;

const CustomWrewardsButton = styled(WrewardsButton)`
  padding: 8px;
  color: var(--daylight-color);
  font-family: var(--font-family-golos);
  font-size: 12px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: center;
  width: 100%;
  gap: 8px;
  text-transform: capitalize;
`;

const NotificationCardContent = styled.div`
  flex-grow: 1;
  color: var(--daylight-75-color);
  font-size: 14px;
`;

const NotificationCardContainer = styled.div<{ styles?: FlattenSimpleInterpolation }>`
  background: radial-gradient(
    70.8% 93.57% at 0% 100%,
    rgba(213, 163, 136, 0.45) 0%,
    rgba(177, 98, 19, 0.3375) 55%,
    rgba(149, 81, 39, 0) 170%
  );

  gap: 16px;
  height: 250px;
  width: 100%;
  min-height: 250px;
  min-width: 230px;
  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `}
`;

const NotificationCardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  & > svg {
    min-height: 26px;
    min-width: 26px;
  }
`;

const NotificationTitleContainer = styled.div`
  font-family: var(--font-family-exo);
  font-size: 16px;
  font-style: italic;
  font-weight: 800;
  line-height: 20.8px;
  text-align: left;
  text-transform: uppercase;
  color: var(--copper-color);
`;
