import React from "react";
import styled, { css } from "styled-components";
import { ITheModalBox } from "interfaces";
import { Flex } from "components/common";

const Box = styled.div<ITheModalBox>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  align-items: center;

  ${({ hasBlur }) =>
    hasBlur
      ? css`
          backdrop-filter: blur(10px);
        `
      : null}

  ${({ isFixed }) =>
    isFixed
      ? css`
          justify-content: center;
          position: fixed;
          left: 0;
          top: 0;
          min-height: 100vh;
          min-width: 100vw;
        `
      : css`
          padding: 32px 0;
          justify-content: flex-start;
          position: absolute;
          left: 0;
          top: 0;
          min-height: 100vh;
          min-width: 100vw;
          height: 100%;
          width: 100%;
        `}

	${({ zIndex }) =>
    typeof zIndex === "number"
      ? css`
          z-index: ${zIndex};
        `
      : null}

	${({ styles }) => styles}
`;

const Modal = styled.div<ITheModalBox>`
  box-sizing: border-box;
  position: relative;
  display: block;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;

  ${({ hasBorder, showClose }) =>
    hasBorder
      ? css`
          padding: 40px;
          padding-top: ${!!showClose ? "0px" : "40px"};
          background: radial-gradient(
              152.76% 130.7% at 50% 0%,
              rgba(101, 101, 101, 0.05) 0%,
              rgba(101, 101, 101, 0) 100%
            ),
            #101622;
          border: 1px solid rgba(210, 234, 255, 0.05);
          border-radius: 8px;
          color: rgba(210, 234, 255, 0.75);
        `
      : null}

  ${({ height }) =>
    typeof height === "number"
      ? css`
          height: ${height}px;
        `
      : typeof height === "string"
      ? css`
          height: ${height};
        `
      : null}

	${({ width }) =>
    typeof width === "number"
      ? css`
          width: ${width}px;
        `
      : typeof width === "string"
      ? css`
          width: ${width};
        `
      : null}

	${({ styles }) => styles}
`;

const Close = styled.div`
  --size: 14px;
  box-sizing: border-box;
  // position: sticky;
  top: 0px;
  display: inline-block;
  width: var(--size);
  height: var(--size);
  background-image: url("/images/TheModalBox/close.svg");
  background-size: var(--size);
  background-repeat: no-repeat;
  background-position: center;
  background-clip: border-box;
  cursor: pointer;
`;

export function TheModalBox({
  id,
  children,
  hasBlur = true,
  hasBorder = true,
  isFixed = true,
  zIndex = 1400,
  width,
  height,
  showClose = true,
  onClose,
  boxProps,
  ...props
}: ITheModalBox): React.ReactElement {
  const boxRef = React.useRef<HTMLDivElement | null>(null);
  const onClick = React.useMemo(() => {
    return (checkTarget: boolean) => (event: React.MouseEvent) => {
      event.preventDefault();
      if (!checkTarget || event.target === boxRef.current) {
        onClose && onClose();
      }
    };
  }, [onClose, boxRef]);

  const boxPropsNew = {
    id,
    hasBlur,
    isFixed,
    zIndex,
    ...boxProps,
  };

  const modalProps = {
    width,
    height,
    showClose,
    hasBorder,
    ref: boxRef,
    ...props,
  };

  return (
    <>
      <Box {...boxPropsNew}>
        <Modal {...modalProps}>
          {hasBorder && showClose ? (
            <Flex
              style={{
                position: "sticky",
                top: 0,
                zIndex: 10,
                background: "#131925",
                width: "100%",
              }}
              justify="flex-end"
              height="50px"
              align="center"
            >
              <Close onClick={onClick(false)} />
            </Flex>
          ) : null}
          {children}
        </Modal>
      </Box>
    </>
  );
}
