import React from "react";
import { $styles, $stylesReset, Flex, IStyles } from "components/common";
import { TheButton } from "components/TheComponents";
import styled, { css } from "styled-components";
import { IAppContext, withApp } from "contexts";
import MFAModal from "components/ScreenComponent/MFAModals/MFAModal";
import QRСodeModal from "components/ScreenComponent/MFAModals/QRСodeModal";
import { triggerNotification } from "components/TheComponents/Notification";

interface SiteRewardsDeleteModalProps {
  onClose: (action: string) => void;
  onDelete: (action: string) => void;
  selectedRewardId: string;
}

const SiteRewardsDeleteModal: React.FC<SiteRewardsDeleteModalProps & IAppContext> = ({
  accessFetch,
  onClose,
  onDelete,
  profile,
  showQrModal,
  setShowQrModal,
  selectedRewardId,
}): React.ReactElement => {
  const isMfaEnabled = !!profile?.mfaSecret;
  const [showVerifyModal, setShowVerifyModal] = React.useState<boolean>(false);

  const deleteReward = async ({ otp }: { otp: string }) => {
    try {
      if (selectedRewardId) {
        const response = await accessFetch(`/site-rewards/${selectedRewardId}`, {
          method: "DELETE",
          headers: {
            "x-otp": otp,
          },
        });
        const data = await response?.json();
        if (response && (response.status === 200 || response.status === 201)) {
          triggerNotification({
            text: "Site Reward Deleted!",
            type: "success",
          });
          onDelete("delete");
        } else {
          triggerNotification({ text: data?.message, type: "error" });
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };

  //

  return (
    <>
      <Flex justify="center" column>
        <Title>Are you sure you want to delete this site reward?</Title>
        <Text>
          You are about to delete this site reward permanently. <br />
          This action cannot be undone. Are you absolutely sure you want to proceed?
        </Text>
        <Flex style={{ marginTop: 15 }} width="100%" justify="space-between">
          <BoxItem
            styles={css`
              margin-top: 8px;
              margin-right: 12px;
            `}
          >
            <CancelButton onClick={() => onClose("delete")}>Cancel</CancelButton>
          </BoxItem>
          <BoxItem
            styles={css`
              margin-top: 8px;
            `}
          >
            <TheButton
              preset="blue"
              width={"100%"}
              onClick={(e) => {
                e.preventDefault();
                if (isMfaEnabled) {
                  setShowVerifyModal(true);
                } else {
                  setShowQrModal(true);
                }
              }}
            >
              Delete
            </TheButton>
          </BoxItem>
        </Flex>
      </Flex>
      {isMfaEnabled && showVerifyModal && (
        <MFAModal onVerify={deleteReward} onClose={() => setShowVerifyModal(false)} />
      )}
      {showQrModal && <QRСodeModal onClose={() => setShowQrModal(false)} />}
    </>
  );
};

export default withApp(SiteRewardsDeleteModal);

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;

const CancelButton = styled.div`
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-flow: row;
  -ms-flex-flow: row;
  flex-flow: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 8px;
  width: 100%;
  height: 50px;
  padding: 10px 32px;
  border-radius: 8px;
  line-height: 150%;
  font-weight: 600;
  cursor: pointer;
  border: 1px #47d3f2 solid;
  background: linear-gradient(265.52deg, #4796f2, #47d3f2);
  background-clip: text;
  -webkit-background-clip: text; /* For Safari/WebKit browsers */
  color: transparent;
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: var(--font-family-golos);
  font-size: 22px;
  text-align: center;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;
const Text = styled.div<IStyles>`
  ${$stylesReset}
  display: inline-block;
  color: #a2b5c8;
  margin: 5px 0px;
  font-size: 13px;
  text-align: center;
  font-family: var(--font-family-golos);
  line-height: 16px;
  text-transform: uppercase;
  ${$styles}
`;
