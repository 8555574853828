export interface LeaderboardPrize {
  prizeNumber: number;
  amount: number;
}

export enum LeaderboardPrizeSelectionType {
  TIERED = 'TIERED',
  RAFFLE = 'RAFFLE'
};


export interface IDaoLeaderboard {
  id?: string;
  casino: string;
  startDate: string | null;
  duration: string;
  maxPublicUsersCount: string;
  prize1: string;
  prize2: string;
  prize3: string;
  randomPrizeThreshold: string;
  randomPrizes: LeaderboardPrize[];
  additionalPrizes: LeaderboardPrize[];
  prizeSelectionType: LeaderboardPrizeSelectionType;
  ticketRate: number;
}
