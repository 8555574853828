import { SVGProps } from "react";

export type SevenProps = SVGProps<SVGSVGElement>;

const Seven: React.FC<SevenProps> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.4207 22H5.68199V21.4156C5.68199 19.742 6.08234 18.0144 6.87199 16.2808C7.49613 14.9105 8.36398 13.533 9.45148 12.1865C10.2344 11.2171 11.0133 10.408 11.6562 9.79355C11.308 9.81925 10.9551 9.83219 10.5984 9.83219C8.99645 9.83219 7.4541 9.56653 6.15789 9.07511V10.2269H2V2.14963H6.15785V2.90029C7.45398 2.40883 8.99637 2.14322 10.5984 2.14322C12.2087 2.14322 13.3873 2.36059 14.4197 2.84806C15.1398 3.18814 15.9341 3.32073 16.7164 3.23127C17.5016 3.14161 18.2391 2.83181 18.849 2.33539L19.2613 2L22 3.85837L21.662 4.34381C21.6438 4.36999 19.8191 7.00086 18.0203 10.4253C16.3782 13.5515 14.4206 17.9959 14.4206 21.4156V22H14.4207Z"
        fill={props.fill ?? "#E9F4FF"}
      />
    </svg>
  );
};

export default Seven;
