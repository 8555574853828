import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import { IStyles } from "components";
import { useAppContext } from "contexts";
import { IDtoLeaderboard, IDtoLeaderboardUser } from "interfaces";
import { triggerNotification } from "components/TheComponents/Notification";
import { Button, ButtonBlue } from "components/common";
import VerifierProvider, { useVerifierContext } from "contexts/VerifierProvider";

export interface ILeaderboardUnbanFormProps {
  leaderboard: IDtoLeaderboard;
  leaderboardUser: IDtoLeaderboardUser;
  onUnban: () => void;
  onClose: () => void;
}

const LeaderboardUnbanForm: React.FC<ILeaderboardUnbanFormProps> = ({
  leaderboard,
  leaderboardUser,
  onUnban,
  onClose,
}) => {
  const { accessFetch, accessURL } = useAppContext();
  const { verify } = useVerifierContext();

  const [hasError, setHasError] = React.useState<string | null>(null);

  const unbanUser = async ({ otp }: { otp: string }) => {
    try {
      const leaderboardId = leaderboard.id;
      const refereeId = leaderboardUser.refereeId;
      const url = accessURL(`/leaderboard/${leaderboardId}/${refereeId}/unbanned`) as URL;
      const response = await accessFetch(url.href, {
        method: "POST",
        headers: {
          "x-otp": otp,
        },
      });
      const json = await response?.json();
      if (response && (response.status === 200 || response.status === 201)) {
        triggerNotification({ text: "User Unbanned!", type: "success" });
        onUnban();
      } else {
        setHasError(json.message);
        triggerNotification({ text: json?.message, type: "error" });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Box>
      <BoxItem
        styles={css`
          margin-bottom: 16px;
        `}
      >
        <Text1>
          <Image src="/images/logos/logo.svg" width={170} height={32} alt="Logo" />
        </Text1>
      </BoxItem>
      <BoxItem>
        <Text1>
          Are you sure you want to unban <strong>{leaderboardUser?.displayName ?? "?"}</strong> ?
        </Text1>
      </BoxItem>
      <BoxItem>
        <Text2>
          It was banned because of the reason:{" "}
          <strong>{leaderboardUser?.bannedReason ?? "?"}</strong> ?
        </Text2>
      </BoxItem>
      <BoxItem>{hasError ? <Warn>{hasError}</Warn> : null}</BoxItem>
      <BoxItem>
        <Actions>
          <Button
            styles={css`
              width: 150px;
            `}
            isDark
            onClick={onClose}
          >
            <ButtonBlue isDark>Cancel</ButtonBlue>
          </Button>

          <Button
            styles={css`
              width: 150px;
            `}
            onClick={(e) => {
              e.preventDefault();
              verify(({ otp }) => unbanUser({ otp }));
            }}
          >
            <ButtonBlue>Ban</ButtonBlue>
          </Button>
        </Actions>
      </BoxItem>
      <BoxItem>
        <Text2>
          You’re on the verge of unblocking this user, which will allow them to interact again. Are
          you sure you want to go ahead with this decision?
        </Text2>
      </BoxItem>
    </Box>
  );
};

export const LeaderboardUnbanFormLayout: React.FC<ILeaderboardUnbanFormProps> = (props) => {
  return (
    <VerifierProvider>
      <LeaderboardUnbanForm {...props} />
    </VerifierProvider>
  );
};

const Text1 = styled.div`
  font-family: var(--font-family-golos);
  font-style: italic;
  font-weight: 900;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #d2eaff;
  text-align: center;
`;

const Text2 = styled.div`
  font-family: var(--font-family-golos);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  color: rgba(210, 234, 255, 0.75);
  text-align: center;
`;

const Actions = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  max-width: 543px;

  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  /* display: flex; */
  /* justify-content: flex-start; */
  /* align-items: center; */
  width: 100%;
  /* margin: 0 0 16px; */

  ${({ styles }) => styles}
`;

const Warn = styled.div<IStyles>`
  box-sizing: border-box;
  color: red;
  font-weight: bold;

  ${({ styles }) => styles}
`;
