import { useQuery } from "@tanstack/react-query";
import { FindSlotGamesResponse } from "types/slots/dto";
import { useAppContext } from "contexts";

export enum GameUsageTimeRange {
  ONE_HOUR = "one_hour",
  ONE_DAY = "one_day",
  ONE_WEEK = "one_week",
  ONE_MONTH = "one_month",
  ALL_TIME = "all_time",
}

export enum FindSlotGamesSort {
  NAME_ASC = "name_asc",
  NAME_DESC = "name_desc",
  MOST_POPULAR = "most_popular",
  PROVIDER = "provider",
  NEW_RELEASES = "new_releases",
}

type useSlotGamesProps = {
  take?: number;
  skip?: number;
  search?: string;
  sort?: FindSlotGamesSort | string;
  provider?: string;
  enabled?: boolean;
  isFavorite?: boolean;
  code?: string;
  name?: string;
};

const useSlotGames = ({
  take = 5,
  skip = 0,
  search,
  provider,
  sort,
  code,
  name,
  enabled = true,
  isFavorite = false,
}: useSlotGamesProps) => {
  const { apiService } = useAppContext();
  return useQuery<FindSlotGamesResponse>({
    queryKey: ["slot-games", search, skip, take, provider, sort],
    queryFn: async () => {
      const searchFilter = `${!!search ? `&search=${search}` : ""}`;
      const providerFilter = `${!!provider ? `&provider=${provider}` : ""}`;
      const sortFilter = `${!!sort ? `&sort=${sort}` : ""}`;
      const favorite = isFavorite ? "/favorite" : "";
      const columnFilter = code ? `&code=${code}` : name ? `&name=${name}` : "";

      const response = await apiService.request(
        `/slots${favorite}?skip=${skip}&take=${take}${searchFilter}${columnFilter}${providerFilter}${sortFilter}`,
        {
          method: "GET",
        }
      );

      const data = await response?.json();

      if (!response?.ok) {
        return Promise.reject([]);
      }

      return data;
    },
    initialData: { data: [], total: 0 },
    placeholderData: () => ({ data: [], total: 0 }),
    enabled: enabled,
  });
};

export default useSlotGames;
