import * as luxon from "luxon";
import Link from "next/link";
import styled, { css } from "styled-components";
import { Item } from "./YoutubeFeedComponent";
import { $stylesReset, IStyles } from "components";

export const SlidingVideos = ({
  videos = [],
  youTubeSectionRef,
}: {
  videos: Item[];
  youTubeSectionRef: React.RefObject<HTMLElement>;
}) => {
  return (
    <>
      <Wrapper>
        <Section ref={youTubeSectionRef}>
          {videos.map((item, index) => (
            <Card key={index} href={item.link} target="_blank" index={index}>
              <CardThumbnail
                styles={css`
                  background-image: url(${item.thumbnail});
                `}
              />
              <CardTitle>{item.title}</CardTitle>
              <CardDate>{castDate(item.pubDate)}</CardDate>
            </Card>
          ))}
        </Section>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  position: relative;
`;

const Section = styled.section`
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  flex-wrap: nowrap;
  gap: 16px;
`;

const Card = styled(Link)<IStyles & { range?: number; index?: number; current?: number }>`
  flex: 1 0;
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  gap: 16px;
  min-width: 292px;
  width: var(--Card-width);
  height: var(--Card-height);
  ${({ styles }) => styles}
`;

const CardThumbnail = styled.div<IStyles>`
  ${$stylesReset}
  height: calc( var(--Card-height) / 3 * 4 ); // cutting black stripes
  background-clip: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--Card-width) var(--Card-height); // 343px 273px
  ${({ styles }) => styles}
`;

const CardTitle = styled.div<IStyles>`
  color: rgba(210, 234, 255, 0.75);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: left;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  ${({ styles }) => styles}
`;

const CardDate = styled.div<IStyles>`
  color: rgba(210, 234, 255, 0.25);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: left;

  ${({ styles }) => styles}
`;

const castDate = (date: string) => {
  const formatIn = "yyyy-MM-dd hh:mm:ss";
  const formatOut = "dd/MM/yyyy";
  return luxon.DateTime.fromFormat(date, formatIn).toFormat(formatOut);
};
