import { $styles, Button, ButtonBlue, Flex, IStyles } from "components/common";
import { TheButton, TheIcon } from "components/TheComponents";
import styled from "styled-components";
import Image from "next/image";
import { useRouter } from "next/router";
import React, { Dispatch, SetStateAction } from "react";
import {
  BoxItem,
  Card,
  CardLinks,
  CopyCode,
  CopyLink,
  cssEdit,
  cssFeatured,
  cssNew,
  IReward,
  onCopy,
} from "./StartEarningRewardsComponent";
import DynamicFontSize from "./DynamicSizeText";
import { FeaturedCasinoTitle } from "../FeatureCasinoComponent";
import { TrackGoogleAnalyticsEvent } from "helpers/gaEvent";

export interface StartEarningRewardsComponentCardProps {
  item: IReward;
  isAdminPage?: boolean;
  isFeatured?: boolean;
  width?: string;
  onOpenEdit?: () => void;
  onOpenDelete?: () => void;
  setSelectedReward?: Dispatch<SetStateAction<IReward | null>>;
}

const StartEarningRewardsComponentCard: React.FC<StartEarningRewardsComponentCardProps> = ({
  item,
  isAdminPage,
  onOpenDelete,
  onOpenEdit,
  setSelectedReward,
  width,
  isFeatured,
}) => {
  const SEVEN_DAYS_IN_MS = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
  const router = useRouter();

  return (
    <Card
      isAdminPage={isAdminPage}
      style={{
        width: width || "300px",
        height: 500,
      }}
    >
      {isFeatured ? (
        <BoxItem styles={cssFeatured}>
          <FeaturedCasinoTitle>
            <TheIcon icon="star:blue" size={16} />
          </FeaturedCasinoTitle>
        </BoxItem>
      ) : null}
      {!isAdminPage &&
      new Date().getTime() - new Date(item.createdAt).getTime() <= SEVEN_DAYS_IN_MS ? (
        <TheButton preset="new" styles={cssNew}>
          New
        </TheButton>
      ) : null}
      {isAdminPage ? (
        <Flex>
          <Button
            onClick={() => {
              if (onOpenEdit && setSelectedReward) {
                onOpenEdit();
                setSelectedReward(item);
              }
            }}
            style={{ marginRight: 55 }}
            isDark
            styles={cssEdit}
          >
            <ButtonBlue isDark>
              <TheIcon icon="action:edit" size={16} />
            </ButtonBlue>
          </Button>
          <Button
            onClick={() => {
              if (onOpenDelete && setSelectedReward) {
                onOpenDelete();
                setSelectedReward(item);
              }
            }}
            isDark
            styles={cssEdit}
          >
            <ButtonBlue isDark>
              <TheIcon icon="action:remove" size={16} />
            </ButtonBlue>
          </Button>
        </Flex>
      ) : null}
      <Flex padding="30px 0px" align="center" justify="center">
        <Image alt="logo" width={250} height={120} src={item.logoUrl} />
      </Flex>
      <DynamicFontSize text={item.bonusTitle} />
      <Flex height={55} style={{ margin: "0px 24px 12px" }}>
        <Flex style={{ gap: 7 }} column margin="auto">
          <Flex
            justify="flex-start"
            width="100%"
            style={{ display: item.hasLeaderboard ? "flex" : "none" }}
            align="center"
          >
            <Image
              alt="logo"
              width={24}
              height={24}
              style={{ marginRight: 8 }}
              src="/images/icons/check-with-border.png"
            />
            <Text>LEADERBOARD</Text>
          </Flex>

          <Flex
            justify="flex-start"
            style={{ display: item.hasDiscordBonuses ? "flex" : "none" }}
            width="100%"
            align="center"
          >
            <Image
              alt="logo"
              width={24}
              height={24}
              style={{ marginRight: 8 }}
              src="/images/icons/check-with-border.png"
            />
            <Text>DISCORD REWARDS</Text>
          </Flex>
        </Flex>
      </Flex>
      <CardLinks>
        <CopyCode>
          <CopyLink
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onCopy(item);
            }}
          >
            <TheIcon icon="copy" size={50} />
          </CopyLink>
          {item.refCode}
        </CopyCode>

        <Button
          onClick={() => {
            TrackGoogleAnalyticsEvent({
              action: "bonuses_link",
              category: "bonuses",
              value: item.refUrl,
              label: "All bonuses",
            });
            router.push(item.refUrl);
          }}
        >
          <ButtonBlue>Redeem Bonuses</ButtonBlue>
        </Button>
      </CardLinks>
    </Card>
  );
};

export default StartEarningRewardsComponentCard;

export const Text = styled.div<IStyles>`
  color: #d2eaff;
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 23px;
  text-shadow: 0px 0px 24px rgba(40, 113, 255, 0.5), 0px 0px 6px rgba(58, 113, 254, 0.46),
    0px 2px 4px #0b0e23;
  text-transform: uppercase;
  ${$styles}
`;
