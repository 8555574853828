import React from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { IStyles, $styles } from "components";
import { TheFieldset } from "components/TheComponents";
import { useDebouncedCallback } from "use-debounce";
import WrewardsDropdown from "components/wrewards-ui-kit/Dropdown";
import Arrow from "/public/images/icons/arrow.svg";

export interface TableHeader {
  setValue: (value: string) => void;
  setSorter: (value: string) => void;
  boxStyles?: FlattenSimpleInterpolation;
  setSkip: (skip: number) => void;
  skip: number;
  sorter: string;
}

const BoxContent = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  margin: 30px auto;
  width: 100%;
  ${$styles};
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;

  ${$styles}
`;

export const PointsShopHeader: React.FC<TableHeader> = ({
  setValue,
  setSorter,
  boxStyles,
  sorter,
  skip,
  setSkip,
}): React.ReactElement => {
  const [searchValue, setSearchValue] = React.useState("");

  const debounced = useDebouncedCallback((v) => {
    setValue(v);
    if (skip !== 0) {
      setSkip(0);
    }
  }, 400);

  const options = [
    {
      value: "price:asc",
      label: "Price",
    },
    {
      value: "createdAt:asc",
      label: "Date",
    },
  ];

  return (
    <>
      <BoxContent styles={boxStyles}>
        <BoxItem
          styles={css`
            flex-grow: 1;
          `}
        >
          <TheFieldset
            styles={css`
              width: 100%;
              border: 1px #1e213a solid;
              height: 50px;
              line-height: 35px;
              border-radius: 8px;
              background-image: url("/images/PachinkoComponent/search-prefix.png");
              background-repeat: no-repeat;
              background-size: 15px 15px;
              background-position: 1rem center;
              text-indent: 2rem;
            `}
          >
            <input
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
                debounced(event.target.value);
              }}
            />
          </TheFieldset>
        </BoxItem>
        <BoxItem>
          <WrewardsDropdown
            value={sorter}
            items={options.map(({ value, label }) => ({ value, label }))}
            prefix="Sort By:"
            icon={<Arrow />}
            onChange={({ value }) => setSorter(value.toString())}
          />
        </BoxItem>
      </BoxContent>
    </>
  );
};
