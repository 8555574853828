import React from "react";
import * as luxon from "luxon";
import styled, { css } from "styled-components";
import { TheButton, TheDatePicker, TheFieldset, IStyles } from "components";
import { useAppContext } from "contexts";
import {
  IDaoLeaderboard,
  IDtoLeaderboard,
  IDtoLeaderboardUser,
  LeaderboardPrizeSelectionType,
  TheLeaderboardSourceKeys,
} from "interfaces";
import { Flex } from "components/common";
import Image from "next/image";
import { ManagePrizesButton } from "./LeaderboardCreateLayout";
import { triggerNotification } from "components/TheComponents/Notification";
import ChakraModal from "components/TheComponents/Modal";
import { useDisclosure } from "@chakra-ui/react";
import LeaderboardPrizesModalLayout from "./LeaderboardPrizesModalLayout";
import VerifierProvider, { useVerifierContext } from "contexts/VerifierProvider";
import WrewardsButton from "components/wrewards-ui-kit/Button";

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  max-width: 543px;

  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;

  ${({ styles }) => styles}
`;

export interface ILeaderboardEditProps {
  // ?? remove
  switcher?: string;
  data: IDtoLeaderboard | null;
  refetchLeaderboard: () => void;
  // ?? remove
  users?: IDtoLeaderboardUser[];
  onClose: () => void;
}

const LeaderboardEdit: React.FC<ILeaderboardEditProps> = ({
  data,
  refetchLeaderboard,
  onClose,
}): React.ReactElement => {
  const { accessFetch } = useAppContext();
  const { verify } = useVerifierContext();

  const { isOpen: isPrizesOpen, onOpen: onOpenPrizes, onClose: onClosePrizes } = useDisclosure();

  const leaderboardId = data?.id;
  const [payload, setPayload] = React.useState<IDaoLeaderboard>({
    casino: data?.casinoProvider || "",
    startDate: data?.startDate || "",
    duration: data?.durationDays.toString() || "",
    maxPublicUsersCount: data?.maxPublicUsersCount.toString() || "",
    prize1: data?.prize1.toString() || "",
    prize2: data?.prize2.toString() || "",
    prize3: data?.prize3.toString() || "",
    randomPrizeThreshold:
      data?.randomPrizeThreshold === 0 ? "0" : data?.randomPrizeThreshold.toString() || "0",
    additionalPrizes: [
      ...(data?.additionalPrizes?.map((el) => ({
        prizeNumber: el.prizeNumber,
        amount: el.amount,
      })) || []),
    ],
    randomPrizes:
      data?.randomPrizes?.map((el) => ({
        prizeNumber: el.prizeNumber,
        amount: el.amount,
      })) || [],
    prizeSelectionType: data?.prizeSelectionType ?? LeaderboardPrizeSelectionType.TIERED,
    ticketRate: data?.ticketRate ?? 1,
  });

  const endDate: string | null = React.useMemo(() => {
    const startDate = payload?.startDate ?? null;
    const duration = payload?.duration ?? "";
    const date = startDate ? luxon.DateTime.fromISO(startDate) : null;
    const days = duration !== "" && Number.isInteger(+duration) ? +duration : 0;
    if (date?.isValid && days > 0) {
      const hours = days * 24;
      const endDate = date.plus({ hours }).minus({ seconds: 1 }).toUTC();
      return endDate.toISO();
    }
    return null;
  }, [payload]);

  const onDateChage = React.useMemo(() => {
    return (key: string) => (value: string | null) => {
      if (value) {
        const date = luxon.DateTime.fromISO(value).setZone("UTC").startOf("day").toISO();
        payload && setPayload({ ...payload, [key]: date });
      }
    };
  }, [payload, setPayload]);

  const onTextChange = React.useMemo(() => {
    return (key: string) => (event: React.ChangeEvent) => {
      event.preventDefault();
      const { target } = event;
      if (target instanceof HTMLInputElement) {
        const { value } = target as HTMLInputElement;
        payload && setPayload({ ...payload, [key]: value });
      } else if (target instanceof HTMLTextAreaElement) {
        const { value } = target as HTMLTextAreaElement;
        payload && setPayload({ ...payload, [key]: value });
      }
    };
  }, [payload, setPayload]);

  const onSave = async ({ otp }: { otp: string }) => {
    if (payload) {
      const response = await accessFetch(`/leaderboard/${leaderboardId}`, {
        method: "PATCH",
        headers: {
          "x-otp": otp,
        },
        body: JSON.stringify({
          ...(typeof payload.casino === "string" && { casinoProvider: payload.casino }),
          ...(typeof payload.startDate === "string" && { startDate: payload.startDate }),
          ...(typeof payload.duration === "string" && { durationDays: +payload.duration }),
          ...(typeof payload.maxPublicUsersCount === "string" && {
            maxPublicUsersCount: +payload.maxPublicUsersCount,
          }),
          ...(payload.prize1 && { prize1: +payload.prize1 }),
          ...(payload.prize2 && { prize2: +payload.prize2 }),
          ...(payload.prize3 && { prize3: +payload.prize3 }),
          ...(payload.additionalPrizes && {
            additionalPrizes: payload.additionalPrizes,
          }),
          ...(payload.randomPrizeThreshold && {
            randomPrizeThreshold: payload.randomPrizes.length ? +payload.randomPrizeThreshold : 0,
          }),
          ...(payload.randomPrizes && {
            randomPrizes: payload.randomPrizes,
          }),
          ...(payload.prizeSelectionType && {
            prizeSelectionType: payload.prizeSelectionType,
          }),
          ...(payload.ticketRate && {
            ticketRate: +payload.ticketRate,
          }),
        }),
      });
      const json = await response?.json();
      if (response && (response.status === 200 || response.status === 201)) {
        refetchLeaderboard();
        triggerNotification({ text: "Leaderboard updated", type: "success" });
        onClose();
      } else {
        triggerNotification({ text: json.message, type: "error" });
      }
    }
  };

  const numProps = { pattern: "^[d]*$", type: "text" };

  const onSaveClick = (e: React.MouseEvent) => {
    e.preventDefault();
    const fieldsToValidate = [payload.prize1, payload.prize2, payload.prize3, payload.duration];

    const valid = fieldsToValidate.every(
      (field) => field !== "" && Number.isInteger(+field) && +field >= 0
    );

    const maxPublicUsersCount = payload?.maxPublicUsersCount;
    const isMaxUsersValid =
      maxPublicUsersCount &&
      maxPublicUsersCount !== "" &&
      Number.isInteger(+maxPublicUsersCount) &&
      +maxPublicUsersCount >= 10;

    if (valid && isMaxUsersValid) {
      verify(({ otp }) => onSave({ otp }));
    } else {
      triggerNotification({ text: "Validation failed", type: "error" });
    }
  };

  const canBeRaffleType = payload?.casino === TheLeaderboardSourceKeys.gamdom;

  return (
    <Box>
      <BoxItem>
        <TheFieldset title="Start date">
          <TheDatePicker value={payload?.startDate ?? null} onChange={onDateChage("startDate")} />
        </TheFieldset>
      </BoxItem>
      <BoxItem>
        <TheFieldset title="Duration (days)">
          <input
            {...numProps}
            placeholder="Duration (days)"
            value={payload?.duration ?? ""}
            onChange={onTextChange("duration")}
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem>
        <TheFieldset title="End date">
          <TheDatePicker value={endDate} readOnly />
        </TheFieldset>
      </BoxItem>
      <BoxItem>
        <TheFieldset title="Max Public Users">
          <input
            {...numProps}
            placeholder="Max Public Users"
            value={payload?.maxPublicUsersCount ?? ""}
            onChange={onTextChange("maxPublicUsersCount")}
          />
        </TheFieldset>
      </BoxItem>

      {canBeRaffleType ? (
        <>
          <BoxItem>
            <TheFieldset title="Tickets Rate">
              <input
                {...numProps}
                placeholder="Tickets Rate"
                value={payload?.ticketRate ?? ""}
                onChange={onTextChange("ticketRate")}
              />
            </TheFieldset>
          </BoxItem>

          <BoxItem>
            <ButtonGroup>
              <WrewardsButton
                onClick={() =>
                  setPayload((p) => ({
                    ...p,
                    prizeSelectionType: LeaderboardPrizeSelectionType.TIERED,
                  }))
                }
                variant={
                  payload.prizeSelectionType === LeaderboardPrizeSelectionType.TIERED
                    ? "game-action-blue"
                    : "game-action-gray"
                }
              >
                Tiered
              </WrewardsButton>
              <WrewardsButton
                onClick={() =>
                  setPayload((p) => ({
                    ...p,
                    prizeSelectionType: LeaderboardPrizeSelectionType.RAFFLE,
                  }))
                }
                variant={
                  payload.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE
                    ? "game-action-blue"
                    : "game-action-gray"
                }
              >
                Raffle
              </WrewardsButton>
            </ButtonGroup>
          </BoxItem>
        </>
      ) : null}
      <Flex
        style={{
          border: "1px solid rgba(36, 39, 70, 0.5)",
          borderRadius: 8,
          cursor: "pointer",
        }}
        width="100%"
        background="rgba(36, 39, 70, 0.5)"
        height={37}
        align="center"
        onClick={onOpenPrizes}
        justify="center"
      >
        <Image
          style={{ marginRight: 8 }}
          src="/images/icons/add-icon.png"
          alt="plus"
          width={15}
          height={15}
        />
        <ManagePrizesButton>Manage Prizes</ManagePrizesButton>
      </Flex>
      <BoxItem
        styles={css`
          margin-top: 8px;
        `}
      >
        <TheButton preset="blue" width={"100%"} onClick={onSaveClick}>
          Save item
        </TheButton>
      </BoxItem>
      <ChakraModal
        isOpen={isPrizesOpen}
        onClose={onClosePrizes}
        styles={css`
          max-height: 700px;
        `}
        size="2xl"
        title="ADD PRIZES"
        content={
          <LeaderboardPrizesModalLayout
            setPayload={setPayload}
            payload={payload}
            onClose={onClosePrizes}
          />
        }
      />
    </Box>
  );
};

export const LeaderboardEditLayout: React.FC<ILeaderboardEditProps> = (props) => {
  return (
    <VerifierProvider>
      <LeaderboardEdit {...props} />
    </VerifierProvider>
  );
};

const ButtonGroup = styled.div`
  padding: 4px;
  border-radius: 8px;
  background: var(--Dark-Void, #10131a);
  display: flex;
  gap: 2px;

  button {
    flex: 1;
  }
`;
