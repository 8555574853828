import styled, { css, } from 'styled-components';
import { ITheRadiobox } from 'interfaces';

const cssDarkBlue_checked = css`
	background-color: #D2EAFF;
	box-shadow: 0px 0px 24px rgba(40, 113, 255, 0.5), 0px 0px 6px rgba(58, 113, 254, 0.46), 0px 2px 4px #0B0E23;

	&::before {
		content: '';
		width: 50%;
		height: 50%;
		background-color: #0B101B;
		border-radius: 25%;
	}
`;

const cssDarkBlue = css`
	background-color: #0B101B;
`;

export const Radiobox = styled.div<ITheRadiobox>`
	flex-grow: 0;
	flex-shrink: 0;
	box-sizing: border-box;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	${
		({ size, }) => typeof size === 'number'
			? css`
				width: ${ size }px;
				height: ${ size }px;
				border-radius: ${ size / 2 }px;
			`
			: null
	}

	overflow: hidden;

	//
	background-color: transparent;
	box-shadow: 0px 0px 24px transparent, 0px 0px 6px transparent, 0px 2px 4px transparent;
	cursor: pointer;

	${
		({ checked, preset, }) =>
			  preset === 'dark-blue' && checked ? cssDarkBlue_checked
			: preset === 'dark-blue' && !checked ? cssDarkBlue
			: checked ? cssDarkBlue_checked
			: !checked ? cssDarkBlue
			: null
	}

	${ ({ styles, }) => styles }
`;

const Input = styled.input`
  display: none;
`;

export function TheRadiobox(
	{
		checked,
    name,
		value,
    preset,
    size = 10,
    onChange,
    ...props
	}: ITheRadiobox,
): React.ReactElement {
	const inputProps = {
		...(typeof checked === 'boolean') && { checked },
		...(typeof name === 'string') && { name },
		...['number', 'string'].includes(typeof value) && { value },
		...(typeof onChange === 'function') && { onChange },
	};
	const checkboxProps = {
		...(typeof checked === 'boolean') && { checked },
		...(typeof preset === 'string') && { preset },
		...(typeof size === 'number') && { size },
		...props
	};

	return (
		<>
			<Input type="checkbox" { ...inputProps } />
			<Radiobox { ...checkboxProps } />
		</>
	);
}
