import React from "react";
import DropdownSelect from "./DropdownSelect";
import { IAppContext, withApp } from "contexts";
import styled from "styled-components";
import { useDebouncedCallback } from "use-debounce";
import Image from "next/image";

const Root = styled.div`
  width: 100%;
  padding-top: 8px;
`;

const GalleryOption = styled.div`
  display: flex;
  flex-direction: row;
  padding: 15px;
  gap: 16px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  &:active {
    opacity: 0.5;
  }
`;

const GalleryOptionImg = styled(Image)``;

export type GalleryDropdownProps = {
  onSelect: (imageUrl: string) => void;
};

export type GalleryItem = { name: string; code: string; src: string };

const GalleryDropdownOption: React.FC<{
  key: string | number;
  option: { label: string; value: GalleryItem };
  onSelect: (data: { label: string; value: GalleryItem }) => void;
}> = ({ key, option, onSelect }) => {
  return (
    <GalleryOption key={key} onClick={() => onSelect(option)}>
      <GalleryOptionImg src={option.value?.src} alt={option.value.code} width={32} height={32} />
      {option.label}
    </GalleryOption>
  );
};

const GalleryDropdown: React.FC<GalleryDropdownProps & IAppContext> = ({
  accessFetch,
  onSelect,
}) => {
  const take = 10;
  const [skip, setSkip] = React.useState<number>(0);
  const [total, setTotal] = React.useState<number>(0);
  const [search, setSearch] = React.useState<string>("");
  const [options, setOptions] = React.useState<{ name: string; code: string; src: string }[]>([]);

  const galleryFetch = React.useCallback(async () => {
    const res = await accessFetch(`/asset?skip=${skip}&take=${take}`);
    const data = await res?.json();

    setOptions(data.items);
    setTotal(data.total);
  }, []);

  React.useEffect(() => {
    galleryFetch();
  }, [galleryFetch]);

  const updateOptions = async () => {
    const newSkip = skip + take;
    setSkip(newSkip);
    const res = await accessFetch(`/asset?skip=${newSkip}&take=${take}&search=${search}`);
    const data = await res?.json();

    setOptions([...options, ...data.items]);
  };

  const searchOptions = async (search?: string) => {
    setSkip(0);
    setSearch(search ?? "");
    const res = await accessFetch(`/asset?skip=${0}&take=${take}&search=${search}`);
    const data = await res?.json();

    setOptions(data.items);
  };

  const debounced = useDebouncedCallback(async (value) => {
    await searchOptions(value);
  }, 400);

  return (
    <Root>
      <DropdownSelect
        options={options?.map((item) => ({ value: item, label: item.name }))}
        onUpdateOptions={() => {
          if (options.length < total) {
            updateOptions();
          }
        }}
        onSelect={(option) => onSelect(option.value.src)}
        onSearch={debounced}
        customOption={(key, option, onSelect) => (
          <GalleryDropdownOption key={key} option={option} onSelect={onSelect} />
        )}
      />
    </Root>
  );
};

export default withApp(GalleryDropdown);
