import { useAppContext, withApp } from "contexts";
import MyBetsTabNav from "./components/MyBetsTabNav";
import { useEffect, useState } from "react";
import { HouseGameType } from "helpers/constants";
import { DualRingInner, DualRingLoader, Pages } from "components/common";
import MyBetsTabTable from "./components/MyBetsTabTable";
import { Root } from "./styles";
import { css } from "styled-components";
import { useQuery } from "@tanstack/react-query";

export type GameBet = {
  id: number;
  amount: number;
  gameId: number;
  gameType: HouseGameType;
  payout: number;
  settledAt: string;
};

export type MyBetsTabProps = { userId?: string };

const take: number = 5;

const MyBetsTab: React.FC<MyBetsTabProps> = ({ userId }) => {
  const { accessFetch, profile } = useAppContext();

  const [skip, setSkip] = useState<number>(0);
  const [betsType, setBetsType] = useState<HouseGameType | undefined>();

  const isOwnProfile = userId === profile?.id;

  const {
    data: { data: bets, total },
    isFetching: isLoading,
  } = useQuery<{ data: GameBet[]; total: number }>({
    queryKey: ["user-bets", { userId, skip, betsType }],
    queryFn: async () => {
      try {
        const response = await accessFetch(
          `/bets${isOwnProfile ? "/my?" : `?userId=${userId}&`}take=${take}&skip=${skip}${
            betsType ? `&gameType=${betsType}` : ""
          }`
        );

        const betsData = await response?.json();

        if (!betsData?.data) {
          return Promise.reject(betsData);
        }

        return betsData;
      } catch (error) {
        console.error("Error fetching data:", error);
        throw new Error("Error fetching data");
      }
    },
    enabled: !!userId || !profile?.id,
    initialData: {
      data: [],
      total: 0,
    },
  });

  useEffect(() => {
    setSkip(0);
  }, [betsType]);

  const onPage = (index: number) => {
    setSkip(index * take);
  };

  return (
    <Root style={{ flexGrow: 1 }}>
      <MyBetsTabNav betsType={betsType} setBetsType={setBetsType} />
      {isLoading ? (
        <DualRingLoader>
          <DualRingInner />
        </DualRingLoader>
      ) : (
        <div
          style={{
            width: "100%",
          }}
        >
          <MyBetsTabTable bets={bets} />
          <Pages
            styles={css`
              flex-wrap: wrap !important;
              margin-top: 12px !important;
            `}
            name="Bets:"
            skip={skip}
            take={take}
            total={total}
            onClick={(index) => {
              onPage(index);
            }}
          />
        </div>
      )}
    </Root>
  );
};

export default withApp(MyBetsTab);
