import { ButtonHTMLAttributes, DetailedHTMLProps } from "react";
import { Root } from "./styles";
import Image from "next/image";

export type SaveButtonProps = Omit<
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>,
  "ref"
> & {
  isActive?: boolean;
};

const SaveButton: React.FC<SaveButtonProps> = ({ isActive, ...props }) => {
  return (
    <Root {...props} active={isActive}>
      {isActive ? (
        <>
          <Image src="/images/TheModalBox/check.png" height={18} width={15} alt="done" />
          <span>Save</span>
        </>
      ) : (
        <Image src={"/images/icons/edit.svg"} alt="edit" height={12} width={12} />
      )}
    </Root>
  );
};

export default SaveButton;
