import { TheIcon } from "components/TheComponents";
import { IStyles, $styles } from "components/common";
import React from "react";
import styled from "styled-components";
import { CardGift, cssGift } from "./StartEarningRewardsComponent";

const BonusTitle = styled.div<IStyles>`
  font-size: 100%;
  ${$styles}
`;

const DynamicFontSize = ({ text }: { text: string }) => {
  const arrayOfLines = text.split("/n").map((el) => el.trim());

  return (
    <CardGift style={{ height: 90, justifyContent: "center" }}>
      <TheIcon icon="gift:title" size={24} styles={cssGift} />
      {arrayOfLines.map((line, idx) => (
        <BonusTitle style={{ fontSize: 13, whiteSpace: "nowrap" }} key={idx}>
          {line}
        </BonusTitle>
      ))}
    </CardGift>
  );
};

export default DynamicFontSize;
