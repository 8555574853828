import { AnimatedNumber } from "components/leaderboard";
import usePachicoStats from "hooks/pachiko/usePachicoStats";
import Image from "next/image";
import React from "react";
import styled from "styled-components";

import PachikoAward from "assets/pachiko/images/pachiko-boy-award.png";
import P1 from "assets/pachiko/images/box-1.png";
import P3 from "assets/pachiko/images/box-2.png";
import P2 from "assets/pachiko/images/box-3.png";

export function formatNumber(value: number | string): string | number {
  if (!value) {
    return 0;
  }
  const strValue = value.toString();
  const parts = strValue.split(".");

  const wholeNumber = parts[0];
  const decimal = parts[1];

  const formattedWholeNumber = addCommasToThousandCount(Number(wholeNumber));

  if (decimal) {
    const formattedDecimal = decimal.replace(/0+$/, "");
    if (formattedDecimal.length > 0) {
      return `${formattedWholeNumber}.${formattedDecimal}`;
    }
  }

  return formattedWholeNumber;
}

function addCommasToThousandCount(value: number | string): string | number {
  const strValue = value.toString();
  const chars = strValue.split("");

  const reversedChars = chars.reverse();
  const formattedChars = [];

  for (let i = 0; i < reversedChars.length; i++) {
    if (i !== 0 && i % 3 === 0) {
      formattedChars.push(",");
    }
    formattedChars.push(reversedChars[i]);
  }

  const formattedValue = formattedChars.reverse().join("");

  return formattedValue;
}

export const Prizes = () => {
  const { data: prizes } = usePachicoStats();

  return (
    <TotalAwarded>
      <div className="boy">
        <Image
          className="box box-1"
          src={P1.src}
          width={220}
          height={220}
          style={{ objectFit: "contain" }}
          alt="boy-award"
        />
        <Image
          className="box box-2"
          src={P2.src}
          width={200}
          height={200}
          style={{ objectFit: "contain" }}
          alt="boy-award"
        />
        <Image
          className="box box-3"
          src={P3.src}
          width={200}
          height={200}
          style={{ objectFit: "contain" }}
          alt="boy-award"
        />
        <Image
          className="box box-4"
          src={P1.src}
          width={160}
          height={160}
          style={{ objectFit: "contain" }}
          alt="boy-award"
        />
        <div className="boy-img-wrapper">
          <Image
            className="boy-img"
            src={PachikoAward.src}
            fill
            style={{ objectFit: "contain" }}
            alt="boy-award"
          />
        </div>
        <div className="prize">
          <span className="prize-value">
            $
            <AnimatedNumber number={1000000} />
          </span>
          <div className="prize-label">
            Prizes <span className="highlight">Distributed</span>
          </div>

          <div className="users">
            <span>Awarded Users</span>
            <span className="users-value">
              <AnimatedNumber number={prizes.count} />
            </span>
          </div>
        </div>
      </div>
    </TotalAwarded>
  );
};

const TotalAwarded = styled.div`
  margin-bottom: 120px;
  .boy {
    padding-bottom: 100px;
    position: relative;
  }

  .box-1 {
    left: 20%;
    position: absolute;
    top: 10%;
    animation: float 5s infinite ease-in-out, shadow 5s infinite ease-in-out;
  }
  .box-2 {
    left: 10%;
    position: absolute;
    bottom: 20%;
    animation: float 4s infinite ease-in-out, shadow 4s infinite ease-in-out;
  }
  .box-3 {
    right: 20%;
    position: absolute;
    top: 10%;
    animation: float 4.5s infinite ease-in-out, shadow 4.5s infinite ease-in-out;
  }
  .box-4 {
    right: 10%;
    position: absolute;
    bottom: 20%;
    animation: float 5.5s infinite ease-in-out, shadow 5s infinite ease-in-out;
  }

  @media (max-width: 800px) {
    .box-1 {
      left: 5%;
      max-width: 160px;
    }
    .box-2 {
      left: 0%;
      max-width: 160px;
      bottom: 43%;
      z-index: 1;
    }
    .box-3 {
      right: 5%;
      max-width: 160px;
    }
    .box-4 {
      right: 0%;
      z-index: 1;
      bottom: 43%;
      max-width: 160px;
      /* filter: drop-shadow(0px 0px 16px #000000); */


    }
  }
  @media (max-width: 600px) {
    .box-1 {
      left: 5%;
      max-width: 100px;
    }
    .box-2 {
      left: 0%;
      max-width: 100px;
      bottom: 43%;
      z-index: 1;
    }
    .box-3 {
      right: 5%;
      max-width: 100px;
    }
    .box-4 {
      right: 0%;
      z-index: 1;
      bottom: 43%;
      max-width: 100px;
      /* filter: drop-shadow(0px 0px 16px #000000); */


    }
  }

  .boy-img-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    aspect-ratio: 1.1/1;
    position: relative;
  }
  .boy-img {
    filter: drop-shadow(0px 0px 16px #000000);
  }

  .prize {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .prize-value {
    position: relative;
    font-family: var(--font-family-exo);
    font-weight: 900;
    font-style: italic;
    font-size: 104px;
    color: #d5e0f5;
    text-shadow: 0px 3px 0px #03d9ff, 0px 0px 40px #000, 0px 0px 40px #651bcd;
    filter: drop-shadow(0px 0px 40px #000);

    display: block;
    animation: pulse 3s infinite, shadow 5s infinite;
    z-index: 10;
    /* &::before {
      content: '';
      inset: 6px 0px;
      position: absolute;
      box-shadow:0px 0px 44px #651BCD ;
    } */
  }

  .prize-label {
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 500;
    padding: 16px;
    background-color: rgba(171, 186, 219, 0.05);
    border-radius: 12px;
    backdrop-filter: blur(40px);
    color: var(--daylight-daylight-main, #d5e0f5);

    .highlight {
      color: var(--pure-sky-100, #00b2ff);
    }
  }

  .users {
    color: var(--daylight-daylight-75, rgba(200, 215, 245, 0.75));
    display: flex;
    gap: 16px;
    margin-top: 16px;
    flex-direction: column;
    align-items: center;
  }

  .users-value {
    font-family: var(--font-family-exo);
    font-weight: 900;
    font-style: italic;
    font-size: 72px;
    color: #d5e0f5;
    text-shadow: 0px 0px 10px #000;
  }

  @media (max-width: 800px) {
    /* .title {
      .highlight {
        font-size: 48px;
      }
    } */
    .prize-value {
      font-size: 40px;
    }
    .users-value {
      font-size: 48px;
    }

    .prize-label {
      font-size: 16px;
    }
  }
`;
