import React from "react";
import { TheModalBox } from "components";
import {
  $styles,
  $stylesReset,
  Button,
  ButtonBlue,
  DualRingInner,
  DualRingLoader,
  Flex,
  IStyles,
} from "components/common";
import styled, { css } from "styled-components";
import Image from "next/image";
import RecoveryKeyModal from "./RecoveryKeyModal";
import CodeInput from "./CodeInput";

interface MFAModalModalProps {
  onClose: () => void;
  onVerify: ({ otp }: { otp: string }, optionalArg?: any) => Promise<void> | void;
  isLoading?: boolean;
}

const MFAModal: React.FC<MFAModalModalProps> = ({
  onClose,
  onVerify,
  isLoading = false,
}): React.ReactElement => {
  const [payload, setPayload] = React.useState<string>("");
  const [isValid, _] = React.useState<boolean>(true);
  const [showRecoveryKey, setShowRecoveryKey] = React.useState<boolean>(false);
  const [showMFA, setShowMFA] = React.useState<boolean>(true);

  const modalBoxProps = {
    hasBlur: true,
    isFixed: true,
    hasBorder: true,
    showClose: true,
    onClose: () => onClose && onClose(),
  };

  const modalStyles = css`
    width: 325px;
    height: 280px;
    @media (min-width: 600px) {
      width: 615px;
      height: 360px;
    }
  `;

  const ButtonFlex = styled(Flex)`
    margin-top: 15px !important;
    margin: auto;
    @media (min-width: 600px) {
      width: 50%;
    }
  `;

  return (
    <TheModalBox styles={modalStyles} {...modalBoxProps}>
      {(showMFA && (
        <Flex justify="center" column>
          <Image src={"/images/HeaderComponent/logo.svg"} width={170} height={32} alt="logo" />
          <Title
            styles={css`
              text-align: center;
            `}
          >
            Multi-factor authentification
          </Title>
          <Flex style={{ margin: "20px 0px" }} width="100%" align="center" column>
            <Text>Enter the 6-digit code displayed in your authentificator app:</Text>
            <Flex width="75%" align="center" justify="flex-start" column>
              <CodeInput payload={payload} setPayload={setPayload} isValid={isValid} />
              {/* <Text
                styles={css`
                  color: #0c8ce9;
                  text-decoration: underline;
                  margin: 5px 0px;
                  width: 100%;
                  text-align: left;
                  cursor: pointer;
                `}
                onClick={() => {
                  setShowMFA(false);
                  setShowRecoveryKey(true);
                }}
              >
                Use recovery code
              </Text> */}
            </Flex>
            <ButtonFlex>
              <Button
                styles={css`
                  margin: auto;
                  height: 44px;
                  width: 100px;
                `}
                isLoading={isLoading}
                onClick={() => onVerify({ otp: payload })}
              >
                <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
                  {isLoading ? (
                    <DualRingLoader smallRing>
                      <DualRingInner smallRing />
                    </DualRingLoader>
                  ) : (
                    "Verify"
                  )}
                </ButtonBlue>
              </Button>
            </ButtonFlex>
          </Flex>
        </Flex>
      )) ||
        (showRecoveryKey && (
          <RecoveryKeyModal
            onBack={() => {
              setShowMFA(true);
            }}
            onClose={() => {
              // setShowMFA(true);
              setShowRecoveryKey(false);
            }}
          />
        ))}
    </TheModalBox>
  );
};

export default MFAModal;

const Title = styled.div<IStyles>`
  font-family: var(--font-family-golos);
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(210, 234, 255, 1);
  padding: 10px 0px;

  text-transform: uppercase;

  ${$styles}
`;
const Text = styled.div<IStyles>`
  ${$stylesReset}
  font-family: var(--font-family-golos);
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: left;
  margin-bottom: 20px;
  ${$styles};
`;
