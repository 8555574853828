import { SVGProps } from "react";

export type DropLinesProps = SVGProps<SVGSVGElement>;

const DropLines: React.FC<DropLinesProps> = (props) => {
  return (
    <svg {...props} width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M0.699951 2.17813C0.699951 1.18402 1.50584 0.378128 2.49995 0.378128H20.5C21.4941 0.378128 22.2999 1.18402 22.2999 2.17813C22.2999 3.17223 21.4941 3.97813 20.5 3.97813H2.49995C1.50584 3.97813 0.699951 3.17223 0.699951 2.17813Z"
        fill={props.fill ?? "#E9F4FF"}
      />
      <path
        d="M0.699951 9.37813C0.699951 8.38402 1.50584 7.57813 2.49995 7.57813H13.3C14.2941 7.57813 15.1 8.38402 15.1 9.37813C15.1 10.3722 14.2941 11.1781 13.3 11.1781H2.49995C1.50584 11.1781 0.699951 10.3722 0.699951 9.37813Z"
        fill={props.fill ?? "#E9F4FF"}
      />
      <path
        d="M0.699951 16.5781C0.699951 15.584 1.50584 14.7781 2.49995 14.7781H20.5C21.4941 14.7781 22.2999 15.584 22.2999 16.5781C22.2999 17.5722 21.4941 18.3781 20.5 18.3781H2.49995C1.50584 18.3781 0.699951 17.5722 0.699951 16.5781Z"
        fill={props.fill ?? "#E9F4FF"}
      />
    </svg>
  );
};

export default DropLines;
