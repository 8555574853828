declare global {
  interface Window {
    gtag: (type: string, eventAction: string, eventData: any) => void;
  }
}

export const TrackGoogleAnalyticsEvent = ({
  action,
  category,
  label,
  value,
}: {
  action: any;
  category: any;
  label: any;
  value: any;
}) => {
  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
  });
};
