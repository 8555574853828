import React from 'react';
import { AppContext, } from './AppContext';

export function withApp(Component: React.ComponentType<any>) {
	const render = (props: any) => (
		<AppContext.Consumer>
			{ (context) => (<Component { ...props} { ...context } />) }
		</AppContext.Consumer>
	);

	return render;
}
