import { useRef, useState } from "react";
import { Root, Label, ImageWrapper } from "./styles";
import Image from "next/image";
import { Input } from "../styles";
import SaveButton from "../SaveButton";
import { validate } from "multicoin-address-validator";
import { triggerNotification } from "components/TheComponents/Notification";
import styled from "styled-components";
import { useProfileContext } from "components/public/ProfileLayout/ProfileContext";
import Hidden from "/public/images/icons/eye-hidden.svg";
import Visible from "/public/images/icons/eye-visible.svg";

export enum WalletAssetEnum {
  BTC = "BTC",
  ETH = "ETH",
  LTC = "LTC",
  USDT = "USDT",
  USDT_ERC = "USDT ERC",
  // TODO
  // USDT_TRC = "USDT TRC",
}

export type AddressInputProps = {
  asset: WalletAssetEnum;
  address: string;
  saveNewAddress: (value: string | null) => void;
  disabled?: boolean;
};

const assetIconMap = {
  [WalletAssetEnum.BTC]: "/images/icons/btc.svg",
  [WalletAssetEnum.ETH]: "/images/icons/eth.svg",
  [WalletAssetEnum.LTC]: "/images/icons/eth.svg",
  [WalletAssetEnum.USDT]: "/images/icons/usdt.svg",
  [WalletAssetEnum.USDT_ERC]: "/images/icons/usdt.svg",
  // [WalletAssetEnum.USDT_TRC]: "/images/icons/usdt.svg",
};

const AddressInput: React.FC<AddressInputProps> = ({
  asset,
  address,
  saveNewAddress,
  disabled,
}) => {
  const [value, setValue] = useState<string>(address ?? "");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isHidden, setHidden] = useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement>(null);
  const { isAdminCanEdit } = useProfileContext();

  return (
    <Root active={isActive}>
      <Label>
        <ImageWrapper>
          <Image src={assetIconMap[asset]} alt={asset} height={10} width={10} />
        </ImageWrapper>
        {asset}
      </Label>
      <Input
        type={isHidden ? "password" : "text"}
        disabled={!isActive}
        ref={inputRef}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      {isAdminCanEdit && <ClearButton onClick={() => saveNewAddress(null)}>clear</ClearButton>}
      <ClearButton onClick={() => setHidden((p) => !p)}>{isHidden ? <Hidden width={18} height={18} /> : <Visible width={18} height={18} />}</ClearButton>
      {!disabled && (
        <SaveButton
          isActive={isActive}
          onClick={() => {
            setIsActive((prev) => {
              if (prev) {
                if (validate(value, asset === WalletAssetEnum.USDT_ERC ? "eth" : asset)) {
                  saveNewAddress(value);
                } else {
                  triggerNotification({
                    type: "error",
                    text: "Address validation error",
                  });
                }
              }
              return !prev;
            });
            setTimeout(() => {
              inputRef.current?.focus();
            }, 100);
          }}
        />
      )}
    </Root>
  );
};

export const ClearButton = styled.button`
  margin: 8px;
  display: flex;
  gap: 4px;
  min-width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: #1b2035;
  border: 1px solid #2a3046;
  border-radius: 8px;
  color: #d5e0f5;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.75;
  }
`;

export default AddressInput;
