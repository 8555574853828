import React from "react";
import { useDropzone } from "react-dropzone";
import styled, { css } from "styled-components";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { IStyles, $styles } from "components";
import { Flex, Button, ButtonBlue } from "components/common";
import { IAppContext, withApp } from "contexts";
import Image from "next/image";
import { TheCheckbox, TheFieldset, TheIcon } from "components/TheComponents";
import { SiteRewardsActionModalProps } from "./SiteRewardsActionModal";
import { triggerNotification } from "components/TheComponents/Notification";
import MFAModal from "components/ScreenComponent/MFAModals/MFAModal";
import QRСodeModal from "components/ScreenComponent/MFAModals/QRСodeModal";
import GalleryDropdown from "components/common/GalleryDropdown";

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;

  ${$styles}
`;

const Label = styled.label<IStyles>`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  ${$styles}
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: var(--font-family-golos);
  font-size: 18px;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;

interface ItemToAction {
  isFeatured: boolean;
  hasDiscordBonuses: boolean;
  hasLeaderboard: boolean;
  logoUrl: string;
  imageUrl: string;
  mobileImageUrl: string;
  refCode: string;
  refUrl: string;
  bonusTitle: string;
}

const SiteRewardsActionLayout: React.FC<SiteRewardsActionModalProps & IAppContext> = ({
  selectedReward,
  profile,
  showQrModal,
  setShowQrModal,
  onAction,
}): React.ReactElement => {
  const isMfaEnabled = !!profile?.mfaSecret;
  const [showVerifyModal, setShowVerifyModal] = React.useState<boolean>(false);

  const [payload, setPayload] = React.useState<ItemToAction>(
    selectedReward
      ? {
          isFeatured: selectedReward.isFeatured,
          hasLeaderboard: selectedReward.hasLeaderboard,
          hasDiscordBonuses: selectedReward.hasDiscordBonuses,
          logoUrl: selectedReward.logoUrl,
          imageUrl: selectedReward.imageUrl || "",
          mobileImageUrl: selectedReward.mobileImageUrl || "",
          refCode: selectedReward.refCode,
          refUrl: selectedReward.refUrl,
          bonusTitle: selectedReward.bonusTitle,
        }
      : {
          isFeatured: false,
          hasLeaderboard: false,
          hasDiscordBonuses: false,
          logoUrl: "",
          imageUrl: "",
          mobileImageUrl: "",
          refCode: "",
          refUrl: "",
          bonusTitle: "",
        }
  );
  const [firstFilePreview, setFirstFilePreview] = React.useState<string | null>(null);
  const [secondFilePreview, setSecondFilePreview] = React.useState<string | null>(null);
  const [thirdFilePreview, setThirdFilePreview] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const {
    acceptedFiles: firstAcceptedFiles,
    open: openFirst,
    getRootProps: firstRootProps,
    getInputProps: firstInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    onDrop: (acceptedFiles) => {
      setFirstFilePreview(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }).preview
      );
    },
    maxFiles: 1,
  });

  const {
    acceptedFiles: secondAcceptedFiles,
    open: openSecond,
    getRootProps: secondRootProps,
    getInputProps: secondInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    onDrop: (acceptedFiles) => {
      setSecondFilePreview(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }).preview
      );
    },
    maxFiles: 1,
  });

  const {
    acceptedFiles: thirdAcceptedFiles,
    open: openThird,
    getRootProps: thirdRootProps,
    getInputProps: thirdInputProps,
  } = useDropzone({
    accept: { "image/*": [] },
    onDrop: (acceptedFiles) => {
      setThirdFilePreview(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        }).preview
      );
    },
    maxFiles: 1,
  });

  const numProps = { pattern: "^[d]*$", type: "text" };

  const [errors, setErrors] = React.useState<{
    [key in keyof ItemToAction]?: string;
  }>({});

  const validateForm = () => {
    const newErrors: { [key in keyof ItemToAction]?: string } = {};

    if (!payload.logoUrl && !firstAcceptedFiles[0]) {
      newErrors.logoUrl = "Add logo";
    }
    if (!payload.refUrl) {
      newErrors.refUrl = "Url is required";
    }
    if (!payload.refCode) {
      newErrors.refCode = "Code is required";
    }
    if (!payload.bonusTitle) {
      newErrors.bonusTitle = "Bonus Title is required";
    }

    if (payload.bonusTitle.length > 50) {
      newErrors.bonusTitle = "Bonus Title is too long";
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const createOrUpdateItem = async ({ otp }: { otp: string }) => {
    try {
      setIsLoading(true);
      const formData = new FormData();

      for (const [key, value] of Object.entries(payload)) {
        if (key !== "logoUrl" && key !== "imageUrl" && key !== "mobileImageUrl") {
          formData.append(key, String(value));
        }
      }
      // FILES
      if (firstAcceptedFiles[0]) {
        formData.append("logoFile", firstAcceptedFiles[0]);
      } else if (payload.logoUrl) {
        formData.append("logoUrl", payload.logoUrl);
      }

      if (secondAcceptedFiles[0]) {
        formData.append("imageFile", secondAcceptedFiles[0]);
      } else if (payload.imageUrl) {
        formData.append("imageUrl", payload.imageUrl);
      }

      if (thirdAcceptedFiles[0]) {
        formData.append("mobileImageFile", thirdAcceptedFiles[0]);
      } else if (payload.mobileImageUrl) {
        formData.append("mobileImageUrl", payload.mobileImageUrl);
      }
      //

      const urlOrigin: string = process.env.NEXT_PUBLIC_APP_BE_ORIGIN ?? "http://localhost:4000";
      const response = await fetch(
        selectedReward
          ? `${urlOrigin}/site-rewards/${selectedReward.id}`
          : `${urlOrigin}/site-rewards/create`,
        {
          method: selectedReward ? "PUT" : "POST",
          body: formData,
          headers: {
            "x-otp": otp,
          },
          credentials: "include",
        }
      );
      const data = await response?.json();
      if (response && (response.status === 200 || response.status === 201)) {
        triggerNotification({
          text: selectedReward ? "Site Reward Updated!" : "Site Reward Created!",
          type: "success",
        });
        onAction(selectedReward ? "edit" : "add");
      } else {
        triggerNotification({ text: data?.message, type: "error" });
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  return (
    <Flex column>
      <Title>{selectedReward ? "EDIT ITEM" : "NEW SITE REWARD"}</Title>
      <Title>LOGO</Title>
      <BoxItem style={{ marginBottom: 20 }}>
        <Flex width="100%">
          <div
            {...firstRootProps({ className: "dropzone" })}
            style={{ width: "30%", height: "112px" }}
          >
            <input {...firstInputProps()} />
            {(firstFilePreview && (
              <Image src={firstFilePreview} alt="image" width={120} height={112} />
            )) ||
              (payload.logoUrl && (
                <div
                  style={{
                    width: 120,
                    height: 112,
                    backgroundImage: `url(${payload.logoUrl})`,
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: " center 20%",
                    backgroundClip: "border-box",
                    borderRadius: 8,
                  }}
                />
              )) || (
                <div
                  style={{
                    width: 120,
                    height: 112,
                    backgroundColor: "#101622",
                    borderRadius: 8,
                  }}
                />
              )}
          </div>
          <Flex style={{ width: "70%" }} column>
            <TheFieldset
              valid={!errors.logoUrl}
              styles={css`
                width: 100%;
                margin: 0;
              `}
              title="Image"
            >
              <input
                {...numProps}
                placeholder={errors.logoUrl ? errors.logoUrl : "Image (Link)"}
                value={payload.logoUrl}
                onChange={(e) =>
                  setPayload((prevPayload) => ({
                    ...prevPayload,
                    logoUrl: e.target.value,
                  }))
                }
              />
            </TheFieldset>
            <Flex width="100%">
              <BoxItem
                styles={css`
                  margin-top: 8px;
                  width: 80%;
                `}
              >
                <Button
                  styles={css`
                    height: 50px;
                    background: #181f2e;
                    color: #99abbd;
                    box-shadow: none;
                  `}
                  onClick={openFirst}
                  isDark
                >
                  <ButtonBlue isDark>Choose file</ButtonBlue>
                </Button>
              </BoxItem>

              <BoxItem
                styles={css`
                  margin-top: 8px;
                  width: 20%;
                `}
              >
                <Button
                  onClick={() => {
                    setFirstFilePreview(null);
                  }}
                  style={{ padding: "6px 16px", height: 50, marginLeft: 10 }}
                  isDark
                >
                  <TheIcon icon="action:remove" size={16} />
                </Button>
              </BoxItem>
            </Flex>
            <GalleryDropdown
              onSelect={(logoUrl: string) =>
                setPayload((prevPayload) => ({ ...prevPayload, logoUrl }))
              }
            />
          </Flex>
        </Flex>
      </BoxItem>
      <Flex data-tooltip-id="warning-tooltip" style={{ marginBottom: 20 }}>
        <Title
          styles={css`
            margin-bottom: 0px;
          `}
        >
          DESKTOP IMAGE
        </Title>
        <Image
          style={{ marginTop: 3, marginLeft: 5 }}
          width={16}
          height={16}
          src="/images/PointShopComponent/warning.svg"
          alt="warning"
        />
      </Flex>
      <ReactTooltip
        style={{
          background: "black",
          zIndex: 1,
          opacity: "0.3",
          borderRadius: "8px",
          // width: 350,
          padding: 10,
        }}
        id="warning-tooltip"
      >
        <Flex scroll justify="center" flexWrap>
          Please use 1280x290
        </Flex>
      </ReactTooltip>
      <BoxItem style={{ marginBottom: 20 }}>
        <Flex width="100%">
          <div
            {...secondRootProps({ className: "dropzone" })}
            style={{ width: "30%", height: "112px" }}
          >
            <input {...secondInputProps()} />
            {(secondFilePreview && (
              <Image src={secondFilePreview} alt="image" width={120} height={112} />
            )) ||
              (payload.imageUrl && (
                <div
                  style={{
                    width: 120,
                    height: 112,
                    backgroundImage: `url(${payload.imageUrl})`,
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: " center 20%",
                    backgroundClip: "border-box",
                    borderRadius: 8,
                  }}
                />
              )) || (
                <div
                  style={{
                    width: 120,
                    height: 112,
                    backgroundColor: "#101622",
                    borderRadius: 8,
                  }}
                />
              )}
          </div>
          <Flex style={{ width: "70%" }} column>
            <TheFieldset
              styles={css`
                width: 100%;
                margin: 0;
              `}
              title="Image"
            >
              <input
                {...numProps}
                placeholder="Image (Link)"
                value={payload.imageUrl}
                onChange={(e) =>
                  setPayload((prevPayload) => ({
                    ...prevPayload,
                    imageUrl: e.target.value,
                  }))
                }
              />
            </TheFieldset>
            <Flex width="100%">
              <BoxItem
                styles={css`
                  margin-top: 8px;
                  width: 80%;
                `}
              >
                <Button
                  styles={css`
                    height: 50px;
                    background: #181f2e;
                    color: #99abbd;
                    box-shadow: none;
                  `}
                  onClick={openSecond}
                  isDark
                >
                  <ButtonBlue isDark>Choose file</ButtonBlue>
                </Button>
              </BoxItem>
              <BoxItem
                styles={css`
                  margin-top: 8px;
                  width: 20%;
                `}
              >
                <Button
                  onClick={() => {
                    setSecondFilePreview(null);
                  }}
                  style={{ padding: "6px 16px", height: 50, marginLeft: 10 }}
                  isDark
                >
                  <TheIcon icon="action:remove" size={16} />
                </Button>
              </BoxItem>
            </Flex>
            <GalleryDropdown
              onSelect={(imageUrl: string) =>
                setPayload((prevPayload) => ({ ...prevPayload, imageUrl }))
              }
            />
          </Flex>
        </Flex>
      </BoxItem>
      <Title>MOBILE IMAGE</Title>
      <BoxItem style={{ marginBottom: 20 }}>
        <Flex width="100%">
          <div
            {...thirdRootProps({ className: "dropzone" })}
            style={{ width: "30%", height: "112px" }}
          >
            <input {...thirdInputProps()} />
            {(thirdFilePreview && (
              <Image src={thirdFilePreview} alt="image" width={120} height={112} />
            )) ||
              (payload.mobileImageUrl && (
                <div
                  style={{
                    width: 120,
                    height: 112,
                    backgroundImage: `url(${payload.mobileImageUrl})`,
                    backgroundSize: "100%",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: " center 20%",
                    backgroundClip: "border-box",
                    borderRadius: 8,
                  }}
                />
              )) || (
                <div
                  style={{
                    width: 120,
                    height: 112,
                    backgroundColor: "#101622",
                    borderRadius: 8,
                  }}
                />
              )}
          </div>
          <Flex style={{ width: "70%" }} column>
            <TheFieldset
              styles={css`
                width: 100%;
                margin: 0;
              `}
              title="Mobile Image"
            >
              <input
                {...numProps}
                placeholder="Image (Link)"
                value={payload.mobileImageUrl}
                onChange={(e) =>
                  setPayload((prevPayload) => ({
                    ...prevPayload,
                    mobileImageUrl: e.target.value,
                  }))
                }
              />
            </TheFieldset>
            <Flex width="100%">
              <BoxItem
                styles={css`
                  margin-top: 8px;
                  width: 80%;
                `}
              >
                <Button
                  styles={css`
                    height: 50px;
                    background: #181f2e;
                    color: #99abbd;
                    box-shadow: none;
                  `}
                  onClick={openThird}
                  isDark
                >
                  <ButtonBlue isDark>Choose file</ButtonBlue>
                </Button>
              </BoxItem>
              <BoxItem
                styles={css`
                  margin-top: 8px;
                  width: 20%;
                `}
              >
                <Button
                  onClick={() => {
                    setThirdFilePreview(null);
                  }}
                  style={{ padding: "6px 16px", height: 50, marginLeft: 10 }}
                  isDark
                >
                  <TheIcon icon="action:remove" size={16} />
                </Button>
              </BoxItem>
            </Flex>
            <GalleryDropdown
              onSelect={(mobileImageUrl: string) =>
                setPayload((prevPayload) => ({ ...prevPayload, mobileImageUrl }))
              }
            />
          </Flex>
        </Flex>
      </BoxItem>
      <Flex justify="space-between" width="100%" height={40} style={{ gap: 10, fontSize: 15 }}>
        <BoxItem
          styles={css`
            margin-bottom: 20px;
            width: fit-content;
          `}
        >
          <Label
            onClick={() =>
              setPayload((prevPayload) => ({
                ...prevPayload,
                isFeatured: !prevPayload.isFeatured,
              }))
            }
            styles={css`
              margin: 0;
              justify-content: flex-start;
              align-items: center;
              gap: 22px;
            `}
          >
            <TheCheckbox
              checked={payload.isFeatured}
              preset="dark"
              onChange={() =>
                setPayload((prevPayload) => ({
                  ...prevPayload,
                  isFeatured: !prevPayload.isFeatured,
                }))
              }
            />
            <BoxItem
              styles={css`
                max-width: 440px;
                justify-content: flex-start;
                text-align: left;
                display: inline-box;
              `}
            >
              IS FEATURED
            </BoxItem>
          </Label>
        </BoxItem>
        <BoxItem
          styles={css`
            margin-bottom: 20px;
            width: fit-content;
          `}
        >
          <Label
            onClick={() =>
              setPayload((prevPayload) => ({
                ...prevPayload,
                hasDiscordBonuses: !prevPayload.hasDiscordBonuses,
              }))
            }
            styles={css`
              margin: 0;
              justify-content: flex-start;
              align-items: center;
              gap: 22px;
            `}
          >
            <TheCheckbox
              checked={payload.hasDiscordBonuses}
              preset="dark"
              onChange={() =>
                setPayload((prevPayload) => ({
                  ...prevPayload,
                  hasDiscordBonuses: !prevPayload.hasDiscordBonuses,
                }))
              }
            />
            <BoxItem
              styles={css`
                max-width: 440px;
                justify-content: flex-start;
                text-align: left;
                display: inline-box;
              `}
            >
              DISCORD
            </BoxItem>
          </Label>
        </BoxItem>
        <BoxItem
          styles={css`
            margin-bottom: 20px;
            width: fit-content;
          `}
        >
          <Label
            onClick={() =>
              setPayload((prevPayload) => ({
                ...prevPayload,
                hasLeaderboard: !prevPayload.hasLeaderboard,
              }))
            }
            styles={css`
              margin: 0;
              justify-content: flex-start;
              align-items: center;
              gap: 22px;
            `}
          >
            <TheCheckbox
              checked={payload.hasLeaderboard}
              preset="dark"
              onChange={() =>
                setPayload((prevPayload) => ({
                  ...prevPayload,
                  hasLeaderboard: !prevPayload.hasLeaderboard,
                }))
              }
            />
            <BoxItem
              styles={css`
                max-width: 440px;
                justify-content: flex-start;
                text-align: left;
                display: inline-box;
              `}
            >
              LEADERBOARD
            </BoxItem>
          </Label>
        </BoxItem>
      </Flex>
      <BoxItem style={{ marginBottom: 20 }}>
        <TheFieldset valid={!errors.refUrl} title="Link">
          <input
            {...numProps}
            placeholder={errors.refUrl ? errors.refUrl : "Link"}
            value={payload.refUrl}
            onChange={(e) =>
              setPayload((prevPayload) => ({
                ...prevPayload,
                refUrl: e.target.value,
              }))
            }
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem style={{ marginBottom: 20 }}>
        <TheFieldset valid={!errors.refCode} title="Code">
          <input
            {...numProps}
            placeholder={errors.refCode ? errors.refCode : "Enter code"}
            value={payload.refCode}
            onChange={(e) =>
              setPayload((prevPayload) => ({
                ...prevPayload,
                refCode: e.target.value,
              }))
            }
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem style={{ marginBottom: 20 }}>
        <TheFieldset valid={!errors.bonusTitle} title="Bonus Title">
          <input
            {...numProps}
            placeholder={errors.bonusTitle ? errors.bonusTitle : "Enter bonus title"}
            value={payload.bonusTitle}
            onChange={(e) =>
              setPayload((prevPayload) => ({
                ...prevPayload,
                bonusTitle: e.target.value,
              }))
            }
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem
        styles={css`
          margin-top: 8px;
          height: 50px;
          @media (max-width: 600px) {
            width: 50%;
          }
        `}
      >
        <Button
          height={44}
          onClick={() => {
            if (validateForm()) {
              if (isMfaEnabled) {
                setShowVerifyModal(true);
              } else {
                setShowQrModal(true);
              }
            } else {
              triggerNotification({ text: "Validation failed", type: "error" });
            }
          }}
        >
          <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
            {selectedReward ? "Edit reward" : "Create reward"}
          </ButtonBlue>
        </Button>
      </BoxItem>
      {isMfaEnabled && showVerifyModal && (
        <MFAModal
          isLoading={isLoading}
          onVerify={createOrUpdateItem}
          onClose={() => setShowVerifyModal(false)}
        />
      )}
      {showQrModal && <QRСodeModal onClose={() => setShowQrModal(false)} />}
    </Flex>
  );
};

export default withApp(SiteRewardsActionLayout);
