import numeral from 'numeral';

numeral.locale('en');

export function fixed(
	format: string,
	value: number,
	length: number = 1,
): string {
	const valueLength = `${ value }`.length;
	const formated = numeral(value).format(format);
	const fixed = formated.padStart(length - valueLength + formated.length, '0');
	return fixed;
}
