import CookieConsent from "react-cookie-consent";

import React from "react";
import { IStyles } from "components";
import { Button, ButtonBlue } from "components/common";
import { css } from "styled-components";

export const CookieBanner: React.FC<IStyles> = (): React.ReactElement => {
  const CustomButton = ({ props }: { props: any }) => (
    <Button
      {...props}
      height={30}
      styles={css`
        margin-right: 10px;
        @media (max-width: 459px) {
          margin: auto;
          width: 80%;
        }
      `}
    >
      <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
        Accept
      </ButtonBlue>
    </Button>
  );
  return (
    <CookieConsent
      location="bottom"
      cookieName="cookiesTrack"
      containerClasses="cookie-banner"
      buttonClasses="cookie-banner-button"
      buttonWrapperClasses="cookie-banner-button-wrapper"
      ButtonComponent={({ ...props }) => <CustomButton props={{ ...props, style: undefined }} />}
    >
      By clicking «Accept», you agree to the storing of cookies on your device to enhance site
      navigation, analyze site usage, and assist in our marketing efforts.
    </CookieConsent>
  );
};
