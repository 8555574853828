import React from "react";
import { NotificationToastProps, NotificationType } from "types/notification";
import StreamLiveNotificationCard from "./StreamLiveNotificationCard";
import { LiveStreamData } from "helpers/types";
import { css } from "styled-components";

const StreamerCardWrapper = (data: NotificationToastProps<LiveStreamData>) => {
  const { streamer, startedAt, platform } = data.props;
  const { title, description, info } = data.notificationData;
  return (
    <StreamLiveNotificationCard
      containerStyles={css`
        max-width: 240px;
      `}
      title={title}
      description={description}
      type={NotificationType.StreamLive}
      info={{
        actionButtonText: info.actionButtonText,
        streamer,
        startedAt,
        platform,
      }}
    />
  );
};

export default StreamerCardWrapper;
