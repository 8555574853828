import { SVGProps } from "react";

export type PinballProps = SVGProps<SVGSVGElement>;

const Pinball: React.FC<PinballProps> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M7.05771 11.3097C6.72481 10.9768 6.3296 10.7127 5.89465 10.5326C5.45969 10.3524 4.99351 10.2597 4.52271 10.2597C4.05192 10.2597 3.58573 10.3524 3.15078 10.5326C2.71582 10.7127 2.32061 10.9768 1.98771 11.3097C1.65492 11.6423 1.39093 12.0372 1.21081 12.4718C1.03069 12.9065 0.937988 13.3724 0.937988 13.8428C0.937988 14.3133 1.03069 14.7792 1.21081 15.2138C1.39093 15.6485 1.65492 16.0434 1.98771 16.376C2.43021 16.8222 8.62521 21.8585 11.3252 21.8585C11.4887 21.8687 11.6525 21.8461 11.8071 21.792C11.9617 21.7379 12.1038 21.6534 12.2252 21.5435C12.7502 21.0372 13.0052 19.9647 10.6052 16.1247C9.55809 14.4242 8.37151 12.8137 7.05771 11.3097ZM5.80521 15.1235C5.50877 15.4212 5.11821 15.6069 4.70015 15.6489C4.28209 15.6908 3.86243 15.5864 3.51275 15.3535C3.16307 15.1205 2.90503 14.7735 2.78265 14.3716C2.66028 13.9696 2.68114 13.5377 2.84169 13.1494C3.00224 12.7611 3.29253 12.4406 3.66303 12.2424C4.03354 12.0443 4.46131 11.9808 4.87338 12.0629C5.28545 12.1449 5.65629 12.3674 5.92265 12.6923C6.18901 13.0173 6.33438 13.4246 6.33396 13.8447C6.33454 14.0822 6.28812 14.3175 6.19737 14.5369C6.10663 14.7564 5.97335 14.9557 5.80521 15.1235Z"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      />
      <path
        d="M22.0127 4.74722C21.6798 4.41431 21.2846 4.15024 20.8496 3.97007C20.4146 3.7899 19.9485 3.69717 19.4777 3.69717C19.0069 3.69717 18.5407 3.7899 18.1057 3.97007C17.6708 4.15024 17.2756 4.41431 16.9427 4.74722C15.6287 6.2524 14.4421 7.8642 13.3952 9.56597C10.9952 13.4022 11.2689 14.4747 11.7752 14.9997C11.8964 15.1098 12.0386 15.1945 12.1932 15.2486C12.3478 15.3027 12.5117 15.3252 12.6752 15.3147C15.3864 15.3147 21.5702 10.2785 22.0127 9.83222C22.3455 9.49964 22.6094 9.10475 22.7896 8.6701C22.9697 8.23546 23.0624 7.76958 23.0624 7.29909C23.0624 6.82861 22.9697 6.36273 22.7896 5.92809C22.6094 5.49344 22.3455 5.09855 22.0127 4.76597V4.74722ZM20.7602 8.56097C20.5919 8.72968 20.392 8.86354 20.1719 8.95488C19.9519 9.04621 19.7159 9.09323 19.4777 9.09323C19.2394 9.09323 19.0035 9.04621 18.7834 8.95488C18.5633 8.86354 18.3634 8.72968 18.1952 8.56097C18.027 8.39255 17.8936 8.19265 17.8027 7.9727C17.7118 7.75274 17.6651 7.51703 17.6653 7.27902C17.6654 7.04101 17.7125 6.80537 17.8037 6.58554C17.895 6.36572 18.0286 6.16602 18.197 5.99784C18.3655 5.82967 18.5654 5.69632 18.7853 5.60539C19.0053 5.51447 19.241 5.46777 19.479 5.46794C19.717 5.46811 19.9526 5.51517 20.1725 5.60641C20.3923 5.69765 20.592 5.8313 20.7602 5.99972C21.0989 6.33987 21.289 6.80033 21.289 7.28034C21.289 7.76035 21.0989 8.22082 20.7602 8.56097Z"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      />
      <path
        d="M10.8753 6.77598C11.1812 6.77628 11.484 6.71613 11.7666 6.59901C12.0491 6.48188 12.3057 6.31008 12.5216 6.09348C12.9015 5.71286 13.1377 5.21231 13.19 4.67708C13.2423 4.14185 13.1074 3.60504 12.8084 3.15808C12.5093 2.71112 12.0646 2.38165 11.5499 2.22578C11.0352 2.06991 10.4824 2.09728 9.98562 2.30323C9.48884 2.50919 9.07882 2.88099 8.82539 3.3553C8.57196 3.82962 8.49079 4.37713 8.59571 4.90457C8.70062 5.43202 8.98514 5.90678 9.4008 6.24801C9.81645 6.58923 10.3375 6.77581 10.8753 6.77598Z"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      />
    </svg>
  );
};

export default Pinball;
