import { DualRingInner, DualRingLoader, Flex } from "components";
import { FlexProps } from "../types";

export type LoaderProps = {} & FlexProps;

const Loader: React.FC<LoaderProps> = (props) => {
    return (
        <Flex align="center" justify="center" {...props}>
            <DualRingLoader>
                <DualRingInner />
            </DualRingLoader>
        </Flex>
    )
}

export default Loader;