import Image from "next/image";
import Link from "next/link";
import React from "react";
import styled, { css } from "styled-components";
import { $styles, $stylesReset, IStyles, Ident, TheIcon, Button, ButtonBlue } from "components";
import { DualRingInner, DualRingLoader, Flex, Pages } from "components/common";
import { IAppContext, withApp } from "contexts";
import SiteRewardsDeleteModal from "components/admin/site-rewards/Modals/SiteRewardsDeleteModal";
import FeaturedCasinoComponent from "../FeatureCasinoComponent";
import StartEarningRewardsComponentCard from "./StartEarningRewardsComponentCard";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, rectSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import RewardsGrid from "./RewardsGrid";
import { useRouter } from "next/router";
import { Select, SelectContainer } from "components/TheComponents/TheSelect";
import ChakraModal from "components/TheComponents/Modal";
import { useDisclosure } from "@chakra-ui/react";
import SiteRewardsActionLayout from "components/admin/site-rewards/Modals/SiteRewardsActionLayout";

export interface IReward {
  id: string;
  refUrl: string;
  bonusTitle: string;
  refCode: string;
  logoUrl: string;
  imageUrl: string | null;
  mobileImageUrl: string | null;
  isFeatured: boolean;
  hasLeaderboard: boolean;
  hasDiscordBonuses: boolean;
  createdAt: string;
  updatedAt: string;
}
const BorderBottomDiv = styled.div`
  border-bottom: 1px solid #1d2431;
  margin: auto;
  width: 100%;
  height: 100%;
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: var(--font-family-golos);
  font-size: 18px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;

const pageSizes = [12, 24, 48, 96];

export interface IStartEarningRewardsComponent {
  id?: string;
  isAdminPage?: boolean;
}

export const onCopy = async (item: IReward) => {
  try {
    // Clipboard
    if (navigator.clipboard instanceof Clipboard) {
      await navigator.clipboard.writeText(item.refCode);
    } else {
      throw new Error("Clipboard undefined!");
    }
  } catch (error) {
    console.error("Could not copy text: ", error);
  }
};

const StartEarningRewardsComponent: React.FC<IStartEarningRewardsComponent & IAppContext> = ({
  isAdminPage,
  accessFetch,
}): React.ReactElement => {
  const { pathname } = useRouter();

  const showFullTable = isAdminPage || pathname.includes("/all-bonuses");

  const [rewards, setRewards] = React.useState<IReward[]>([]);
  const [featuredReward, setFeaturedReward] = React.useState<IReward>();
  const [selectedReward, setSelectedReward] = React.useState<IReward | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const [skip, setSkip] = React.useState<number>(0);
  const [take, setTake] = React.useState<number>(showFullTable ? 24 : 4);
  const [total, setTotal] = React.useState<number>(0);

  const { isOpen: isDeleteOpen, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();
  const { isOpen: isEditOpen, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const { isOpen: isAddOpen, onOpen: onOpenAdd, onClose: onCloseAdd } = useDisclosure();

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const fetchData = React.useCallback(async () => {
    try {
      const nonFeaturedResponse = await accessFetch(`/site-rewards?skip=${skip}&take=${take}`);
      const nonFeaturedData = await nonFeaturedResponse?.json();
      setRewards(nonFeaturedData?.items ?? []);
      setTotal(nonFeaturedData?.totalCount);

      const featuredResponse = await accessFetch(`/site-rewards/featured`);
      const featuredData = await featuredResponse?.json();
      setFeaturedReward(featuredData?.items?.[0]);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [accessFetch, skip, take]);

  React.useEffect(() => {
    fetchData(); // Initial data fetch
  }, [fetchData]);

  const onActionClose = () => {
    onCloseAdd();
    onCloseEdit();
    onCloseDelete();
    setSelectedReward(null);
  };

  const onAction = React.useMemo(() => {
    return (action: string) => {
      if (action === "add") {
        onCloseAdd();
      } else if (action === "edit") {
        onCloseEdit();
      } else if (action === "delete") {
        onCloseDelete();
      }
      setSelectedReward(null);
      fetchData();
    };
  }, [fetchData, onCloseDelete, onCloseAdd, onCloseEdit]);

  const changeOrder = React.useCallback(async (updatedItems: IReward[], skip: number) => {
    try {
      const urlOrigin = process.env.NEXT_PUBLIC_APP_BE_ORIGIN || "http://localhost:4000";
      await fetch(`${urlOrigin}/site-rewards/change-order`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          items: updatedItems.map((item: IReward, index: number) => ({
            id: item.id,
            order: index + 1 + skip,
          })),
        }),
        credentials: "include",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, []);

  const emptySlotsCount = total > 4 ? 0 : Math.max(4 - total, 0);

  const handleDragEnd = React.useCallback(
    (event: DragEndEvent) => {
      const { active, over } = event;
      if (active.id !== over?.id) {
        setRewards((prev: IReward[]) => {
          const oldIndex = prev.findIndex((item: IReward) => item.id === active.id);
          const newIndex = prev.findIndex((item: IReward) => item.id === over!.id);
          const updatedItems = arrayMove(prev, oldIndex, newIndex);

          if (isAdminPage) {
            changeOrder(updatedItems, skip);
          }

          return updatedItems;
        });
      }
    },
    [isAdminPage, changeOrder, skip]
  );

  return (
    <Box>
      <Screen>
        <Bg>
          <Img
            src="/images/StartEarningRewardsComponent/subtract.svg"
            fill={true}
            alt="bg"
            styles={css`
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              object-fit: contain;
            `}
          />
        </Bg>
        {isLoading ? (
          <Flex align="center" justify="center" padding="60px 0px">
            <DualRingLoader>
              <DualRingInner />
            </DualRingLoader>
          </Flex>
        ) : (
          <Content>
            {!isAdminPage ? (
              <>
                <Ident height={48} />
                <TextTitle>
                  START &nbsp;
                  <span data-blue>EARNING REWARDS</span>
                  &nbsp; INSTANTLY
                </TextTitle>
                <Text>
                  <br />
                  Register & Use Codes for Bonuses!
                  <br />
                  <br />
                  <br />
                </Text>
              </>
            ) : (
              <Flex align="flex-end" margin="0px 0px 20px" width="100%">
                <Flex align="flex-end" width="100%">
                  <Title>WEBSITES</Title>
                  <Flex width="100%">
                    <BorderBottomDiv style={{ marginLeft: 30 }} />
                  </Flex>
                </Flex>
                <BoxItem style={{ marginLeft: 30 }}>
                  <Button
                    onClick={() => {
                      onOpenAdd?.();
                    }}
                  >
                    <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
                      Add new
                    </ButtonBlue>
                  </Button>
                </BoxItem>
              </Flex>
            )}
            {isAdminPage && featuredReward ? (
              <Flex align="center" justify="space-between" width="100%">
                <Title style={{ marginBottom: 20 }}>Featured casino</Title>
                <Flex style={{ marginBottom: 20 }}>
                  <Button
                    onClick={() => {
                      onOpenEdit();
                      setSelectedReward(featuredReward);
                    }}
                    style={{ marginRight: 15, padding: "6px 16px" }}
                    isDark
                  >
                    <ButtonBlue style={{ width: 20 }} isDark>
                      <TheIcon icon="action:edit" size={16} />
                    </ButtonBlue>
                  </Button>
                  <Button
                    onClick={() => {
                      onOpenDelete();
                      setSelectedReward(featuredReward);
                    }}
                    style={{ padding: "6px 16px" }}
                    isDark
                  >
                    <ButtonBlue isDark>
                      <TheIcon icon="action:remove" size={16} />
                    </ButtonBlue>
                  </Button>
                </Flex>
              </Flex>
            ) : null}
            {isAdminPage ? (
              <>
                {featuredReward ? (
                  <FeaturedCasinoComponent featuredReward={featuredReward} />
                ) : (
                  <Card
                    style={{
                      border: "1px dashed rgba(210,234,255,0.25)",
                      width: "100%",
                      height: "232px",
                      marginBottom: 20,
                    }}
                  >
                    <Flex width="100%" height="100%" align="center" justify="center">
                      <h1 style={{ fontSize: 30 }}>Empty Slot</h1>
                    </Flex>
                  </Card>
                )}
              </>
            ) : (
              <>{featuredReward && <FeaturedCasinoComponent featuredReward={featuredReward} />}</>
            )}
            {isAdminPage ? (
              <Flex width="100%">
                <Title style={{ marginBottom: 20 }}>SITE REWARDS</Title>
              </Flex>
            ) : null}
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
            >
              {isAdminPage ? (
                <RewardsGrid isAdminPage={true}>
                  {rewards
                    .map((item) => (
                      <SortableContext
                        key={item?.id}
                        items={rewards}
                        strategy={rectSortingStrategy}
                      >
                        <SortableItem
                          item={item}
                          id={item.id}
                          isAdminPage={isAdminPage}
                          onOpenEdit={onOpenEdit}
                          onOpenDelete={onOpenDelete}
                          setSelectedReward={setSelectedReward}
                        />
                      </SortableContext>
                    ))
                    .concat(
                      Array(emptySlotsCount)
                        .fill(null)
                        .map((_, index) => (
                          <Card
                            style={{
                              border: "1px dashed rgba(210,234,255,0.25)",
                            }}
                            styles={css`
                              width: calc(33% - 9px) !important;
                              height: 500px;
                            `}
                            key={index}
                          >
                            <Flex width="100%" height="100%" align="center" justify="center">
                              <h1 style={{ fontSize: 30 }}>Empty Slot</h1>
                            </Flex>
                          </Card>
                        ))
                    )}
                </RewardsGrid>
              ) : (
                <RewardsGrid>
                  {rewards
                    .filter((el) => !el.isFeatured)
                    .map((item) => (
                      <StartEarningRewardsComponentCard
                        key={item?.id}
                        item={item}
                        width={`100% - 18px`}
                        isAdminPage={isAdminPage}
                        onOpenEdit={onOpenEdit}
                        onOpenDelete={onOpenDelete}
                        setSelectedReward={setSelectedReward}
                      />
                    ))}
                </RewardsGrid>
              )}
              {total > take && showFullTable ? (
                <Flex style={{ marginTop: 20 }}>
                  <SelectContainer
                    styles={css`
                      width: 60px;
                      margin-right: 15px;
                    `}
                  >
                    <Select
                      placeholder="PAGE SIZE"
                      value={take}
                      styles={css`
                        height: 35px;
                        background-position: 88% center;
                      `}
                      onChange={(e) => {
                        const newTake = +e.target.value;
                        setSkip(0);
                        setTake(newTake);
                      }}
                    >
                      {pageSizes.map((o) => (
                        <option key={o} value={o}>
                          {o}
                        </option>
                      ))}
                    </Select>
                  </SelectContainer>
                  <Pages
                    showInfo={false}
                    skip={skip}
                    take={take}
                    total={total}
                    onClick={(index: number) => {
                      setSkip(index * take);
                      setTake(take);
                    }}
                  />
                </Flex>
              ) : null}
            </DndContext>
          </Content>
        )}
      </Screen>
      <ChakraModal
        isOpen={isDeleteOpen}
        onClose={onActionClose}
        content={
          <SiteRewardsDeleteModal
            onDelete={onAction}
            selectedRewardId={selectedReward?.id}
            onClose={onActionClose}
          />
        }
      />
      <ChakraModal
        isOpen={isEditOpen || isAddOpen}
        onClose={onActionClose}
        styles={css`
          max-height: 600px;
        `}
        content={
          <SiteRewardsActionLayout
            selectedReward={selectedReward}
            onAction={onAction}
            onClose={onActionClose}
          />
        }
      />
    </Box>
  );
};

export default withApp(StartEarningRewardsComponent);

export const Card = styled.div<{ isAdminPage?: boolean } & IStyles>`
  box-sizing: border-box;
  position: relative;
  height: 460px;
  flex-shrink: 0;
  background: radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    #101622;
  border: 1px solid rgba(210, 234, 255, 0.1);
  border-radius: 8px;

  ${({ isAdminPage }) =>
    isAdminPage
      ? css`
          width: 100% !important;
        `
      : css`
          @media (min-width: 1280px) {
            width: calc(25% - 9px);
          }
          @media (max-width: 1279px) {
            width: calc(50% - 6px);
          }
          @media (max-width: 800px) {
            width: 100%;
          }
        `}
  ${({ styles }) => styles}
`;

const Box = styled.div`
  ${$stylesReset}
  background-color: #0b0e16;
`;

const Screen = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
`;

const Content = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  width: 1280px;
  margin: 24px;
  overflow: hidden;
  @media (min-width: 800px) {
    /* padding-bottom: 112px; */
  }
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

const TextTitle = styled(Text)`
  color: rgb(210, 234, 255); // #d2eaff;
  font-size: 40px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  & [data-blue] {
    color: #3b8be9;
  }
  @media (max-width: 800px) {
    color: #d2eaff;
    font-size: 28px;
    /* line-height: 130%;
		letter-spacing: 0.84px; */
  }
`;

const Img = styled(Image)<IStyles>`
  ${({ styles }) => styles}
`;

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  // width: 1920px;
  height: 319px;
`;

export const cssGift = css`
  position: absolute;
  top: -50px;
  background-size: calc(84px * 0.6) calc(88px * 0.6);
  height: 100%;
  width: 100%;
`;

export const cssNew = css`
  position: absolute;
  /* display: inline-flex; */
  /* justify-content: center; */
  top: 16px;
  right: 16px;
  z-index: 1;
  height: unset;
  width: unset;
  padding: 6px 16px !important;
  color: #0b101b;
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
`;

export const cssFeatured = css`
  position: absolute;
  /* display: inline-flex; */
  /* justify-content: center; */
  top: 16px;
  left: 16px;
  z-index: 1;
  height: unset;
  width: unset;
  padding: 6px 16px !important;
  color: #0b101b;
  font-style: normal;
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
`;

export const cssEdit = css`
  position: absolute;
  /* display: inline-flex; */
  /* justify-content: center; */
  top: 16px;
  right: 16px;
  height: unset;
  z-index: 1;
  cursor: pointer !important;
  width: unset;
  padding: 6px 16px !important;
  background-color: #1c2036;
  color: #8292a7;
  font-style: normal;
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
  text-transform: uppercase;
`;

export const BoxItem = styled.div<IStyles>`
  ${({ styles }) => styles}
`;

export const CardGift = styled.div<IStyles>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 15px 24px 20px;
  padding: 24px 8px;

  // border
  border: 1px dashed rgba(210, 234, 255, 0.25);
  border-radius: 81px;

  // text
  text-align: center;
  color: #d2eaff;
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 23px;
  text-shadow: 0px 0px 24px rgba(40, 113, 255, 0.5), 0px 0px 6px rgba(58, 113, 254, 0.46),
    0px 2px 4px #0b0e23;
  text-transform: uppercase;

  ${({ styles }) => styles}
`;

export const CardLinks = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  gap: 16px;
  margin: 0 24px 24px;

  ${({ styles }) => styles}
`;

export const CopyCode = styled.div<IStyles & { featuredCasino?: Boolean }>`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 50px;
  color: #d2eaff;
  ${({ featuredCasino }) =>
    !featuredCasino
      ? `padding: 10px calc(0px + 50px) 10px calc(0px + 50px);`
      : "padding-right:30px"};
  // background & border
  background: rgba(36, 39, 70, 0.5);
  border: 1px solid rgba(36, 39, 70, 0.5);
  border-radius: 8px;

  // text
  font-weight: 600;
  line-height: 150%;

  ${({ styles }) => styles}
`;

export const CopyLink = styled(Link)`
  position: absolute;
  right: 0;
  top: 0;
  display: inline-flex;
`;
