import { SVGProps } from "react";

export type SlotMachineProps = SVGProps<SVGSVGElement>;

const SlotMachine: React.FC<SlotMachineProps> = (props) => {
  return (
    <svg
      {...props}
      width="24px"
      height="20px"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M58.384 8H5.614C3.619 8 2 10.011 2 12.493v39.012C2 53.987 3.619 56 5.614 56h52.77C60.381 56 62 53.987 62 51.505V12.493C62 10.011 60.381 8 58.384 8M21.646 52.584h-1.134c-.346-2.755-3.79-5.034-7.802-5.032c-4.005-.002-6.753 2.276-5.885 5.032H5.691a68.68 68.68 0 0 1 0-41.17h.785a70.641 70.641 0 0 0-1.203 4.468h15.059c.154-1.499.33-2.99.526-4.468h.787c-1.657 13.378-1.657 27.791.001 41.17m18.331 0h-1.132c.262-2.755-2.839-5.034-6.845-5.032c-4.008-.002-7.106 2.276-6.845 5.032h-1.133c-1.276-13.379-1.276-27.792 0-41.17h.787c-.128 1.478-.24 2.969-.339 4.468h15.059c-.099-1.499-.213-2.99-.339-4.468h.786c1.276 13.378 1.276 27.791.001 41.17m18.332 0h-1.134c.868-2.755-1.882-5.034-5.887-5.032c-4.01-.002-7.458 2.276-7.8 5.032h-1.134c1.657-13.379 1.657-27.792 0-41.17h.786c.197 1.478.373 2.969.525 4.468h15.061a69.99 69.99 0 0 0-1.205-4.468h.787a68.672 68.672 0 0 1 .001 41.17"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      ></path>
      <path
        d="M56.234 26.942a7.428 7.428 0 0 1-.234.159c-.334-.238-.923-.632-2.077-1.028a6.71 6.71 0 0 0-2.183-.352c-1.478 0-2.828.434-3.736.819a.92.92 0 0 0-.678-.299h-1.354c-.518 0-.938.433-.938.969v5.543c0 .536.42.968.938.968h1.354a.942.942 0 0 0 .898-.692c.1-.345.397-1.391 2.504-1.391l.105.001c-2.779 2.909-3.319 6.395-3.344 6.566a.979.979 0 0 0 .217.776a.927.927 0 0 0 .71.336h6.634c.519 0 .938-.433.938-.969c0-6.042 3.45-9.594 3.727-9.868a.983.983 0 0 0 .322-.73c0-.535-.418-.969-.936-.969h-2.35a.936.936 0 0 0-.517.161M55.051 38.35h-6.634s.659-4.518 4.609-7.357c0 0-1.071-.322-2.298-.322c-1.364 0-2.921.398-3.402 2.084h-1.354v-5.543h1.354v.74s2.047-1.262 4.414-1.262c.617 0 1.257.086 1.887.303c1.807.623 1.986 1.17 2.336 1.17c.163 0 .364-.121.789-.411h2.346c0-.001-4.047 3.837-4.047 10.598"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      ></path>
      <path
        d="M38.564 26.782h-2.35a.928.928 0 0 0-.518.16a7.428 7.428 0 0 1-.234.159c-.334-.238-.923-.632-2.077-1.028a6.71 6.71 0 0 0-2.183-.352c-1.478 0-2.828.434-3.736.819a.92.92 0 0 0-.678-.299h-1.354c-.518 0-.938.433-.938.969v5.543c0 .536.42.968.938.968h1.354a.942.942 0 0 0 .898-.692c.1-.345.397-1.391 2.504-1.391l.105.001c-2.779 2.909-3.319 6.395-3.345 6.566a.983.983 0 0 0 .218.776a.927.927 0 0 0 .71.336h6.634c.519 0 .938-.433.938-.969c0-6.042 3.45-9.594 3.727-9.868a.983.983 0 0 0 .322-.73c.001-.534-.417-.968-.935-.968m-4.05 11.568H27.88s.659-4.518 4.609-7.357c0 0-1.071-.322-2.298-.322c-1.364 0-2.921.398-3.402 2.084h-1.354v-5.543h1.354v.74s2.047-1.262 4.414-1.262c.617 0 1.257.086 1.887.303c1.807.623 1.986 1.17 2.336 1.17c.163 0 .364-.121.789-.411h2.346c0-.001-4.047 3.837-4.047 10.598"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      ></path>
      <path
        d="M18.027 26.782h-2.35a.928.928 0 0 0-.518.16c-.046.033-.144.099-.234.159c-.334-.238-.923-.632-2.077-1.028a6.713 6.713 0 0 0-2.185-.352c-1.476 0-2.828.434-3.734.819a.92.92 0 0 0-.678-.299H4.898c-.518 0-.938.433-.938.969v5.543c0 .536.42.968.938.968h1.354a.942.942 0 0 0 .898-.692c.098-.345.397-1.391 2.504-1.391l.105.001c-2.779 2.909-3.319 6.395-3.345 6.566a.983.983 0 0 0 .218.776a.927.927 0 0 0 .71.336h6.634c.517 0 .938-.433.938-.969c0-6.042 3.45-9.594 3.727-9.868a.983.983 0 0 0 .322-.73c0-.534-.418-.968-.936-.968m-4.05 11.568H7.343s.659-4.518 4.609-7.357c0 0-1.071-.322-2.298-.322c-1.364 0-2.921.398-3.402 2.084H4.898v-5.543h1.354v.74s2.047-1.262 4.412-1.262c.619 0 1.259.086 1.889.303c1.807.623 1.986 1.17 2.336 1.17c.163 0 .364-.121.789-.411h2.346c-.001-.001-4.047 3.837-4.047 10.598"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      ></path>
    </svg>
  );
};

export default SlotMachine;
