export enum GeoRestrictedPage {
  GAMES = "/games",
  ALL_BONUSES = "/all-bonuses",
}

export const GeoRestrictedPages = [GeoRestrictedPage.GAMES];

export const GeoModalText = {
  [GeoRestrictedPage.GAMES]: {
    text: "Due to regulations in your country, we are unable to offer access to games.",
    title: "Sorry, Games Are Not Available In Your Region",
  },
  [GeoRestrictedPage.ALL_BONUSES]: {
    text: `Due to regulations in your country, we are unable to display bonus offers. If you believe this is wrong, contact us or consider using a VPN.`,
    title: "Sorry, Bonuses Are Not Available In Your Region",
  },
};
