export function fixedRound(value: number): number {
	const length = `${ value }`.length - 3;

	if (value > 0 && length > 0) {
		const base = Math.pow(10, length);
		const valueRounded = Math.floor(value / base);
		return valueRounded * base;
	}

	return value;
}
