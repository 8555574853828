import React from 'react';
import styled, { css, } from 'styled-components';
import {
	IStyles,
} from './$styles';

export interface ISpace {
	isBlock?: boolean | string;
	width?: number | string | null;
	height?: number | string | null;
}

/**
 * Create abstarct separator
 * 
 * @param isBlock boolean | string
 * @param width number | string | null
 * @param height number | string | null
 * @returns React.ReactElement
 * 
 */
export const Space: React.FC<IStyles & ISpace> = ({
	isBlock = false,
	width = 0,
	height = 0,
	...props
}): React.ReactElement => {
	const boxProps = { isBlock, width, height, ...props, };
	return (<Box { ...boxProps } />);
};

/**
 * Create inline separator
 * 
 * @param isBlock boolean | string
 * @param width number | string | null
 * @param height number | string | null
 * @returns React.ReactElement
 * 
 */
export const Offset: React.FC<IStyles & ISpace> = ({
	width = 8,
	height = 1,
	...props
}): React.ReactElement => {
	const spaceProps = { width, height, ...props, };
	return (<Space { ...spaceProps } />);
};

/**
 * Create block separator
 * 
 * @param isBlock boolean | string
 * @param width number | string | null
 * @param height number | string | null
 * @returns React.ReactElement
 * 
 */
export const Ident: React.FC<IStyles & ISpace> = ({
	isBlock = true,
	width = null,
	...props
}): React.ReactElement => {
	const spaceProps = { isBlock, width, ...props, };
	return (<Space { ...spaceProps } />);
};


const Box = styled.div<IStyles & ISpace>`
	box-sizing: border-box;
	${
		({ isBlock: _ }) => 
			typeof _ === 'string' ? css`display: ${ _ };`
			: _ ? css`display: block;`
			: css`display: inline-block;`
	}
	${
		({ width: _ }) =>
			typeof _ === 'string' ? css`width: ${ _ };`
			: typeof _ === 'number' ? css`width: ${ _ }px;`
			: null
	}
	${
		({ height: _ }) =>
			typeof _ === 'string' ? css`height: ${ _ };`
			: typeof _ === 'number' ? css`height: ${ _ }px;`
			: null
	}
	${ ({ styles }) => styles }	
`;
