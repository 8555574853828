import CustomNotificationCard from "components/admin/notifications/CustomNotificationCard";
import GenericNotificationCard from "components/admin/notifications/NotificationCard";
import StreamLiveNotificationCard from "components/admin/notifications/StreamLiveNotificationCard";
import { useGetActiveNotificationList } from "hooks/notification/useGetActiveNotificationList";
import React, { useMemo } from "react";
import styled from "styled-components";
import { NotificationType } from "types/notification";

const News = () => {
  const { data } = useGetActiveNotificationList();

  const Cards = useMemo(() => {
    const cards: React.ReactElement[] = [];
    {
      data.map((activeNotification) => {
        switch (activeNotification.type) {
          case NotificationType.StreamLive:
            cards.push(
              <StreamLiveNotificationCard {...activeNotification} key={activeNotification.id} />
            );
            break;
          case NotificationType.Custom:
            cards.push(
              <CustomNotificationCard {...activeNotification} key={activeNotification.id} />
            );
            break;
          default:
            cards.push(
              <GenericNotificationCard {...activeNotification} key={activeNotification.id} />
            );
        }
      });
    }
    return cards;
  }, [data]);

  return (
    <NewsContainer>
      <NewsHeader>News</NewsHeader>
      <NewsContentContainer>{Cards}</NewsContentContainer>
    </NewsContainer>
  );
};

const NewsHeader = styled.div`
  display: flex;
  height: 48px;
  padding: 16px;
  color: var(--clouds-100);
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
  background-color: var(--global-bg-dark-2);
`;
const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const NewsContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  height: calc(100% - 48px);
  overflow-y: auto;
  overflow-x: hidden;
  align-items: center;
`;

export default News;
