import React from "react";
import { css } from "styled-components";
import { Button, ButtonBlue } from "components";
import { Flex } from "components/common";
import { IAppContext, withApp } from "contexts";
import Image from "next/image";
import { SubHeader } from "../ItemsTable/ItemsTable";

const DiscordLayout: React.FC<IAppContext> = ({ onDiscordRedirect }): React.ReactElement => {
  return (
    <Flex column>
      <SubHeader style={{ padding: "3px 0px", marginBottom: 15, fontSize: 14 }}>
        Please connect your discord to be eligible for buying this item
      </SubHeader>
      <Flex style={{ marginTop: 30 }} width="100%" justify="center" align="center">
        <Button
          styles={css`
            border: 1px solid #3b8be9;
            background: #101622;
            height: 50px;
            padding: 10px 22px;
          `}
          height={50}
          isDark
          onClick={onDiscordRedirect}
        >
          <ButtonBlue isDark>
            <Image src="/images/icons/discord-purple.png" height={15} width={19} alt="image" />
            Connect
          </ButtonBlue>
        </Button>
      </Flex>
    </Flex>
  );
};

export default withApp(DiscordLayout);
