import React, { useCallback, useEffect, useMemo, useState } from "react";
import NavLink from "./NavLink";
import { DropNav, MobileHeaderBlock, Nav } from "../../styles";
import { ITopMenuItem, menuItems } from "../../dto";
import { filterNavItems } from "../../utils/filterNavItems";
import { useAppContext } from "contexts";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";
import SwitchButtonsSquared from "components/wrewards-ui-kit/Button/SwitchButtonsSquare";
import { ISwitchButton } from "types/webkit/switch-buttons";
import useAdminItems from "hooks/admin/useAdminItems";
import { useMediaQuery } from "react-responsive";

const Navigation = () => {
  const { profile, isAdmin, isFeatureEnabled, featureFlags } = useAppContext();
  const { adminItems } = useAdminItems();
  const [items, setItems] = React.useState<ITopMenuItem[]>([]);
  const [itemKeys, setItemKeys] = React.useState<string[]>([]);
  const [isAdminPanelActive, setIsAdminPanelActive] = useState<boolean>(false);
  const { showNavSidebar, setShowNavSidebar } = useShowPageLayoutContext();

  useEffect(() => {
    const newItems = filterNavItems(menuItems, isFeatureEnabled, profile, isAdmin);
    setItems(newItems);
  }, [profile, isFeatureEnabled, featureFlags, isAdmin]);

  const isDynamicSidebar = useMediaQuery({
    query: "(max-width: 1400px)",
  });

  const onNavItemClick = useCallback(() => {
    if (isDynamicSidebar) {
      setShowNavSidebar(false);
    }
  }, [isDynamicSidebar, setShowNavSidebar]);

  const itemsMenu = items.reduce<{
    interactive: ITopMenuItem[];
    nonInteractive: ITopMenuItem[];
  }>(
    (acc, item) => {
      item.isInteractive ? acc.interactive.push(item) : acc.nonInteractive.push(item);
      return acc;
    },
    { interactive: [], nonInteractive: [] }
  );

  const switchButtons = useMemo<ISwitchButton[]>(() => {
    return [
      {
        disabled: false,
        onClick: () => {
          setIsAdminPanelActive(false);
        },
        isActive: !isAdminPanelActive,
        text: "User",
      },
      {
        disabled: false,
        onClick: () => {
          setIsAdminPanelActive(true);
        },
        isActive: isAdminPanelActive,
        text: "Admin",
      },
    ];
  }, [isAdminPanelActive]);

  return (
    <Nav show={showNavSidebar}>
      <MobileHeaderBlock>
        <DropNav>
          <>
            {isAdmin && <SwitchButtonsSquared buttons={switchButtons} />}
            {itemsMenu.interactive.map((item, index) => (
              <NavLink
                key={index}
                item={item}
                itemKeys={itemKeys}
                setItemKeys={setItemKeys}
                onNavItemClick={onNavItemClick}
              />
            ))}
          </>
        </DropNav>
      </MobileHeaderBlock>
      <DropNav>
        {isAdminPanelActive &&
          adminItems.map((item, index) => (
            <NavLink
              key={index}
              item={item}
              itemKeys={itemKeys}
              setItemKeys={setItemKeys}
              onNavItemClick={onNavItemClick}
            />
          ))}
        {!isAdminPanelActive &&
          itemsMenu.nonInteractive.map((item, index) => (
            <NavLink
              key={index}
              item={item}
              itemKeys={itemKeys}
              setItemKeys={setItemKeys}
              onNavItemClick={onNavItemClick}
            />
          ))}
      </DropNav>
    </Nav>
  );
};

export default Navigation;
