import Link from "next/link";
import styled from "styled-components";

export const DesktopView = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
  width: 100%;
`;

export const MobileView = styled.div`
  display: none;
  align-items: center;
  gap: 8px;
  @media (max-width: 1480px) {
    display: flex;
    flex-direction: column;
  }
`;

export const CoinFlex = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const Tile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e9f4ff06;
  gap: 8px;
  border-radius: 6px;
  padding: 12px;
  width: 100%;
  margin-bottom: 8px;
`;

export const Icon = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: rgba(41, 48, 73, 0.5);
  margin-left: auto;

  svg {
    transform: rotate(270deg);
    height: 16px;
    width: 16px;
  }
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #e9f4ff;
`;

export const ProfileDisplayName = styled(Text)`
  max-width: 80px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block !important;
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 500;
  height: 48px;
  max-height: 48px;

  @media (max-width: 690px) {
    font-size: 14px;
    max-width: 60px;
  }
`;

export const CalendarLink = styled(Link)<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 500;
  color: #f2c94c;
  height: 48px;
  max-height: 48px;
  padding: 8px 12px 8px 16px;
  gap: 12px;
  flex-shrink: 0;
  background: rgba(200, 215, 245, 0.03);
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: rgba(0, 178, 255, 0.05);
  }

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const MobileCoins = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`;

export const MenuListStyled = styled.div`
  position: relative;
  border: 1px solid #e9f4ff0d;
  border-radius: 8px;
  font-family: var(--font-family-golos);
  background-color: #181e30;
  margin-top: 16px;
  user-select: none;
  .title {
    font-size: 14px;
    padding: 16px;
    border-bottom: 1px solid #e9f4ff0d;
    color: #abbadb;
  }
  .content {
    min-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
  }

  &::before {
    content: url("/images/icons/popup-poligon.svg");
    width: 14px;
    height: 14px;
    position: absolute;
    top: -14px;
    left: calc(50% - 7px);
  }
`;

export const ExchangeBtn = styled.button`
  font-family: var(--font-family-golos), sans-serif;
  margin-top: 8px;
  width: 100%;
  padding: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #e9f4ffbf;
  background: #e9f4ff1a;
  border: 1px solid #e9f4ff1a;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const MenuBtn = styled.button<{ isActive?: boolean }>`
  border: none;
  border-radius: 6px;
  min-width: max-content;
  background: #e9f4ff06;
  display: flex;
  align-items: center;
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 500;
  color: #e9f4ffbf;
  transition: all 0.3s ease;

  svg {
    transition: 0.3s transform ease-in-out;
  }

  &[aria-expanded="true"] .icon svg {
    transform: rotate(180deg);
  }

  & > span {
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 6px;
    padding: 10px;
    pointer-events: initial;
  }

  div {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
  }

  .icon {
    width: 26px;
    height: 26px;
    background: #e9f4ff0d;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }

  ${({ isActive }) =>
    isActive &&
    `
      background: rgba(0, 178, 255, 0.05);
      color: var(--pure-sky);

      div {
        color: var(--pure-sky);
      }
  `}
`;

export const MenuAbsolute = styled.div`
  position: absolute;
  left: 50%;
  top: 14px;
  transform: translateX(-50%);
  `

export const BalanceBtn = styled(MenuBtn)`

  background: #181e30;
  height: 48px;
  padding: 0;
  overflow: hidden;
  color: rgba(200, 215, 245, 0.5);
  text-align: center;
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  background: #101520;

  & span {
    gap: 12px;
    padding: 0 0 0 12px;
    height: 100%;

    @media (max-width: 1024px) {
      padding: 0;
    }
  }

  div {
    gap: 8px;
  }

  .icon {
    background-color: transparent;
    width: 16px;
  }

  @media (max-width: 1024px) {
    font-size: 12px;
  }

  ${({ isActive }) =>
    isActive &&
    `
      background: rgba(0, 178, 255, 0.05);
      color: var(--pure-sky);

      div {
        color: var(--pure-sky);
      }
  `}
`;

export const ProfileBtn = styled(MenuBtn)`
  height: 48px;
  max-height: 48px;

  @media (max-width: 500px) {
    display: none;
  }

  @media (max-width: 690px) {
    font-size: 12px;
    max-width: 80px;
  }
`;

export const BalanceTile = styled.div`
  font-family: var(--font-family-golos);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  background: #141722;
  gap: 8px;
  border-radius: 6px;

  & > div {
    display: flex;
    flex-direction: column;
    gap: 2px;
    color: #fff;

    small {
      color: #e9f4ff80;
      font-size: 12px;
    }
  }
`;

export const ProfileButton = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
  cursor: pointer;
  transition: opacity 0.3s ease;

  svg {
    height: 16px;
    width: 16px;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px;
  transition: background 0.3s ease;
  &:hover {
    background: #e9f4ff0d;
  }
`;

export const ProfileListStyled = styled(MenuListStyled)`
  padding: 4px;
`;

export const ProfileListItem = styled.div`
  display: flex;
  padding: 15px 24px;
  align-items: center;
  gap: 16px;
  align-self: stretch;

  color: var(--clouds-100);
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(185, 187, 222, 0.03);
  }
`;

export const BalanceBlock = styled.div`
  background-color: #0b101b;
  padding: 0 12px;
  color: var(--daylight-color);
  height: 48px;
  max-height: 48px;
  .inPlay {
    height: initial;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 3px;
    color: #5EBD3D;
    text-transform: none;
    border-radius: 4px;
    font-size: 14px;
    background-color: rgba(200, 215, 245,0.1);
  }
  @media (max-width: 767px) {
    background-color: rgba(200, 215, 245, 0.03);
  }
`;


export const ProfileMenuHeader = styled.div`
  width: 100%;
  color: var(--daylight-color);
  font-size: 16px;
`;

export const Title = styled.div`
  margin-bottom: 24px;
`;
