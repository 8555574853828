import { HouseGameType } from "helpers/constants";
import { Item, Root } from "./styles";
import { Dispatch, SetStateAction } from "react";

export type MyBetsTabNavProps = {
  betsType?: HouseGameType;
  setBetsType: Dispatch<SetStateAction<HouseGameType | undefined>>;
};

const MyBetsTabNav: React.FC<MyBetsTabNavProps> = ({ betsType, setBetsType }) => {
  const soonGame = [HouseGameType.BLACKJACK];
  const hideGame = [HouseGameType.ROULETTE];
  return (
    <Root>
      <Item key="all" active={betsType === undefined} onClick={() => setBetsType(undefined)}>
        all
      </Item>
      {Object.entries(HouseGameType)
        .filter(([_, value]) => !soonGame.includes(value) && !hideGame.includes(value))
        .map(([key, value]) => (
          <Item key={key} active={betsType === value} onClick={() => setBetsType(value)}>
            {value}
          </Item>
        ))}
    </Root>
  );
};

export default MyBetsTabNav;
