import styled, { css, } from 'styled-components';
import { TheIcon } from 'components';
import { ITheCheckbox } from 'interfaces';

const cssViolet_checked = css`
	background-color: #683DC4;
`;

const cssViolet = css`
	background-color: rgba(199, 226, 251, 0.95);
`;

const cssDarkBlue_checked = css`
	background-color: #D2EAFF;
	box-shadow: 0px 0px 24px rgba(40, 113, 255, 0.5), 0px 0px 6px rgba(58, 113, 254, 0.46), 0px 2px 4px #0B0E23;
`;

const cssDarkBlue = css`
	background-color: #0B101B;
`;

const cssDark_checked = css`
	background-color: #D2EAFF;
	box-shadow: 0px 0px 24px rgba(40, 113, 255, 0.5), 0px 0px 6px rgba(58, 113, 254, 0.46), 0px 2px 4px #0B0E23;
`;

const cssDark = css`
	background-color: #182A4B;
`;

export const Checkbox = styled.div<ITheCheckbox>`
	flex-grow: 0;
	flex-shrink: 0;
	box-sizing: border-box;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	${
		({ size, }) => typeof size === 'number'
			? css`
				width: ${ size }px;
				height: ${ size }px;
			`
			: null
	}
	overflow: hidden;

	//
	background-color: transparent;
	box-shadow: 0px 0px 24px transparent, 0px 0px 6px transparent, 0px 2px 4px transparent;
	border-radius: 4px;
	cursor: pointer;

	${
		({ checked, preset, }) =>
				preset === 'violet' && checked ? cssViolet_checked
			: preset === 'violet' && !checked ? cssViolet
			: preset === 'dark-blue' && checked ? cssDarkBlue_checked
			: preset === 'dark-blue' && !checked ? cssDarkBlue
			: preset === 'dark' && checked ? cssDark_checked
			: preset === 'dark' && !checked ? cssDark
			: checked ? cssDarkBlue_checked
			: !checked ? cssDarkBlue
			: null
	}

	${ ({ styles, }) => styles }
`;

const Input = styled.input`
  display: none;
`;

export function TheCheckbox(
	{
		checked,
		name,
		value,
		preset,
		size = 16,
		onChange,
		...props
	}: ITheCheckbox,
): React.ReactElement {
	const inputProps = {
		...(typeof checked === 'boolean') && { checked },
		...(typeof name === 'string') && { name },
		...['number', 'string'].includes(typeof value) && { value },
		...(typeof onChange === 'function') && { onChange },
	};
	const checkboxProps = {
		...(typeof checked === 'boolean') && { checked },
		...(typeof preset === 'string') && { preset },
		...(typeof size === 'number') && { size },
		...props
	};
	const iconViolet = (<TheIcon icon="checkbox:checked:light" size={ 12 } />);
	const iconDarkBlue = (<TheIcon icon="checkbox:checked:dark" size={ 12 } />);

	return (
		<>
			<Input type="checkbox" { ...inputProps } />
			<Checkbox { ...checkboxProps }>
				{
					preset === 'violet' && checked ? iconViolet
					: preset === 'violet' && !checked ? null
					: preset === 'dark-blue' && checked ? iconDarkBlue
					: preset === 'dark-blue' && !checked ? null
					: checked ? iconDarkBlue
					: null
				}
			</Checkbox>
		</>
	);
}
