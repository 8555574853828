import React, { FC, memo } from "react";
import Navigation from "./HeaderComponent/components/Navigation";
import styled from "styled-components";

export interface NavSidebarProps {
  show: boolean;
}

const NavSidebar: FC<NavSidebarProps> = ({ show }) => {
  return <Sidebar show={show}></Sidebar>;
};

const Sidebar = styled.aside<NavSidebarProps>`
  width: 100%;
  position: relative;
  width: var(--sidebar-width);
  top: 0;
  left: 0;
  background-color: var(--global-bg-dark-1);
  z-index: 7;
  transition: transform 0.3s;
  top: calc(var(--header-height) + 1px);

  @media (max-width: 500px) {
    top: calc(var(--mobile-header-height) + 1px);
  }

  @media (max-width: 1400px) {
    height: 100%;
    top: calc(var(--header-height) + 1px);
    position: fixed;
    transform: ${({ show }) => (show ? "translateX(0)" : "translateX(-100%)")};
  }
`;

export default memo(NavSidebar);
