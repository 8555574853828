import React, { FC, ReactNode } from "react";
import styled from "styled-components";

type GridProps = {
  children: ReactNode;
  isAdminPage?: boolean;
};

const AdaptiveGrid = styled.div<{ isAdminPage?: boolean }>`
  display: grid;
  gap: 12px;
  width: 100%;

  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  display: flex;
`;

const RewardsGrid: FC<GridProps> = ({ children, isAdminPage }) => {
  return <AdaptiveGrid isAdminPage={isAdminPage}>{children}</AdaptiveGrid>;
};

export default RewardsGrid;
