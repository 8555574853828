import { SVGProps } from "react";

export type ContactProps = SVGProps<SVGSVGElement>;

const Contact: React.FC<ContactProps> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.48 2.81998L19.76 3.38998C19.9 3.66998 20.25 3.92998 20.56 3.98998L20.94 4.04998C22.08 4.23998 22.35 5.07998 21.53 5.90998L21.18 6.25998C20.95 6.49998 20.82 6.95998 20.89 7.27998L20.94 7.48998C21.25 8.86998 20.52 9.39998 19.32 8.67998L19.06 8.52998C18.75 8.34998 18.25 8.34998 17.94 8.52998L17.68 8.67998C16.47 9.40998 15.74 8.86998 16.06 7.48998L16.11 7.27998C16.18 6.95998 16.05 6.49998 15.82 6.25998L15.47 5.89998C14.65 5.06998 14.92 4.22998 16.06 4.03998L16.44 3.97998C16.74 3.92998 17.1 3.65998 17.24 3.37998L17.52 2.80998C18.06 1.72998 18.94 1.72998 19.48 2.81998Z"
        fill={props.fill ?? "#E9F4FF"}
      />
      <path
        d="M21.57 10.09C21.21 10.35 20.2 10.83 18.76 10.09C18.6 10.01 18.4 9.99998 18.24 10.09C17.67 10.38 17.15 10.5 16.74 10.5C16.1 10.5 15.65 10.25 15.43 10.09C15.06 9.81998 14.28 9.04998 14.55 7.41998C14.58 7.24998 14.53 7.07998 14.42 6.94998C13.73 6.15998 13.35 5.09998 13.62 4.26998C13.72 3.93998 13.51 3.49998 13.17 3.49998H7C4 3.49998 2 4.99998 2 8.49998V15.5C2 19 4 20.5 7 20.5H17C20 20.5 22 19 22 15.5V10.27C22 10.08 21.73 9.97998 21.57 10.09ZM14.34 12.09C13.68 12.62 12.84 12.88 12 12.88C11.16 12.88 10.31 12.62 9.66 12.09L6.53 9.58998C6.21 9.32998 6.16 8.84997 6.41 8.52997C6.67 8.20997 7.14 8.14997 7.46 8.40998L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91C13.72 10.65 14.19 10.7 14.45 11.03C14.72 11.35 14.67 11.83 14.34 12.09Z"
        fill={props.fill ?? "#E9F4FF"}
      />
    </svg>
  );
};

export default Contact;
