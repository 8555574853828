import styled from "styled-components";
import React from "react";
import { ILeaderboardEditProps, LeaderboardEditLayout, IStyles, Ident } from "components";

const LeaderboardManage: React.FC<ILeaderboardEditProps> = ({
  data, // Leaderboard
  refetchLeaderboard,
  onClose,
}): React.ReactElement => {
  return (
    <Box>
      <Ident height={16} />
      <BoxItem>
        <LeaderboardEditLayout
          refetchLeaderboard={refetchLeaderboard}
          data={data}
          onClose={onClose}
        />
      </BoxItem>
    </Box>
  );
};

export const LeaderboardManageLayout = LeaderboardManage;

const Box = styled.div<IStyles>`
  /* background-color: #0b0e16;
  background: radial-gradient(
      152.76% 130.7% at 50% 0%,
      rgba(101, 101, 101, 0.05) 0%,
      rgba(101, 101, 101, 0) 100%
    ),
    #101622; */
  max-width: 1282px;
  margin: 0 auto 0;

  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;

  ${({ styles }) => styles}
`;
