import React, { useCallback, useMemo } from "react";
import { ITheHeaderProfile } from "interfaces";
import Image from "next/image";
import { ThePointsBalance } from "../../../../../components/TheComponents/ThePointsBalance";
import {
  Tile,
  Text,
  MobileView,
  CoinFlex,
  MobileCoins,
  ProfileButton,
  ProfileListItem,
  ProfileMenuHeader,
  Icon,
  Title,
} from "../styles";
import ChakraModal from "components/TheComponents/Modal";
import ExchangeModal from "components/games/ExchangeBanner/ExchangeModal";
import { useProfileContext } from "components/public/ProfileLayout/ProfileContext";
import { useRouter } from "next/navigation";
import { MobileHeaderBlock } from "layout/HeaderComponent/styles";
import Dropdown from "../../icons/Dropdown";
import { useBalanceContext } from "contexts/Balance/BalanceProvider";
import { ProfileTabs, ProfileTabsListPublic } from "types/profile";
import { ProfileTabsIcons } from "types/profile/constants";

const MobileViewProfileMenu = ({
  profile,
  handleToggleMobileDropdown,
}: ITheHeaderProfile & {
  onOpenLogout: () => void;
  handleToggleMobileDropdown: () => void;
}): React.ReactElement => {
  const router = useRouter();
  const [isShowModal, setIsShowModal] = React.useState(false);
  const { changeActiveTab } = useProfileContext();
  const { gPointsBalance } = useBalanceContext();

  const handleMenuItemClick = useCallback(
    (item: ProfileTabs) => {
      router.push("/profile");
      changeActiveTab(item);
      handleToggleMobileDropdown();
    },
    [router, changeActiveTab, handleToggleMobileDropdown]
  );

  const handleProfileRediraction = useCallback(() => {
    router.push("/profile");
    handleToggleMobileDropdown();
  }, [router, handleToggleMobileDropdown]);

  const Navigation = useMemo(() => {
    return ProfileTabsListPublic.map((item, index) => (
      <ProfileListItem key={index} onClick={() => handleMenuItemClick(item)}>
        {ProfileTabsIcons[item]}
        {item}
      </ProfileListItem>
    ));
  }, [handleMenuItemClick]);

  return (
    <>
      <MobileView>
        <MobileHeaderBlock as={ProfileMenuHeader}>
          <Tile>
            <ProfileButton onClick={() => handleProfileRediraction()}>
              <Image
                alt="profile"
                src={profile?.profileImageUrl ?? ""}
                width={24}
                height={24}
                style={{
                  borderRadius: "50%",
                }}
              />
              <Text>{profile?.displayName}</Text>
              <Icon>
                <Dropdown fill="#757F97" />
              </Icon>
            </ProfileButton>
          </Tile>
          <MobileCoins>
            <Tile>
              <CoinFlex>
                <Image alt="w-coin" src="/images/coins/w-coin.svg" width={24} height={24} />
                <Text>
                  <ThePointsBalance value={profile?.pointsBalance} isFixed />
                </Text>
              </CoinFlex>
            </Tile>

            <Tile>
              <CoinFlex>
                <Image alt="g-coin" src="/images/coins/g-coin.svg" width={24} height={24} />
                <Text>
                  <ThePointsBalance value={gPointsBalance} />
                </Text>
              </CoinFlex>
            </Tile>
          </MobileCoins>
        </MobileHeaderBlock>
        {Navigation}
      </MobileView>
      <ChakraModal
        content={
          <ExchangeModal
            onClose={() => {
              setIsShowModal(false);
            }}
          />
        }
        isOpen={isShowModal}
        onClose={() => setIsShowModal(false)}
      />
    </>
  );
};

export default MobileViewProfileMenu;
