import * as luxon from "luxon";
import React from "react";
import styled, { css } from "styled-components";

import { IStyles, Ident, TheButton } from "components";
import { ILeaderboardWithWinnerDto } from "interfaces";
import { DEFAULT_AVATAR } from "helpers/constants";

export interface ILeaderboardTopWinnerCard {
  leaderboard?: ILeaderboardWithWinnerDto;
  doHref?: (leaderboard?: ILeaderboardWithWinnerDto) => string;
}

export const LeaderboardTopWinnerCard: React.FC<ILeaderboardTopWinnerCard> = ({
  leaderboard,
  doHref = (leaderboard?: ILeaderboardWithWinnerDto) => `/leaderboards/${leaderboard?.id ?? "xxx"}`,
}): React.ReactElement => {
  const avatar = leaderboard?.winner?.avatar || DEFAULT_AVATAR;
  const displayName = leaderboard?.winner?.displayName ?? "Xxxxxx";
  const value = leaderboard?.prize1;
  const endDate = leaderboard?.endDate
    ? luxon.DateTime.fromISO(leaderboard?.endDate).toFormat("dd-MM-yyyy")
    : "?";
  const href = doHref(leaderboard); // `/leaderboards/${ leaderboard?.id ?? 'xxx' }`;

  const avatarSize = 104;
  return (
    <Box>
      <Ident height={16} />
      <Text>TOP WINNER</Text>
      <Ident height={11} />
      <AvatarFrame avatarSize={avatarSize}>
        <Avatar avatarSize={avatarSize}>
          {avatar ? (
            <AvatarPicture
              src={avatar}
              alt="avatar"
              width={96} // 93.85
              height={96} // 93.85
            />
          ) : null}
        </Avatar>
      </AvatarFrame>
      <Ident height={8} />
      <Name>{displayName}</Name>
      <Ident height={24} />
      <Text>HIGHEST REWARD</Text>
      <Ident height={8} />
      <Value data-first>${value}</Value>
      <Ident height={24} />
      <Text>END DATE</Text>
      <Ident height={8} />
      <Value>{endDate}</Value>
      <Ident height={16} />
      <TheButton href={href} height={55} width={null} styles={cssView}>
        VIEW LEADERBOARD
      </TheButton>
    </Box>
  );
};

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  width: 315px;
  /* height: 454px; */
  background-color: #13171f;
  background-position: center;
  background-repeat: no-repeat;
  background-clip: border-box;
  background-image: radial-gradient(
      151.44% 129.57% at 50% 0%,
      rgba(247, 186, 29, 0.91) 0%,
      rgba(255, 230, 0, 0) 100%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 93.1%),
    url("/images/leaderboard/LeaderboardWinnerCard/Vector1.svg");
  background-size: cover, cover, cover;
  border-radius: 8px;
  text-align: center;
  ${({ styles }) => styles}
`;

const Text = styled.div<IStyles>`
  box-sizing: border-box;
  color: rgba(210, 234, 255, 0.75);
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  ${({ styles }) => styles}
`;

const Name = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: var(--font-family-golos);
  font-size: 32px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 38px;
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 0px 0px 6px rgba(58, 113, 254, 0.46), 0px 2px 4px #0b0e23;

  // truncate
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 20px;

  ${({ styles }) => styles};
`;

const Value = styled.div<IStyles>`
  color: #d2eaff;
  font-family: "Rubik";
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  &[data-first]:first-letter {
    color: #5ebd3d;
  }
  ${({ styles }) => styles}
`;

const AvatarFrame = styled.div<IStyles & { avatarSize: number }>`
  --size: ${({ avatarSize }) => avatarSize ?? 104}px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(219.74deg, #b48b23 -4.97%, #ffda7a 47.59%, #725a05 93.01%);
  width: calc(var(--size) + 5px * 2);
  height: calc(var(--size) + 5px * 2);
  margin: 0 auto;
  border-radius: calc((var(--size) + 5px * 2) / 2);
  ${({ styles }) => styles}
`;

const Avatar = styled.div<IStyles & { avatarSize: number }>`
  --size: ${({ avatarSize }) => avatarSize ?? 104}px;
  position: relevant;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  margin: 5px;
  overflow: hidden;
  background-color: white;
  border: 4px solid rgba(16, 17, 25, 1);
  border-radius: calc(var(--size) / 2);
  ${({ styles }) => styles}
`;

const AvatarPicture = styled.img<IStyles>``;

const cssView = css`
  background: rgba(11, 14, 22, 0.5);
  border-top: 1px solid rgba(210, 234, 255, 0.05);
  border-radius: 0;
  backdrop-filter: blur(37.5px);
  color: #d2eaff;
  font-family: var(--font-family-golos);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
`;
