import React from "react";
import styled  from "styled-components";
import Countdown, { zeroPad } from "react-countdown";
import ESVG from "/public/images/leaderboard/coundown-shape.svg";
import Fire from "/public/images/icons/fire2.svg";
import Finish from "/public/images/icons/finish.svg";
import { DateTime } from "luxon";

export interface ILeaderboardEndsIn {
  title?: string;
  value: string;
  start: string;
  variant?: "v1" | "v2";
}


export const LeaderboardEndsIn: React.FC<ILeaderboardEndsIn> = ({
  title = "Leaderboard ends in",
  value,
  start,
  variant = "v1",
}): React.ReactElement => {
  const givenDate = value?.toString();
  const startDate = start?.toString();
  let i1 = DateTime.fromISO(startDate);
  let i2 = DateTime.fromISO(value);
  const full = i2.diff(i1).toObject(); //=> { milliseconds: 43807500000 }

  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    ...rest
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    total: number;
  }) => {
    // @ts-ignore
    const persentage = (rest.total / full?.milliseconds) * 100 ?? 10;

    return (
      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 32 }}>
        <div style={{ maxWidth: "600px", width: "100%" }}>
          <Progress>
            <ProgressLine style={{ width: `${100 - Number(persentage.toFixed(0))}%` }}>
              <ProgressLineEnd>
                <Fire />
              </ProgressLineEnd>
            </ProgressLine>
            <ProgressEnd>
              <Finish />
            </ProgressEnd>
          </Progress>
        </div>
        <EndsItems style={{ minWidth: 280, position: "relative" }}>
          <div style={{ position: "absolute", inset: "0px", zIndex: 0 }}>
            <ESVG style={{ objectFit: "contain", width: "100%", height: "100%" }} />
          </div>
          {[days, hours, minutes, seconds].map((el, index) => (
            <EndsItem key={index} style={{ whiteSpace: "nowrap", position: "relative" }}>
              <div data-value>{el === days ? el : zeroPad(el)}</div>
              <div>{Object.keys({ days, hours, minutes, seconds })[index][0]}</div>
            </EndsItem>
          ))}
        </EndsItems>
      </div>
    );
  };
  const renderer2 = ({
    days,
    hours,
    minutes,
    seconds,
    ...rest
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    total: number;
  }) => {
    return (
      <EndsItems>
        {[days, hours, minutes, seconds].map((el, index) => (
          <EndsItem key={index} variant="v2">
            <div data-value>{el === days ? el : zeroPad(el)}</div>
            <div data-info>{Object.keys({ days, hours, minutes, seconds })[index]}</div>
          </EndsItem>
        ))}
      </EndsItems>
    );
  };

  const displayed = variant === "v1" ? renderer : renderer2;
  return (
    <Box>
      <Divider className="divider">
        <Title><Finish/> {title}</Title>
      </Divider>
      <BoxItem>
        <Countdown date={givenDate} renderer={displayed} />
      </BoxItem>
    </Box>
  );
};

const Divider = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1em -1em;
  &:before,
  &:after {
    content: "";
    flex: 1;
    height: 1px;
    margin: 0 1em;
    background: var(--main-bright-5, rgba(235, 236, 243, 0.05));
  }
`;

const ProgressLine = styled.div`
  box-sizing: border-box;
  display: flex;
  /* border: 2px solid var(--Dark-Sweet-Night, #1b2035); */
  /* background: var(--Dark-Midnight, #141722); */
  /* border: 1px solid var(--Dark-Sweet-Night, #1b2035); */
  background: linear-gradient(90deg, #ff782f 9.02%, #f32257 91.11%);
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 10px;
  /* &:before {
    overflow: hidden;
    content: "";
    position: absolute;
    inset: 0;
    border-radius: inherit;

  } */
`;
const ProgressEnd = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid var(--Dark-Sweet-Night, #1b2035);
  padding: 6px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 40px;
  height: 40px;
  transform: translate(5px, -50%);
  border-radius: 40px;
  background: var(--Dark-Midnight, #141722);
  box-shadow: 0px 0px 0px 2px #10131a inset;

  svg {
    animation: tilt-shaking 0.5s infinite ease-in-out;
  }
`;
const ProgressLineEnd = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* border: 1px solid var(--Dark-Sweet-Night, #1b2035); */
  position: relative;
  top: -2px;
  left: calc(100% - 10px);
  width: 40px;
  height: 40px;
  filter: drop-shadow(0px 0px 10px #791e0e);
  animation: pulse 1s infinite;
`;

const Progress = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  border: 2px solid var(--Dark-Sweet-Night, #1b2035);
  display: flex;
  position: relative;
  margin: 24px 0px 24px 0;
  height: 10px;
  border-radius: 10px;
  background: var(--Dark-Midnight, #141722);
`;

const Title = styled.p`
  color: var(--daylight-daylight-main, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const Box = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;
  width: 100%;
  max-width: 1008px;
  margin: 0 auto;
`;

const BoxItem = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const EndsItems = styled.div`
  box-sizing: border-box;
  position: relative;
  display: inline-flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  gap: 2px;
`;

const EndsItem = styled.div<{ variant?: string }>`
  --size: 100px;
  --gap: 16px;
  box-sizing: border-box;
  display: inline-flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: var(--gap);
  width: var(--size);
  padding: 16px;
  text-transform: uppercase;

  background: ${({ variant }) => (variant === "v2" ? "#141722" : "transparent")};
  border-radius: 8px;

  &:first-child {
    border-radius: 16px 0 0 16px;
  }

  &:last-child {
    border-radius: 0 16px 16px 0;
  }

  text-align: center;

  & [data-value] {
    color: var(--daylight-daylight-main, #d5e0f5);
    font-family: var(--font-family-exo);
    font-size: 24px;
    font-style: italic;
    font-weight: 800;
    line-height: normal;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  }

  &:last-child [data-value] {
    color: var(--clouds-25, rgba(171, 186, 219, 1));
    animation: pulse1 1s infinite;
  }

  & > div:last-child {
    color: var(--daylight-daylight-half, rgba(200, 215, 245, 0.5));
    text-align: center;

    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
    font-family: var(--font-family-golos);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
  }
  @media (max-width: 600px) {
    --size: 80px;
  }
`;
