import { SVGProps } from "react";

export type SlotsReviewProps = SVGProps<SVGSVGElement>;

const SlotsReview: React.FC<SlotsReviewProps> = (props) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 6L13.434 8.77725L16.5 9.22275L14.25 11.25L15 14.25L12 12.5625L9 14.25L9.75 11.25L7.5 9.22275L10.65 8.77725L12 6Z"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      />
      <path
        d="M13.302 22.5L12 21.75L15 16.5H19.5C19.6971 16.5003 19.8923 16.4617 20.0744 16.3864C20.2565 16.3111 20.422 16.2007 20.5613 16.0613C20.7007 15.922 20.8111 15.7565 20.8864 15.5744C20.9617 15.3923 21.0003 15.1971 21 15V6C21.0003 5.80293 20.9617 5.60775 20.8864 5.42562C20.8111 5.2435 20.7007 5.07803 20.5613 4.93868C20.422 4.79933 20.2565 4.68885 20.0744 4.61358C19.8923 4.5383 19.6971 4.4997 19.5 4.5H4.5C4.30293 4.4997 4.10775 4.5383 3.92562 4.61358C3.7435 4.68885 3.57803 4.79933 3.43868 4.93868C3.29933 5.07803 3.18885 5.2435 3.11358 5.42562C3.0383 5.60775 2.9997 5.80293 3 6V15C2.9997 15.1971 3.0383 15.3923 3.11358 15.5744C3.18885 15.7565 3.29933 15.922 3.43868 16.0613C3.57803 16.2007 3.7435 16.3111 3.92562 16.3864C4.10775 16.4617 4.30293 16.5003 4.5 16.5H11.25V18H4.5C3.70435 18 2.94129 17.6839 2.37868 17.1213C1.81607 16.5587 1.5 15.7956 1.5 15V6C1.5 5.20435 1.81607 4.44129 2.37868 3.87868C2.94129 3.31607 3.70435 3 4.5 3H19.5C20.2956 3 21.0587 3.31607 21.6213 3.87868C22.1839 4.44129 22.5 5.20435 22.5 6V15C22.5 15.7956 22.1839 16.5587 21.6213 17.1213C21.0587 17.6839 20.2956 18 19.5 18H15.8738L13.302 22.5Z"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      />
    </svg>
  );
};

export default SlotsReview;
