import { SVGProps } from "react";

export type ChatIconProps = SVGProps<SVGSVGElement>;

const ChatIcon: React.FC<ChatIconProps> = (props) => {
    return (
        <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="chat-icon">
                <path id="comm-16_chat" d="M12.5007 2C6.70614 2 2.00079 6.70548 2.00079 12.5007C2.00429 14.3552 2.50818 16.1701 3.4418 17.7698L2.01856 22.0396C1.92734 22.3132 2.18789 22.5737 2.46152 22.4825L6.54529 21.1215C8.29562 22.3332 10.3678 22.9968 12.5001 23C18.295 22.9999 23 18.2948 23 12.5C23 6.70506 18.2953 2.00013 12.5007 2ZM8.99187 11.45C9.5676 11.45 10.0419 11.9243 10.0419 12.5C10.0419 13.0758 9.56759 13.55 8.99187 13.55C8.41614 13.55 7.94256 13.0758 7.94255 12.5C7.94255 11.9243 8.41613 11.45 8.99187 11.45ZM12.5007 11.45C13.0765 11.45 13.5507 11.9243 13.5507 12.5C13.5507 13.0758 13.0765 13.55 12.5007 13.55C11.925 13.55 11.4507 13.0758 11.4507 12.5C11.4507 11.9243 11.925 11.45 12.5007 11.45ZM15.9918 11.45C16.5676 11.45 17.0418 11.9243 17.0418 12.5C17.0418 13.0758 16.5676 13.55 15.9918 13.55C15.4161 13.55 14.9418 13.0758 14.9418 12.5C14.9418 11.9243 15.4161 11.45 15.9918 11.45Z" fill="#ABBADB" />
            </g>
        </svg>
    );
};

export default ChatIcon;
