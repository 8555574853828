import { Avatar } from "@chakra-ui/react";
import useEmblaCarousel from "embla-carousel-react";
import { DEFAULT_AVATAR } from "helpers/constants";
import { truncateText } from "helpers/truncateText";
import { memo, PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { formatNumber } from "./utils/formatNumbers";
import Angle from "/public/images/icons/angle.svg";
import Replay from "/public/images/icons/rtp.svg";
import { Flex } from "components/common";
import { hiddenText } from "helpers/hiddenText";

const generateRandomNickname = (): string => {
  const adjectives = [
    "Swift",
    "Mighty",
    "Brave",
    "Fierce",
    "Clever",
    "Sneaky",
    "Lucky",
    "Bold",
    "Silent",
    "Quick",
    "Loyal",
    "Witty",
    "Noble",
    "Shadowy",
    "Blazing",
  ];

  const animals = [
    "Tiger",
    "Wolf",
    "Hawk",
    "Eagle",
    "Fox",
    "Bear",
    "Dragon",
    "Shark",
    "Lion",
    "Panther",
    "Raven",
    "Serpent",
    "Phoenix",
    "Falcon",
    "Cobra",
  ];

  const numbers = Math.floor(Math.random() * 10000); // 0-9999 for uniqueness
  const adjective = adjectives[Math.floor(Math.random() * adjectives.length)];
  const animal = animals[Math.floor(Math.random() * animals.length)];

  return `${adjective}${animal}${numbers}`;
};

const shuffleArray = (array: any[]) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const RaffleLeaderboardCarousel: React.FC<PropsWithChildren & { winners: any[] }> = ({
  winners,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true, skipSnaps: true, duration: 110 });
  const rafId = useRef<NodeJS.Timeout | null>(null);
  const currentIndexRef = useRef(0);
  const [finished, setFinished] = useState(true);
  const [scrolledIndexes, setScrolledIndexes] = useState<number[]>([]);
  const [showFiltered, setShowFiltered] = useState(false);

  const seedUsers = useMemo(
    () =>
      Array.from({ length: 40 }, (_, i) => ({
        refereeId: `seed-${Date.now()}-${i}`,
        displayName: hiddenText(generateRandomNickname()),
        amount: undefined,
        prize: null,
      })),
    []
  );

  const shuffledArray = useMemo(() => {
    const combined = [
      ...winners.map((w) => ({
        ...w,
        refereeId: w?.winner?.id,
      })),
      ...seedUsers.map((s, idx) => ({ ...s, refereeId: `seed-${idx}` })),
    ];
    return shuffleArray(combined);
  }, [winners, seedUsers]);

  const realWinnerIndexes = useMemo(() => {
    return winners.map((winner) =>
      shuffledArray.findIndex((item) => item.winner?.id === winner?.winner?.id)
    );
  }, [winners, shuffledArray]);

  const filteredArray = useMemo(() => {
    return shuffledArray.filter((item) => item.winner);
  }, [shuffledArray]);

  const startAutoScroll = useCallback(() => {
    const scrollInterval = 6000;

    const scroll = () => {
      if (emblaApi && realWinnerIndexes.length > 0) {
        const index = currentIndexRef.current;
        
        if (index < realWinnerIndexes.length) {
          const targetIndex = realWinnerIndexes[index];
          //   console.log("Scrolling to:", targetIndex);
          emblaApi.scrollTo(targetIndex);
          setScrolledIndexes((prev) => [...new Set([...prev, targetIndex])]);
          currentIndexRef.current += 1;
        } else {
          //   console.log("Finished scrolling!");
          setFinished(true);
          stopAutoScroll();
          return;
        }

        rafId.current = setTimeout(scroll, scrollInterval);
      }
    };

    scroll();

    return () => {
      if (rafId.current) {
        clearTimeout(rafId.current);
        rafId.current = null;
      }
    };
  }, [emblaApi, realWinnerIndexes]);

  const stopAutoScroll = useCallback(() => {
    if (rafId.current) {
      clearTimeout(rafId.current);
      rafId.current = null;
    }
  }, []);

  const handleReplay = () => {
    setShowFiltered(false);
    setScrolledIndexes([]);
    setFinished(false);
    currentIndexRef.current = 0;

    if (emblaApi) {
      emblaApi.scrollTo(0);
      startAutoScroll();
    }
  };

  useEffect(() => {
    if (emblaApi && realWinnerIndexes.length > 0) {
      currentIndexRef.current = 0;
      setFinished(false);
      const stopAutoScrollHandler = startAutoScroll();
      return () => stopAutoScrollHandler();
    }
  }, [emblaApi, realWinnerIndexes, startAutoScroll]);

  return (
    <Flex gap={16} column align="center">
      {finished ? (
        <Flex align="center" justify="center">
          <ReplayButton onClick={handleReplay}>
            <span className="icon-wrapper">
              <Replay />
            </span>
            Replay Raffle
          </ReplayButton>
        </Flex>
      ) : (
        <RootCar>
          <TopCenter>
            <Angle />
          </TopCenter>
          <div className="viewport" ref={emblaRef}>
            <div className="container">
              {(showFiltered ? filteredArray : shuffledArray).map((w, index) => (
                <RaffleLeaderboardRandomWinnerCard
                  key={`${w.refereeId}-${index}`}
                  prize={w.amount}
                  prizeNumber={w.prizeNumber}
                  displayName={w?.winner?.displayName ?? w.displayName}
                  isScrolled={showFiltered || scrolledIndexes.includes(index)}
                  avatar={w?.winner?.avatar ?? DEFAULT_AVATAR}
                />
              ))}
            </div>
          </div>
        </RootCar>
      )}
    </Flex>
  );
};

const TopCenter = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const RootCar = styled.div`
  /* overflow: hidden; */
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  position: relative;
  /* padding: 24px; */

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 8px;
    z-index: 0;
    inset: -1px;

    background: linear-gradient(180deg, #b48b23 0%, #ffd97a 24%, rgba(0, 0, 0, 0) 100%),
      linear-gradient(180deg, #181e30 0%, rgba(0, 0, 0, 0) 100%);
  }
  .viewport {
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    padding: 24px 20px;
    box-shadow: 0px 0px 0px 3px #11161e inset;
    background-color: #181e30;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: 8px;
      z-index: 1;
      background: linear-gradient(
        90deg,
        #181e30 0%,
        rgba(0, 0, 0, 0) 30%,
        rgba(0, 0, 0, 0) 70%,
        #181e30 100%
      );
    }
  }

  .container {
    display: flex;
    gap: 16px;
  }
`;

export const RaffleLeaderboardRandomWinnerCard: React.FC<any> = ({
  prizeNumber,
  displayName,
  prize,
  avatar,
  isScrolled,
}) => {
  return (
    <Card number={prizeNumber}>
      <CardContent>
        <UserAvatarWrapper>
          <Avatar size="xl" src={avatar ?? DEFAULT_AVATAR} />
        </UserAvatarWrapper>
        <DisplayName>{truncateText(displayName || "UnknownPlayer", 10)}</DisplayName>
        <Prize style={{ opacity: isScrolled ? 1 : 0 }}>
          {prize ? <b data-content={`$${formatNumber(prize)}`}>${formatNumber(prize)}</b> : null}
        </Prize>
      </CardContent>
      <GradientBorder />
    </Card>
  );
};

const DisplayName = styled.span`
  color: var(--daylight-daylight-main, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  text-align: left;
  position: relative;
  filter: drop-shadow(0px 0px 6px #000);
`;

const UserAvatarWrapper = styled.div`
  border: 2px solid #10131a;
  border-radius: 50%;
`;

const Card = styled.div<{ number: number }>`
  position: relative;
  height: 225px;
  /* margin: 4px; */
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  min-width: 200px;
`;

const GradientBorder = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  /* padding: 1px; */
  z-index: 0;
  border-radius: inherit;

  &::before {
    content: "";
    display: block;
    position: absolute;
    border-radius: 8px;
    z-index: 0;
    inset: -1px;

    background: linear-gradient(180deg, #b48b23 0%, #ffd97a 24%, rgba(0, 0, 0, 0) 100%),
      linear-gradient(180deg, #181e30 0%, rgba(0, 0, 0, 0) 100%);
  }
`;
const CardContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 6px;
  z-index: 10;
  border-radius: inherit;
  box-shadow: 0px 0px 0px 3px #11161e inset;
  background-color: #181e30;
  overflow: hidden;
  &::before {
    border-radius: inherit;
    content: "";
    position: absolute;
    inset: 0;
    background: linear-gradient(
      105deg,
      rgba(208, 219, 255, 0) -24.09%,
      rgba(209, 219, 255, 0.02) 47.65%,
      rgba(209, 219, 255, 0.09) 65.58%,
      rgba(215, 224, 255, 0.02) 83.52%,
      rgba(222, 231, 255, 0) 155.26%
    );
    transform: rotate(45deg) scale(1.5);
    /* background-position-x: left; */
    /* animation: glassSpark 6s ease-in-out infinite; */
  }

  @keyframes glassSpark {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(45deg) scale(1.5);
      opacity: 0;
    }
    100% {
      transform: rotate(0deg) scale(1);
    }
  }
`;

const Prize = styled.div`
  color: var(--daylight-color, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 0;
  transition: opacity 0.3s 5.2s;

  & b {
    position: relative;
    background: linear-gradient(
      130deg,
      #ffce64 27.05%,
      #ffd48e 43.67%,
      #fff 57.81%,
      #ffd48e 73.37%,
      #ffce64 97.77%
    );

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  & b::before {
    padding: 0;
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(280deg, #2b271e 0%, #6d5f40 100%);
    background-size: 200% 200%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 3px transparent;
  }
`;

const ReplayButton = styled.button`
  display: flex;
  justify-content: center;
  gap: 8px;
  /* box-shadow: 0px 2px 0px 0px #055ba0;
  background: linear-gradient(0deg, rgba(141, 221, 255, 0.01) 0%, #8dddff 100%),
    var(--pure-sky-100, rgba(49, 56, 81,0.15)); */
  /* border: 1px solid #8dddff; */
  /* color: #131635; */
  transition: all ease-in-out 0.2s;
  padding: 16px 24px;

  border-radius: 74px;
  border: 2px solid #b48b23;
  background: linear-gradient(
      103deg,
      rgba(208, 219, 255, 0) -21.86%,
      rgba(209, 219, 255, 0.08) 32.26%,
      rgba(209, 219, 255, 0.19) 45.78%,
      rgba(215, 224, 255, 0.08) 59.31%,
      rgba(222, 231, 255, 0) 113.42%
    ),
    rgba(49, 56, 81, 0.15);
  box-shadow: 0px 0px 0px 5px #171a28 inset;
  backdrop-filter: blur(44px);

  color: var(--daylight-color, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 24px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;

  .icon-wrapper {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0px 1px 0px 0px #055ba0;
  }
`;

export default memo(RaffleLeaderboardCarousel);
