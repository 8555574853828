import React from "react";
import { Pages } from "components/common";
import styled, { css } from "styled-components";
import { IAppContext, withApp } from "contexts";
import { ProfileRafflesTable } from "./ProfileRafflesTable";
import { RaffleHeader } from "./RafflesHeader";
import { IUserDto } from "interfaces";
import { scrollToElement } from "helpers/scrollToElement";

export interface RafflesTabProps {
  user: IUserDto | null;
}

const RafflesTab: React.FC<RafflesTabProps & IAppContext> = ({
  accessFetch,
  user,
}): React.ReactElement => {
  const [value, setValue] = React.useState<string>("");
  const [entries, setEntries] = React.useState<any>([]);

  const [skip, setSkip] = React.useState<number>(0);

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [sorter, setSorter] = React.useState<string>("isWinner:desc");
  const [take, setTake] = React.useState<number>(6);
  const [total, setTotal] = React.useState<number>(0);

  const fetchData = React.useCallback(async () => {
    if (user) {
      try {
        setIsLoading(true);
        const searchParam = value ? `search=${value}` : "";
        const skipTakeParam = `skip=${skip}&take=${take}`;

        const response = await accessFetch(
          `user/${user.id}/raffle-entries?${searchParam}${
            searchParam && skipTakeParam ? "&" : ""
          }${skipTakeParam}&sort=${sorter}`
        );

        const data = await response?.json();
        setEntries(data?.items);
        setTotal(data?.totalCount);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  }, [accessFetch, value, skip, take, user, sorter]);

  React.useEffect(() => {
    fetchData(); // Initial data fetch
  }, [fetchData]);

  const onPage = (index: number) => {
    setSkip(index * take);
    setTake(take);
  };

  const profileRafflesTableContainerId = "profileRafflesTableContainer";

  return (
    <Container>
      <TableContainer id={profileRafflesTableContainerId}>
        <RaffleHeader
          boxStyles={css`
            margin: 15px auto;
          `}
          setSorter={setSorter}
          setValue={setValue}
          sorter={sorter}
          skip={skip}
          setSkip={setSkip}
        />
        <ProfileRafflesTable
          $keys={["id", "createdAt", "deadline", "entryCost", "isWinner", "prizeInDollars"]}
          entries={entries ?? []}
          skip={skip}
          isLoading={isLoading}
          take={take}
        />
        <div style={{ marginTop: 20 }}>
          <Pages
            skip={skip}
            take={take}
            total={total}
            showInfo={false}
            onClick={(index) => {
              onPage(index);
              scrollToElement(profileRafflesTableContainerId);
            }}
          />
        </div>
      </TableContainer>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 1920px;
  overflow: hidden;
  width: 100%;
`;

const TableContainer = styled.div`
  position: relative;
  margin: auto;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
`;
export default withApp(RafflesTab);
