const gradientMapper = [
  {
    type: "green-violet",
    gameIds: ["spirit-blast"],
  },
  {
    type: "green",
    gameIds: [
      "shaolin-panda-chaos-reels",
      "eternal-clash",
      "royal-ribbit",
      "happy-honey",
      "sweet-smash",
      "walrus-king",
      "crop-of-the-dead",
      "pearly-shores",
      "kongs-coin-chaos",
      "paddy-power-celtic-king-superwild",
      "hot-harvest",
      "blarneys-gold",
      "panda-bills",
      "sheep-king",
      "mutant-potatoes",
    ],
  },
  {
    type: "red",
    gameIds: [
      "eternal-clash-reloaded",
      "cash-strike",
      "gemstone-guardians",
      "boom-the-buffoon",
      "bounty-smash",
      "blazing-touch",
      "cash-inferno-sizzling-scatters",
      "blazing-inferno-chaos-reels",
    ],
  },
  {
    type: "violet",
    gameIds: ["disco-dive", "jackpot-heist-daylight-robbery", "lord-of-the-dunes", "lollicat"],
  },
  {
    type: "gold",
    gameIds: ["lightning-blitz-chain-collect", "rune-raiders"],
  },
  {
    type: "brown",
    gameIds: [
      "dystopia-rebel-road",
      "dystopia-road",
      "barhalla",
      "teddys-quest",
      "bullet-bonanza",
      "flaming-frenzy",
    ],
  },
  {
    type: "dark-blue",
    gameIds: ["midas-moolah-random-reels"],
  },
];

function getRandomInt(max: number) {
  return Math.floor(Math.random() * max);
}

export const getGradientPresets = (gameId: string) => {
  let gradientType = gradientMapper.find((v) => v.gameIds.includes(gameId))?.type ?? getRandomInt(10).toString();

  switch (gradientType) {
    case "green-violet":
    case "1":
    case "0":
      return ["#48024E59", "#380630B2"];
    case "green":
    case "2":
      return ["#024E3359", "#063838B2"];
    case "red":
    case "3":
      return ["#3C0F0F80", "#330D0D"];
    case "violet":
    case "4":
      return ["#3C0F3680", "#2E0D33"];
    case "gold":
    case "5":
      return ["#F7CC0180", "#FF7917"];
    case "brown":
    case "6":
      return ["#3C1D0F80", "#331F0D"];
    case "dark-blue":
    case "7":
    default:
      return ["#100F3C80", "#100D33"];
  }
};
