import Home from "assets/profile/icons/home.svg";
import History from "assets/profile/icons/history.svg";
import Research from "assets/profile/icons/research.svg";
import Ticket from "assets/profile/icons/ticket.svg";
import W from "assets/profile/icons/w.svg";
import Dollar from "assets/profile/icons/dollar.svg";
import { ProfileTabs } from ".";

export const ProfileTabsIcons = {
  [ProfileTabs.Main]: <Home />,
  [ProfileTabs.Bets]: <History />,
  [ProfileTabs.Shop]: <W />,
  [ProfileTabs.Raffles]: <Ticket />,
  [ProfileTabs.Connections]: <Research />,
  [ProfileTabs.Transactions]: <Dollar />,
};

export const ProfileTabsList = Object.values(ProfileTabs);
