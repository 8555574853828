import { SVGProps } from "react";

export type HuntTrackerProps = SVGProps<SVGSVGElement>;

const HuntTracker: React.FC<HuntTrackerProps> = (props) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.3125 11.1094H19.4484C19.043 7.67813 16.3219 4.95703 12.8906 4.55156V1.6875C12.8906 1.58437 12.8063 1.5 12.7031 1.5H11.2969C11.1937 1.5 11.1094 1.58437 11.1094 1.6875V4.55156C7.67813 4.95703 4.95703 7.67813 4.55156 11.1094H1.6875C1.58437 11.1094 1.5 11.1937 1.5 11.2969V12.7031C1.5 12.8063 1.58437 12.8906 1.6875 12.8906H4.55156C4.95703 16.3219 7.67813 19.043 11.1094 19.4484V22.3125C11.1094 22.4156 11.1937 22.5 11.2969 22.5H12.7031C12.8063 22.5 12.8906 22.4156 12.8906 22.3125V19.4484C16.3219 19.043 19.043 16.3219 19.4484 12.8906H22.3125C22.4156 12.8906 22.5 12.8063 22.5 12.7031V11.2969C22.5 11.1937 22.4156 11.1094 22.3125 11.1094ZM12 17.7188C8.84062 17.7188 6.28125 15.1594 6.28125 12C6.28125 8.84062 8.84062 6.28125 12 6.28125C15.1594 6.28125 17.7188 8.84062 17.7188 12C17.7188 15.1594 15.1594 17.7188 12 17.7188Z"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      />
      <path
        d="M12 9.1875C11.2477 9.1875 10.5445 9.47813 10.0125 10.0125C9.48047 10.5445 9.1875 11.2477 9.1875 12C9.1875 12.7523 9.48047 13.4555 10.0125 13.9875C10.5445 14.5172 11.25 14.8125 12 14.8125C12.75 14.8125 13.4555 14.5195 13.9875 13.9875C14.5172 13.4555 14.8125 12.75 14.8125 12C14.8125 11.25 14.5195 10.5445 13.9875 10.0125C13.4555 9.47813 12.7523 9.1875 12 9.1875Z"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      />
    </svg>
  );
};

export default HuntTracker;
