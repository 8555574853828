import { SVGProps } from "react";

export type TicketProps = SVGProps<SVGSVGElement>;

const Ticket: React.FC<TicketProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="18"
      viewBox="0 0 24 18"
      fill="none"
    >
      <path
        d="M19.845 2C21.0332 2 22 2.96794 22 4.15787V7.34204C22 7.66609 21.7377 7.92882 21.4141 7.92882C20.3529 7.92882 19.4895 8.79338 19.4895 9.85606C19.4895 10.9187 20.3529 11.7833 21.4141 11.7833C21.7377 11.7833 22 12.046 22 12.3701V15.5542C22 16.7441 21.0333 17.712 19.845 17.712H4.15491C2.96668 17.712 2 16.7441 2 15.5542V12.3701C2 12.046 2.26234 11.7833 2.58594 11.7833C3.64711 11.7833 4.51047 10.9187 4.51047 9.85606C4.51047 8.79338 3.64711 7.92882 2.58594 7.92882C2.26234 7.92882 2 7.66609 2 7.34204V4.15787C2 2.96794 2.96668 2 4.15491 2H19.845ZM14.5125 9.82293L16.1746 8.20036C16.3343 8.0445 16.3918 7.81142 16.3229 7.59897C16.254 7.38653 16.0706 7.23171 15.8499 7.19965L13.5527 6.86532L12.5254 4.78091C12.4267 4.58063 12.223 4.45385 12 4.45385C11.777 4.45385 11.5733 4.58063 11.4746 4.78091L10.4473 6.86532L8.15012 7.19965C7.92941 7.23171 7.74606 7.38653 7.67715 7.59897C7.6082 7.81142 7.6657 8.0445 7.82539 8.20036L9.48762 9.82293L9.09527 12.1141C9.05758 12.3342 9.14793 12.5567 9.3284 12.688C9.43047 12.7623 9.55137 12.8001 9.67285 12.8001C9.76613 12.8001 9.85973 12.7777 9.94547 12.7326L12 11.6509L14.0546 12.7326C14.1404 12.7777 14.2339 12.8 14.3272 12.8C14.328 12.8 14.3288 12.8 14.3296 12.8C14.6523 12.7992 14.914 12.5368 14.914 12.2131C14.914 12.1681 14.9089 12.1242 14.8993 12.082L14.5125 9.82293Z"
        fill={`
          ${props.fill ?? "#ABBADB"}
        `}
      />
    </svg>
  );
};

export default Ticket;
