import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import { TheFieldset, IStyles } from "components";
import { IDtoLeaderboard, IDtoLeaderboardUser } from "interfaces";
import { triggerNotification } from "components/TheComponents/Notification";
import { Button, ButtonBlue } from "components/common";
import { useAppContext } from "contexts";
import VerifierProvider, { useVerifierContext } from "contexts/VerifierProvider";

type TPayload = Pick<IDtoLeaderboardUser, "bannedReason">;

export interface ILeaderboardBanFormProps {
  leaderboard: IDtoLeaderboard;
  leaderboardUser: IDtoLeaderboardUser;
  onBan: () => void;
  onClose: () => void;
}

const LeaderboardBanForm: React.FC<ILeaderboardBanFormProps> = ({
  leaderboard,
  leaderboardUser,
  onBan,
  onClose,
}): React.ReactElement => {
  const { accessFetch, accessURL } = useAppContext();

  const { verify } = useVerifierContext();

  const [payload, setPayload] = React.useState<TPayload>({
    bannedReason: "",
  });
  const [hasError, setHasError] = React.useState<string | null>(null);

  const fieldsetProps = (key: keyof TPayload) => {
    const { [key as keyof TPayload]: value } = payload;
    const empty = value === "";
    let valid: boolean | undefined;
    if (["bannedReason"].includes(key) && typeof value === "string") {
      valid = !empty && value.trim().length > 0;
    }
    return {
      empty,
      ...(typeof valid === "boolean" && { valid }),
    };
  };

  const onTextChange = (key: string) => (event: React.ChangeEvent) => {
    event.preventDefault();
    const { target } = event;
    if (!(target instanceof HTMLTextAreaElement)) return;
    const { value } = target as HTMLTextAreaElement;
    setPayload((payload) => ({ ...payload, [key]: value }));
  };

  const banUser = async ({ otp }: { otp: string }) => {
    try {
      const leaderboardId = leaderboard.id;
      const refereeId = leaderboardUser.refereeId;
      const url = accessURL(`/leaderboard/${leaderboardId}/${refereeId}/banned`) as URL;
      const response = await accessFetch(url.href, {
        method: "POST",
        headers: {
          "x-otp": otp,
        },
        body: JSON.stringify({
          bannedReason: payload.bannedReason,
        }),
      });
      const json = await response?.json();
      if (response && (response.status === 200 || response.status === 201)) {
        triggerNotification({ text: "User Banned!", type: "success" });
        onBan();
      } else {
        setHasError(json.message);
        triggerNotification({ text: json.message, type: "error" });
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <Box>
      <BoxItem
        styles={css`
          margin-bottom: 16px;
        `}
      >
        <Text1>
          <Image src="/images/logos/logo.svg" width={170} height={32} alt="Logo" />
        </Text1>
      </BoxItem>
      <BoxItem>
        <Text1>
          Ban user <strong>{leaderboardUser.displayName}</strong> from a leaderboard ?
        </Text1>
      </BoxItem>
      <BoxItem>
        <Text3>What is the ban reason?</Text3>
      </BoxItem>
      <BoxItem>
        <TheFieldset valid={typeof hasError !== "string"} title="Ban reason">
          <textarea
            placeholder="Ban reason"
            value={payload?.bannedReason ?? ""}
            onChange={onTextChange("bannedReason")}
          />
        </TheFieldset>
      </BoxItem>
      <BoxItem>
        <Actions>
          <Button
            styles={css`
              width: 150px;
            `}
            isDark
            onClick={onClose}
          >
            <ButtonBlue isDark>Cancel</ButtonBlue>
          </Button>

          <Button
            styles={css`
              width: 150px;
            `}
            onClick={(e) => {
              e.preventDefault();
              const keys: (keyof TPayload)[] = ["bannedReason"];
              const valid = keys.every((key) => fieldsetProps(key).valid);
              if (valid) {
                verify(({ otp }) => banUser({ otp }));
              } else {
                triggerNotification({ text: "Validation Failed", type: "error" });
                setHasError("Validation Failed");
              }
            }}
          >
            <ButtonBlue>Ban</ButtonBlue>
          </Button>
        </Actions>
      </BoxItem>
      <BoxItem>
        <Text2>
          You’re about to block this user, which will prevent any further user’s actions. Are you
          certain this is what you want to proceed with?
        </Text2>
      </BoxItem>
    </Box>
  );
};

export const LeaderboardBanFormLayout: React.FC<ILeaderboardBanFormProps> = (props) => {
  return (
    <VerifierProvider>
      <LeaderboardBanForm {...props} />
    </VerifierProvider>
  );
};

const Text1 = styled.div`
  font-family: var(--font-family-golos);
  font-style: italic;
  font-weight: 900;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: #d2eaff;
  text-align: center;
`;

const Text2 = styled.div`
  font-family: var(--font-family-golos);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: 0.02em;
  color: rgba(210, 234, 255, 0.75);
  text-align: center;
`;

const Text3 = styled.div`
  font-family: var(--font-family-golos);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.02em;
  color: rgba(210, 234, 255, 0.5);
  text-align: left;
`;

const Actions = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  max-width: 543px;

  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;

  ${({ styles }) => styles}
`;
