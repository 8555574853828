import Link from "next/link";
import styled, { css } from "styled-components";
import { ITheIconLink } from "interfaces";

const cssActive = css`
  border-color: #3b8be9;
`;

export const TheIconLink = styled(Link)<ITheIconLink>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 8px;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border: 1px solid rgba(210, 234, 255, 0.25);
  border-radius: 62px;

  &:hover {
    ${cssActive};
  }

  ${({ "data-active": active }) => (active ? cssActive : null)}

  ${({ styles }) => styles}
`;
