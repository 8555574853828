import React from "react";
import styled, { css } from "styled-components";
import { TheButton, IStyles } from "components";
import { SlideSwitcher } from "components";

export interface ITheLeaderboardHeader {
  switcher: string;
  setSwitcher: (switcher: string) => void;
  onCreate?: () => void;
  onShowFuture?: () => void;
  onShowHistory?: () => void;
  onManage?: (() => void) | null;
}

export const TheLeaderboardHeader: React.FC<ITheLeaderboardHeader> = ({
  switcher,
  setSwitcher,
  onCreate,
  onShowFuture,
  onShowHistory,
  onManage,
}): React.ReactElement => {
  const BUTTONS = [
    ...(onCreate
      ? [
          {
            title: "Create",
            onClick: (event: React.MouseEvent) => {
              event.preventDefault();
              onCreate && onCreate();
            },
          },
        ]
      : []),
    ...(onShowFuture
      ? [
          {
            title: "Show Future",
            onClick: (event: React.MouseEvent) => {
              event.preventDefault();
              onShowFuture && onShowFuture();
            },
          },
        ]
      : []),
    ...(onShowHistory
      ? [
          {
            title: "Show History",
            onClick: (event: React.MouseEvent) => {
              event.preventDefault();
              onShowHistory && onShowHistory();
            },
          },
        ]
      : []),
    ...(onManage
      ? [
          {
            title: "Manage",
            onClick: (event: React.MouseEvent) => {
              event.preventDefault();
              onManage && onManage();
            },
          },
        ]
      : []),
  ];

  return (
    <BoxContent>
      <BoxItem
        styles={css`
          flex-grow: 1;
        `}
      >
        <SlideSwitcher value={switcher} onChange={setSwitcher} />
      </BoxItem>
      <BoxItem>
        {BUTTONS.map(({ title, onClick }, index) => (
          <TheButton
            key={index}
            styles={css`
              width: 190px;
              white-space: nowrap;
              @media (max-width: 1480px) {
                width: 130px;
              }
            `}
            preset="dark-blue"
            onClick={onClick}
          >
            <ButtonTitle
              styles={css`
                font-size: 18px;
                @media (max-width: 1480px) {
                  font-size: 15px;
                }
              `}
            >
              {title}
            </ButtonTitle>
          </TheButton>
        ))}
      </BoxItem>
    </BoxContent>
  );
};

const BoxContent = styled.div<IStyles>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 16px;
  padding: 6px 12px;

  ${({ styles }) => styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  /* flex-flow: row; */
  justify-content: center;
  align-items: center;
  gap: 16px;

  ${({ styles }) => styles}
`;

const ButtonTitle = styled.div<IStyles>`
  box-sizing: border-box;
  display: inline-flex;
  color: rgba(210, 234, 255, 0.75);
  font-family: var(--font-family-golos);
  font-size: 18px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;
  ${({ styles }) => styles}
`;
