import styled from "styled-components";

export const Root = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  background: ${({ active }) => (active ? "#00B2FF0D" : "#141722")};
  border: 1px solid ${({ active }) => (active ? "#00B2FF" : "#1b2035")};
  border-radius: 8px;
  height: 48px;
`;
