import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ITheHeaderProfile } from "interfaces";
import Image from "next/image";
import { ThePointsBalance } from "../../../../../components/TheComponents/ThePointsBalance";
import {
  DesktopView,
  Text,
  CalendarLink,
  MenuListStyled,
  BalanceTile,
  ProfileDisplayName,
  ProfileListStyled,
  ProfileListItem,
  BalanceBlock,
  BalanceBtn,
  ProfileBtn,
  MenuAbsolute,
} from "../styles";
import Calendar from "../../icons/Calendar";
import ArrowDownIcon from "/public/images/icons/arrow-down.svg";
import { Menu, MenuButton, MenuList } from "@chakra-ui/react";
import ChakraModal from "components/TheComponents/Modal";
import ExchangeModal from "components/games/ExchangeBanner/ExchangeModal";
import { triggerNotification } from "components/TheComponents/Notification";
import { useProfileContext } from "components/public/ProfileLayout/ProfileContext";
import { Logout } from "../../icons";
import { useMediaQuery } from "react-responsive";
import { useBalanceContext } from "contexts/Balance/BalanceProvider";
import { useRouter } from "next/router";
import WrewardsButton from "components/wrewards-ui-kit/Button";
import { ProfileTabs, ProfileTabsListPublic } from "types/profile";
import { ProfileTabsIcons } from "types/profile/constants";

const DesktopViewProfileMenu = ({
  profile,
  onOpenLogout,
}: ITheHeaderProfile & {
  onOpenLogout: () => void;
}): React.ReactElement => {
  const router = useRouter();
  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  });
  const isTablet = useMediaQuery({
    query: "(max-width: 1024px)",
  });
  const [isShowModal, setIsShowModal] = React.useState(false);
  const { changeActiveTab, allowedNav } = useProfileContext();
  const { gPointsBalance } = useBalanceContext();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const exchange = () => {
    if (profile) {
      setIsShowModal(true);
    } else {
      triggerNotification({ text: "Please, login for exchage points", type: "error" });
    }
  };

  const handleMenuItemClick = useCallback(
    (item: ProfileTabs) => {
      router.push("/profile");
      changeActiveTab(item);
      setIsMenuOpen(false);
    },
    [router, changeActiveTab]
  );

  useEffect(() => {
    const onResize = () => {
      setIsMenuOpen(false);
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const inPlay = useMemo(() => {
    return router.asPath.includes("/games/slots") && router.query.mode;
  }, [router.asPath, router.query.mode]);

  const Navigation = useMemo(() => {
    return ProfileTabsListPublic.map((item, index) => (
      <ProfileListItem key={index} onClick={() => handleMenuItemClick(item)}>
        {ProfileTabsIcons[item]}
        {item}
      </ProfileListItem>
    ));
  }, [handleMenuItemClick]);

  return (
    <>
      <DesktopView>
        <MenuAbsolute>
          <Menu placement="bottom" isLazy>
            {({ isOpen }) => (
              <div style={{ position: "relative" }}>
                <MenuButton isActive={isOpen} as={BalanceBtn}>
                  {!isTablet && "Balance"}
                  <BalanceBlock>
                    <Image alt="g-coin" src="/images/coins/g-coin.svg" width={20} height={20} />
                    {!inPlay ? (
                      <ThePointsBalance value={gPointsBalance} />
                    ) : (
                      <span className="inPlay">In Play</span>
                    )}

                    <div className="icon">
                      <ArrowDownIcon />
                    </div>
                  </BalanceBlock>
                </MenuButton>
                <MenuList padding={0} background={"transparent"} zIndex={1001}>
                  <MenuListStyled>
                    <div className="title">Your points balance </div>
                    <div className="content">
                      <BalanceTile>
                        <Image alt="w-coin" src="/images/coins/w-coin.svg" width={24} height={24} />
                        <div>
                          <small>W-Points</small>
                          <Text>
                            <ThePointsBalance value={profile?.pointsBalance} isFixed />
                          </Text>
                        </div>
                      </BalanceTile>
                      <BalanceTile>
                        <Image alt="g-coin" src="/images/coins/g-coin.svg" width={24} height={24} />
                        <div>
                          <small>G-Points</small>
                          <Text>
                            <ThePointsBalance value={gPointsBalance} />
                          </Text>
                        </div>
                      </BalanceTile>

                      <WrewardsButton variant="game-action-gray" onClick={exchange}>
                        <Image
                          alt="g-coin"
                          src="/images/icons/exchange.svg"
                          width={24}
                          height={24}
                        />
                        <span>Exchange Points</span>
                      </WrewardsButton>
                    </div>
                  </MenuListStyled>
                </MenuList>
              </div>
            )}
          </Menu>
        </MenuAbsolute>

        {!isMobile && (
          <CalendarLink href="/advent-calendar">
            <Calendar fill="#F2C94C" />
            <span>Calendar</span>
          </CalendarLink>
        )}
        <Menu placement="bottom-end" isLazy isOpen={isMenuOpen}>
          {({ isOpen }) => (
            <>
              <MenuButton
                isActive={isOpen}
                as={ProfileBtn}
                onClick={() => {
                  setIsMenuOpen((prev) => !prev);
                }}
              >
                <div>
                  <Image
                    alt="profile"
                    src={profile?.profileImageUrl ?? ""}
                    width={24}
                    height={24}
                    style={{
                      borderRadius: "50%",
                    }}
                  />
                </div>
                <ProfileDisplayName>{profile?.displayName}</ProfileDisplayName>
                <div className="icon">
                  <ArrowDownIcon />
                </div>
              </MenuButton>

              <MenuList padding={0} background={"transparent"}>
                <ProfileListStyled>
                  {Navigation}
                  <ProfileListItem onClick={onOpenLogout}>
                    <Logout />
                    Log Out
                  </ProfileListItem>
                </ProfileListStyled>
              </MenuList>
            </>
          )}
        </Menu>
      </DesktopView>

      <ChakraModal
        content={
          <ExchangeModal
            onClose={() => {
              setIsShowModal(false);
            }}
          />
        }
        isOpen={isShowModal}
        onClose={() => setIsShowModal(false)}
      />
    </>
  );
};

export default DesktopViewProfileMenu;
