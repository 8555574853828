import React from "react";
import styled from "styled-components";
import { $stylesReset } from "components";
import TheLiveStream from "components/hunt-tracker/LiveStream/TheLiveStream";
import { MainSection } from "components/pachinko/Main";
// import { Prizes } from "components/pachinko/Prizes";
// import TopTenTableComponent from "components/pachinko/Table/TopTenTableComponent";
import { Fade } from "react-awesome-reveal";

export interface IPachinkoComponent {}

const Box = styled.div`
  ${$stylesReset}
  overflow:hidden;
  background: var(--global-bg-dark);
`;

export const PachinkoComponent: React.FC<IPachinkoComponent> = (): JSX.Element => {
  return (
    <>
      <MainSection />
      {/* <Prizes /> */}
      {/* <Fade triggerOnce>
        <TopTenTableComponent />
      </Fade> */}
      {/* <Fade triggerOnce>
        <TheLiveStream />
      </Fade> */}
    </>
  );
};
