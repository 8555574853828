import { SVGProps } from "react";

export type SupportProps = SVGProps<SVGSVGElement>;

const Support: React.FC<SupportProps> = (props) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 0.429932H5C2 0.429932 0 2.42993 0 5.42993V11.4299C0 14.4299 2 16.4299 5 16.4299V18.5599C5 19.3599 5.89 19.8399 6.55 19.3899L11 16.4299H15C18 16.4299 20 14.4299 20 11.4299V5.42993C20 2.42993 18 0.429932 15 0.429932ZM10 12.5999C9.58 12.5999 9.25 12.2599 9.25 11.8499C9.25 11.4399 9.58 11.0999 10 11.0999C10.42 11.0999 10.75 11.4399 10.75 11.8499C10.75 12.2599 10.42 12.5999 10 12.5999ZM11.26 8.44993C10.87 8.70993 10.75 8.87993 10.75 9.15993V9.36993C10.75 9.77993 10.41 10.1199 10 10.1199C9.59 10.1199 9.25 9.77993 9.25 9.36993V9.15993C9.25 7.99993 10.1 7.42993 10.42 7.20993C10.79 6.95993 10.91 6.78993 10.91 6.52993C10.91 6.02993 10.5 5.61993 10 5.61993C9.5 5.61993 9.09 6.02993 9.09 6.52993C9.09 6.93993 8.75 7.27993 8.34 7.27993C7.93 7.27993 7.59 6.93993 7.59 6.52993C7.59 5.19993 8.67 4.11993 10 4.11993C11.33 4.11993 12.41 5.19993 12.41 6.52993C12.41 7.66993 11.57 8.23993 11.26 8.44993Z"
        fill={props.fill ?? "#E9F4FF"}
      />
    </svg>
  );
};

export default Support;
