import Image from "next/image";
import { Root, Title, Description } from "./styles";
import { ButtonBlue, Button } from "components/common";
import { useRouter } from "next/router";

export type AvailableModalProps = {
  title: string;
  text: string;
};

const AvailableModal: React.FC<AvailableModalProps> = ({ title, text }) => {
  const router = useRouter();

  return (
    <Root>
      <Image alt="" src="/images/HeaderComponent/logo.svg" width={316} height={68} />
      <Title>{title}</Title>
      <Description>{text}</Description>
      <ButtonBlue
        onClick={() => {
          router.push("/");
        }}
      >
        <Button>Back To Main</Button>
      </ButtonBlue>
    </Root>
  );
};

export default AvailableModal;
