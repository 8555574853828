import styled from "styled-components";

export const Root = styled.div`
  display: flex;
  gap: 12px;
  padding: 10px 0;
  @media (max-width: 768px) {
    width: min(1080px, 100%);
    overflow-x: auto;
  }
`;

export const Item = styled.div<{ active: boolean }>`
  cursor: pointer;
  display: flex;
  padding: 8px 12px;
  border-radius: 8px;

  ${({ active }) => (active ? `background: #24274680;` : `background: #0b0e16;`)}

  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 600;
  line-height: 16.41px;
  letter-spacing: 0.02em;
  color: #e9f4ff80;
  text-transform: uppercase;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.7;
  }
`;
