import React, { useState } from "react";
import JsonView from "react18-json-view";
import styled, { css } from "styled-components";
import "react18-json-view/src/style.css";
import { TheFieldset, TheIcon } from "components/TheComponents";
import ChakraModal from "components/wrewards-ui-kit/Modal/WrewardsModal";
import WrewardsButton from "components/wrewards-ui-kit/Button";
import { Flex } from "components/common";
import ShieldIcon from "assets/games/icons/shield-2.svg";

interface LeaderboardRaffleVerifyModalProps {
  isOpen: boolean;
  onClose?: () => void;
  proof: any;
}

export const LeaderboardRaffleVerifyModalButton: React.FC<
  Pick<LeaderboardRaffleVerifyModalProps, "proof">
> = ({ proof }) => {
  const [isFairOpen, setIsFairOpen] = useState(false);
  return (
    <>
      <WrewardsButton style={{padding: '2px 4px'}} variant="primary-icon" onClick={() => setIsFairOpen(true)}>
        <ShieldIcon />
      </WrewardsButton>
      <LeaderboardRaffleVerifyModal
        proof={proof}
        isOpen={isFairOpen}
        onClose={() => setIsFairOpen(false)}
      />
    </>
  );
};

const LeaderboardRaffleVerifyModal: React.FC<LeaderboardRaffleVerifyModalProps> = ({
  isOpen,
  proof,
  onClose,
}) => {
  const onCopy = async (code: string) => {
    try {
      if (navigator.clipboard instanceof Clipboard) {
        await navigator.clipboard.writeText(code);
      } else {
        throw new Error("Clipboard undefined!");
      }
    } catch (error) {
      console.error("Could not copy text: ", error);
    }
  };

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={() => onClose?.()}
      content={
        <Flex width="100%">
          <ContentContainer>
            <Paragraph>
              <a
                style={{ textDecoration: "underline", cursor: "pointer" }}
                href={"https://api.random.org/signatures/form"}
              >
                Random.org Result Verification Page.
              </a>
            </Paragraph>
            <Flex style={{ marginBottom: 20 }} width="100%" height="100%">
              {/* <JsonView enableClipboard={false} collapseStringsAfterLength={40} src={JSON.stringify(proof)} /> */}
              <JsonView
                enableClipboard={false}
                collapseStringsAfterLength={100}
                src={proof?.proofBody ? JSON?.parse(proof?.proofBody) : null}
              />
            </Flex>
            <WrewardsButton
              variant="game-action-gray"
              style={{ width: "100%" }}
              onClick={(e) => {
                e.preventDefault();
                if (proof?.proofBody) {
                  onCopy(proof?.proofBody);
                }
              }}
            >
              COPY
              <TheIcon icon="copy" size={20} />
            </WrewardsButton>

            <TheFieldset title="Signature">
              <DarkTextarea
                disabled
                rows={3}
                placeholder="Proof signature"
                value={proof?.proofSignature || ""}
              />
            </TheFieldset>
          </ContentContainer>
        </Flex>
      }
    />
  );
};

const Paragraph = styled.div`
  font-family: var(--font-family-golos);
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  margin: 3px 0px;
  letter-spacing: 0.02em;
  color: rgba(210, 234, 255, 0.75);
`;

const ContentContainer = styled.div`
  margin: auto;
  border-radius: 13px;
  width: 100%;
  color: rgb(84, 92, 110);
  display: flex;
  flex-direction: column;
  gap: 8px;
  & h1 {
    color: white;
    margin: 40px 0px;
    font-size: 20px;
  }
`;

const DarkTextarea = styled.textarea`
  background-color: #101622;
  border: 1px solid #3b8be9;
  border-radius: 4px;
  height: 150px !important;
  color: white;
  height: fit-content;
  font-family: inherit;
  font-size: inherit;
  @media (max-width: 1452px) {
    height: 150px !important;
  }
  @media (max-width: 600px) {
    height: 200px !important;
  }
`;

export default LeaderboardRaffleVerifyModal;
