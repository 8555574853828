import { triggerNotification } from "components/TheComponents/Notification";
import RefreshService from "./refreshService";
import { URL_BE } from "types/constants";
import { ExceptionHandlingType } from "types/apiServiceTypes";

class ApiService extends RefreshService {
  private getRequestDefaultOptions(
    input: string | URL,
    init?: RequestInit | undefined
  ): RequestInit {
    const url = new URL(input, URL_BE);
    return {
      method: "GET",
      ...init,
      credentials: "include",
      headers: {
        ...(url.href.startsWith(URL_BE) && {
          "Content-Type": "application/json",
        }),
        ...init?.headers,
      },
    };
  }

  async export(url: string, otp: string, type: "csv" | "xlsx", fileName: string = "report") {
    const res: any = await this.request(`${url}/${type}`, {
      method: "GET",
      headers: {
        "x-otp": otp,
      },
    });
    let localUrl: any = null;

    if (type === "csv") {
      const csvData = await res.text();
      const blob = new Blob([csvData], { type: "text/csv" });
      localUrl = window.URL.createObjectURL(blob);
    }

    if (type === "xlsx") {
      const blob = await res.blob();
      localUrl = window.URL.createObjectURL(new Blob([blob]));
    }

    const link = document.createElement("a");
    link.href = localUrl;
    link.setAttribute("download", `${fileName}.${type}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async request(
    input: string | URL,
    init?: RequestInit | undefined,
    exceptionHandlingType: ExceptionHandlingType = ExceptionHandlingType.MANUAL
  ): Promise<Response | undefined> {
    const url = new URL(input, URL_BE);
    try {
      const initalRequest = () => fetch(url.href, this.getRequestDefaultOptions(input, init));

      let response = await initalRequest();
      if (response && response.status == 401) {
        response = await this.handleUnauthorizedResponse(initalRequest);
      } else if (
        response &&
        response.status >= 400 &&
        (exceptionHandlingType === ExceptionHandlingType.AUTOMATIC ||
          exceptionHandlingType === ExceptionHandlingType.MANUAL)
      ) {
        const json = await response.json();
        throw new Error(json?.message ?? "Fetch error", { cause: response });
      }
      return response;
    } catch (error: any) {
      if (exceptionHandlingType === ExceptionHandlingType.AUTOMATIC) {
        triggerNotification({
          text: error?.message,
          type: "error",
        });
      } else if (exceptionHandlingType === ExceptionHandlingType.MANUAL) {
        throw error;
      }
      return undefined;
    }
  }

  async get<T>(
    input: string | URL,
    init?: RequestInit | undefined,
    exceptionHandlingType: ExceptionHandlingType = ExceptionHandlingType.MANUAL
  ): Promise<T> {
    const response = await this.request(input, { ...init, method: "GET" }, exceptionHandlingType);
    if (response === undefined) throw new Error("Fetch error");
    const json = await response.json();
    return json as T;
  }

  async delete(input: string | URL, init?: RequestInit | undefined): Promise<Response | void> {
    return this.request(input, { ...init, method: "DELETE" });
  }

  async post(
    input: string | URL,
    init?: RequestInit | undefined,
    exceptionHandlingType: ExceptionHandlingType = ExceptionHandlingType.MANUAL
  ): Promise<Response | void> {
    return this.request(input, { ...init, method: "POST" }, exceptionHandlingType);
  }
}

const apiService = new ApiService();
export default apiService;
export { ApiService };
