import { SVGProps } from "react";

export type ProvablyFairProps = SVGProps<SVGSVGElement>;

const ProvablyFair: React.FC<ProvablyFairProps> = (props) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.8152 4.64123C21.7255 4.53843 21.6154 4.45533 21.492 4.39718C21.3686 4.33904 21.2344 4.3071 21.098 4.30338C18.9795 4.248 16.2989 1.98277 14.5266 1.09384C13.4319 0.54646 12.7091 0.185537 12.1663 0.0904596C12.0561 0.0740556 11.9441 0.0743668 11.834 0.0913827C11.2912 0.18646 10.5685 0.547383 9.47462 1.09477C7.70231 1.98277 5.0217 4.248 2.90323 4.30338C2.76678 4.30752 2.63262 4.33965 2.50909 4.39776C2.38555 4.45588 2.27527 4.53874 2.18508 4.64123C1.99839 4.85289 1.90197 5.12922 1.91646 5.41108C2.37154 14.6631 5.69 20.3889 11.5128 23.7166C11.6642 23.8025 11.8322 23.8468 11.9992 23.8468C12.1663 23.8468 12.3343 23.8025 12.4866 23.7166C18.3094 20.3889 21.6269 14.6631 22.0829 5.41108C22.0987 5.1292 22.0025 4.85252 21.8152 4.64123ZM17.1851 8.28L12.2715 15.5308C12.0952 15.7911 11.8183 15.9637 11.5442 15.9637C11.27 15.9637 10.9635 15.8132 10.7706 15.6203L7.30723 12.156C7.19416 12.0425 7.13067 11.8888 7.13067 11.7286C7.13067 11.5684 7.19416 11.4147 7.30723 11.3012L8.16293 10.4437C8.27689 10.3315 8.43039 10.2686 8.59031 10.2686C8.75023 10.2686 8.90373 10.3315 9.0177 10.4437L11.27 12.696L15.1829 6.92031C15.2735 6.78814 15.4126 6.69716 15.57 6.66724C15.7274 6.63732 15.8902 6.67089 16.0229 6.76061L17.0245 7.44C17.1568 7.53039 17.2479 7.66947 17.278 7.82687C17.3081 7.98426 17.2747 8.14717 17.1851 8.28Z"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      />
    </svg>
  );
};

export default ProvablyFair;
