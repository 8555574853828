import React from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import {
	IStyles,
} from 'components';
import {
	ITheNextScroll,
} from 'interfaces';

const TheBox = styled.div<IStyles>`
	box-sizing: border-box;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;

	${ ({ styles }) => styles }
`;

const TheNext = styled(Link)<IStyles>`
	box-sizing: border-box;
	position: absolute;
	bottom: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	gap: 4px;
	margin-bottom: 21px;
	cursor: pointer;

	&::after {
		content: '';
		--size: 32px;
		width: var(--size);
		height: var(--size);
		background-image: url('/images/TheNextScroll/scrolldown-1.svg');
		background-position: center center;
		background-origin: border-box;
		background-repeat: no-repeat;
		overflow: hidden;
		flex-grow: 0;
		flex-shrink: 0;
	}

	${ ({ styles }) => styles }
`;

const TheMessage = styled.div<IStyles>`
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;

	${ ({ styles }) => styles }
`;

export function TheNextScroll(
	{
		children: msg,
		href,
		styles,
	}: ITheNextScroll,
): React.ReactElement {
	const onClick = React.useMemo(() => {
		return (event: React.MouseEvent) => {
			const id = /^#.*/.test(href) ? href.slice(1) : '';
			const element = id ? document.getElementById(id) : null;
			if (id && element) {
				event.preventDefault();
				const top = element.offsetTop;
				location.replace(new URL(href, location.href));
				window.scrollTo({ top, behavior: 'smooth', });
			}
		};
	}, [ href, ]);

	return (
		<TheBox>
			<TheNext href={ href } styles={ styles } onClick={ onClick } >
				{ msg ? (<TheMessage>{ msg }</TheMessage>) : null }
			</TheNext>
		</TheBox>
	);
}