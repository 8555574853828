import React, { useState } from "react";
import styled, { css } from "styled-components";
import { IStyles, Ident } from "components";
import {
  IDtoLeaderboard,
  IDtoLeaderboardUser,
  LeaderboardPrizeSelectionType,
  TheLeaderboardSourceKeys,
} from "interfaces";
import { DEFAULT_AVATAR } from "helpers/constants";
import { formatNumber } from "./utils/formatNumbers";
import NumberShape from "/public/images/icons/number-shape.svg";
import CustomShapeCardSvg from "./CustomShapeCardSvg";
import Gift from "/public/images/icons/gift.svg";
import CurrencyNumbers from "components/wrewards-ui-kit/CurrencyNumbers";
import LeaderboardRaffleVerifyModal from "./LeaderboardRaffleVerifyModal";
import WrewardsButton from "components/wrewards-ui-kit/Button";
import ShieldIcon from "assets/games/icons/shield-2.svg";

export interface ILeaderboardWinnerCard {
  leaderboard: IDtoLeaderboard;
  leaderboardUser: IDtoLeaderboardUser;
  place: number;
  customWinnerRewardText?: React.ReactNode;
}

export const placeMap = {
  "1": "I",
  "2": "II",
  "3": "III",
  "4": "IV",
  "5": "V",
  "6": "VI",
  "7": "VII",
  "8": "VIII",
  "9": "IX",
  "10": "X",
};

const getValueTitle = (provider: string): string | null => {
  switch (provider) {
    case TheLeaderboardSourceKeys.gamdom:
    case TheLeaderboardSourceKeys.packdraw:
    case TheLeaderboardSourceKeys.clash:
    case TheLeaderboardSourceKeys.cases:
    case TheLeaderboardSourceKeys.rain_gg:
    case TheLeaderboardSourceKeys.luxdrop:
      return "Wagered";
    case TheLeaderboardSourceKeys.hypedrop:
      return "Deposited";
    case TheLeaderboardSourceKeys.wrewards:
      return "Total Balance";
    default:
      return null;
  }
};

const getValue = (provider: string, user: IDtoLeaderboardUser) => {
  switch (provider) {
    case TheLeaderboardSourceKeys.gamdom:
    case TheLeaderboardSourceKeys.packdraw:
    case TheLeaderboardSourceKeys.clash:
    case TheLeaderboardSourceKeys.cases:
    case TheLeaderboardSourceKeys.rain_gg:
    case TheLeaderboardSourceKeys.luxdrop:
      return (
        <CurrencyNumbers icon="USD">
          {formatNumber(user?.wageredTotal ? +user.wageredTotal : 0)}
        </CurrencyNumbers>
      );
    case TheLeaderboardSourceKeys.wrewards:
      return (
        <CurrencyNumbers icon="GCoin">
          {formatNumber(user?.wageredTotal ? +user.wageredTotal : 0)}
        </CurrencyNumbers>
      );
    case TheLeaderboardSourceKeys.hypedrop:
      return (
        <CurrencyNumbers icon="USD">
          {formatNumber(user?.deposited ? +user.deposited : 0)}
        </CurrencyNumbers>
      );
    default:
      return 0;
  }
};

export const LeaderboardWinnerCard: React.FC<ILeaderboardWinnerCard> = ({
  leaderboard,
  leaderboardUser,
  place = 1,
  customWinnerRewardText,
}): React.ReactElement => {
  const [isFairOpen, setIsFairOpen] = useState(false);
  // const avatar = leaderboardUser?.avatar || DEFAULT_AVATAR;
  const showTickets = leaderboard.prizeSelectionType === LeaderboardPrizeSelectionType.RAFFLE;
  const ticketPerDollar = leaderboard.ticketRate;
  const displayName = leaderboardUser?.displayName ?? "Xxxxxx";

  const value = getValue(leaderboard.casinoProvider, leaderboardUser);

  const valueTitle = getValueTitle(leaderboard.casinoProvider);

  // @ts-ignore
  const reward = formatNumber(+leaderboard?.[`prize${place}`] ?? 0);

  const avatarSize = 104;

  const calculatedTickets =
    ticketPerDollar && value
      ? Math.floor(Number(leaderboardUser.wageredTotal) / ticketPerDollar)
      : Math.floor(Number(leaderboardUser));

  return (
    <Box>
      <Card
        place={place}
        className={`place-${place} ${showTickets ? "tickets" : ""}`}
        avatarSize={avatarSize}
      >
        <div style={{ position: "absolute", inset: 0 }}>
          <CustomShapeCardSvg seed={`${place}`} />
        </div>
        {/* <AnimatedBackground place={place} /> */}

        <AvatarFrame avatarSize={avatarSize} place={place}>
          <Avatar avatarSize={avatarSize}>
            <AvatarPicture
              src={leaderboardUser.avatar ?? DEFAULT_AVATAR}
              alt="avatar"
              width={110}
              height={110}
            />
          </Avatar>
          <div className="place">
            <NumberShape />
            {/* @ts-ignore */}
            <span className="text">{placeMap?.[place] ?? place}</span>
          </div>
        </AvatarFrame>
        <div className="content" style={{ position: "relative" }}>
          <Name place={place}>{displayName}</Name>

          {showTickets && (
            <>
              <Text>Tickets</Text>
              <Value className="tickets">
                <CurrencyNumbers icon="Raffle">{formatNumber(calculatedTickets)} </CurrencyNumbers>
              </Value>
            </>
          )}

          <Text>{valueTitle}</Text>
          <Value data-first>{value}</Value>
        </div>

        <Ident height={10} />
        <div className="content  absolute-bottom">
          <Text>Reward</Text>
          <Reward>
            <span className="icon-gift">
              <Gift />
            </span>
            {customWinnerRewardText ? (
              <div className="custom-reward">{customWinnerRewardText}</div>
            ) : (
              <span>${reward}</span>
            )}
            {leaderboardUser.proof ? (
              <WrewardsButton variant="primary-icon-bordered" onClick={() => setIsFairOpen(true)}>
                <ShieldIcon />
              </WrewardsButton>
            ) : null}
          </Reward>
        </div>
      </Card>
      <LeaderboardRaffleVerifyModal
        isOpen={isFairOpen}
        proof={leaderboardUser.proof}
        onClose={() => setIsFairOpen(false)}
      />
    </Box>
  );
};

const Box = styled.div<IStyles>`
  ${({ styles }) => styles}
`;

const Card = styled.div<IStyles & { place?: number; avatarSize: number }>`
  --size: ${({ avatarSize }) => avatarSize ?? 104}px;
  --fill-shape-color: #141722;
  box-sizing: border-box;
  width: 336px;
  margin-top: calc((var(--size) + 5px * 2) / 2);
  text-align: center;
  position: relative;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 200px;
  /* animation: pulse 4s infinite; */

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
  }

  .absolute-bottom {
    position: absolute;
    bottom: 0px;
  }

  &.tickets {
    .absolute-bottom {
      position: absolute;
      bottom: 80px;
    }
  }

  &.place-1 {
    animation: 4s ease-in-out 0s infinite normal none running float;

    /* animation: pulse 3s infinite 0s; */
    /* transform: translateY(-50px); */
    top: -50px;

    --border-g-start: #b48b23;
    --border-g-center: #ffda7a;
    --border-g-end: #725a05;

    --border-main-color: #e2c056;
  }
  &.place-2 {
    animation: 4s ease-in-out 0.5s infinite normal none running float;
    top: -25px;
    /* animation: pulse 3s infinite 0.5s; */
    --border-g-start: #f8f8f8;
    --border-g-center: #cdcdcd;
    --border-g-end: #595959;

    --border-main-color: #c6cad8;
  }
  &.place-3 {
    animation: 4s ease-in-out 1s infinite normal none running float;

    /* animation: pulse 3s infinite 1s; */
    --border-g-start: #785d45;
    --border-g-center: #d5ae8c;
    --border-g-end: #4c422e;

    --border-main-color: #a1866d;
  }
`;

const Text = styled.div`
  position: relative;
  box-sizing: border-box;

  color: var(--daylight-daylight-half, rgba(200, 215, 245, 0.5));
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 24px;
  text-align: center;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
`;

const Name = styled.div<{ place?: number }>`
  position: relative;
  box-sizing: border-box;
  color: #d2eaff;
  font-family: var(--font-family-exo);
  font-size: 32px;
  font-style: italic;
  font-weight: 800;
  /* letter-spacing: 0.02em; */
  /* line-height: 38px; */
  word-wrap: break-word;
  word-break: break-all;
  text-align: center;
  text-shadow: 0px 5px 6px rgba(0, 0, 0, 0.55);
  text-transform: uppercase;

  // truncate
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0px 20px;
  width: 100%;
  /* ${({ place }) =>
    place === 1
      ? css`
          font-size: 48px;
          line-height: 56px;
        `
      : null} */
`;

const Value = styled.div<IStyles>`
  position: relative;
  color: var(--daylight-daylight-main, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 16px;
  font-style: italic;
  font-weight: 800;
  text-align: center;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 8px 12px;
  background: var(--daylight-daylight-10, rgba(200, 215, 245, 0.1));
  backdrop-filter: blur(20px);
  border-radius: 48px;
  min-width: 160px;
  display: flex;
  justify-content: center;
  /* animation: pulse 3s infinite 0.5s; */

  & span {
    font-size: 20px;
    /* color: #5ebd3d; */
  }

  &.tickets span {
    font-size: 20px;
    color: #f2c94c;
  }
  ${({ styles }) => styles}
`;

const AvatarFrame = styled.div<IStyles & { avatarSize: number; place?: number }>`
  --size: ${({ avatarSize }) => avatarSize ?? 104}px;
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  /* background-image: linear-gradient(219.74deg, #b48b23 -4.97%, #ffda7a 47.59%, #725a05 93.01%); */
  /* width: calc(var(--size) + 2px * 2);
  height: calc(var(--size) + 2px * 2); */
  border-radius: calc((var(--size) + 2px * 2) / 2);
  /* box-shadow: 0px 0px 0px 4px #141721; */
  position: relative;
  filter: drop-shadow(0px 8px 10px #000000);
  /* animation: filter 2s ease-in-out infinite; */

  .place {
    width: 46px;
    aspect-ratio: 1/1;
    position: absolute;
    bottom: -18px;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(2px 9px 13px #000000);

    svg {
      color: var(--place-border);
      width: 100%;
      height: 100%;
      position: absolute;
      inset: 0;
    }
  }

  /* @keyframes filter {
    0% {
      filter: drop-shadow(0px 8px 10px #000000);
    }

    50% {
      filter: drop-shadow(0px 4px 4px #000000);
    }

    100% {
      filter: drop-shadow(0px 8px 10px #000000);
    }
  } */

  .text {
    width: 100%;
    height: 100%;
    /* background-color: var(--fallback); */
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-style: italic; */
    font-family: var(--font-family-exo);
    font-weight: 900;
    font-size: 18px;
    opacity: 1;
    /* box-shadow: 0px 0px 1px 3px #141721 inset; */
    border-radius: calc((var(--size) + 2px * 2) / 2);
    z-index: 1;
    font-style: italic;
    line-height: normal;
    background: linear-gradient(180deg, #e9f0ff 0%, #bdccfa 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  &::before {
    content: "";
    border-radius: inherit;
    position: absolute;
    inset: 0;
    /* box-shadow: 0px 0px 20px 6px var(--drop-shadow); */
  }

  ${({ place }) => {
    switch (place) {
      case 1:
        return css`
          --drop-shadow: #171a28;
          --fallback: #987b34;
          --place-border: #e2c056;
          transform: translateY(-50%);
          background: linear-gradient(
            25deg,
            rgba(224, 151, 40, 0.2) 0%,
            rgba(244, 218, 79, 0.9) 30%,
            rgba(255, 236, 135, 1) 50%,
            rgba(244, 218, 79, 0.9) 70%,
            rgba(235, 181, 42, 1) 100%
          );
        `;
      case 2:
        return css`
          --drop-shadow: rgba(79, 79, 79, 0.3);
          --fallback: #1c1c22;
          --place-border: #c6cad8;
          transform: translateY(-50%);

          background: linear-gradient(
            205deg,
            rgba(195, 195, 195, 0.93) 0%,
            rgba(255, 255, 255, 0.9) 30%,
            rgba(255, 255, 255, 0.91) 50%,
            rgba(255, 255, 255, 0.93) 70%,
            rgba(98, 98, 98, 1) 100%
          );
        `;
      case 3:
        return css`
          --drop-shadow: rgba(250, 210, 187, 0.1);
          --fallback: #0c1617;
          --place-border: #e0ab7e;
          transform: translateY(-50%);
          background: linear-gradient(
            75deg,
            #583831 0%,
            #735c48 30%,
            #ab8a6c 50%,
            #a08064 70%,
            #544436 100%
          );
        `;

      default:
        return css`
          transform: translateY(-50%);
        `;
    }
  }}
`;

const Avatar = styled.div<IStyles & { avatarSize: number }>`
  --size: ${({ avatarSize }) => avatarSize ?? 104}px;
  box-sizing: border-box;
  position: relevant;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  margin: 3px;
  overflow: hidden;
  /* background-color: white; */
  /* border: 4px solid rgba(16, 17, 25, 1); */
  border-radius: calc(var(--size) / 2);
  ${({ styles }) => styles}
`;

const AvatarPicture = styled.img<IStyles>``;

const Reward = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 80%;
  width: 100%;
  min-height: 68px;
  border-radius: 74px;
  border: 2px solid var(--border-main-color);
  padding: 16px;
  gap: 12px;
  background: linear-gradient(
    135deg,
    rgba(208, 219, 255, 0) -21.86%,
    rgba(209, 219, 255, 0.08) 32.26%,
    rgba(209, 219, 255, 0.19) 45.78%,
    rgba(215, 224, 255, 0.08) 59.31%,
    rgba(222, 231, 255, 0) 113.42%
  );
  box-shadow: 0px 0px 0px 5px #171a28 inset;
  backdrop-filter: blur(24px);
  font-family: var(--font-family-exo);
  font-size: 32px;
  font-style: italic;
  font-weight: 800;
  color: #d2eaff;

  text-transform: uppercase;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);

  .icon-gift {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    background-color: #131620;
    border: 2px solid var(--border-main-color);
    border-radius: 60px;
  }

  .custom-reward {
    z-index: 1;
  }

  & > span {
    z-index: 1;
    &::first-letter {
      /* color: #5ebd3d; */
    }
  }
`;
