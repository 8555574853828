import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "contexts";
import { NotificationUIModelExtended } from "types/notification";

export function useGetActiveNotificationList() {
  const { apiService } = useAppContext();
  return useQuery<NotificationUIModelExtended[]>({
    queryKey: ["active-notification-list"],
    queryFn: async () => {
      try {
        const response = await apiService.request("/notification/active-notifications", {
          method: "GET",
        });

        const data = await response?.json();

        if (!response?.ok || !data) {
          return Promise.reject(data);
        }

        return data ?? [];
      } catch (error) {
        return [];
      }
    },
    retry: false,
    initialData: [],
    enabled: false,
  });
}
