import WrewardsButton from "components/wrewards-ui-kit/Button";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import Play from "assets/notifications/play.svg";
import StreamerLive from "assets/notifications/streamer-live.svg";
import { NotificationCardProps, StreamPlatforms } from "types/notification";
import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";
import { StreamUrls } from "types/notification/constants";

const StreamLiveNotificationCard = (props: NotificationCardProps) => {
  const router = useRouter();
  const { setShowChatSidebar } = useShowPageLayoutContext();

  const { title, info, onActionButtonClick, containerStyles } = props;

  const titleParts = useMemo(() => {
    const parts = title?.split(" ");
    let first = parts ? parts[0] : "";
    let second = parts ? parts.slice(1).join(" ") : "";

    return [first, second];
  }, [title]);

  const onActionButtonClickInner = useCallback(() => {
    if (info.platform && info.streamer) {
      const url = StreamUrls[info.platform];
      onActionButtonClick && onActionButtonClick();
      if (url) {
        router.push(`${url}${info.streamer}`);
        setShowChatSidebar(false);
      }
    }
  }, [info.platform, info.streamer, onActionButtonClick, router, setShowChatSidebar]);

  return (
    <NotificationCardContainer styles={containerStyles}>
      <NotificationCardHeader>
        {StreamerLive()}
        <NotificationTitleContainer>
          <span>{titleParts[0]}</span>
          <span>{titleParts[1]}</span>
        </NotificationTitleContainer>
      </NotificationCardHeader>
      <NotificationCardContent>
        <StreamerName>{info.streamer}</StreamerName>
        <ListItem>
          <ListItemName>Platform:</ListItemName>
          <ListItemContent>{info.platform}</ListItemContent>
        </ListItem>
        <ListItem>
          <ListItemName>Started:</ListItemName>
          <ListItemContent>{info.startedAt} </ListItemContent>
        </ListItem>
      </NotificationCardContent>
      <WrewardsButton
        onClick={onActionButtonClickInner}
        variant="game-action-blue"
        style={{ fontSize: "12px", width: "100%", padding: "8px" }}
      >
        {Play()}
        {info.actionButtonText}
      </WrewardsButton>
    </NotificationCardContainer>
  );
};
export default StreamLiveNotificationCard;

const StreamerName = styled.div`
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 600;
  color: var(--daylight-color);
`;
const ListItem = styled.div`
  display: flex;
  gap: 4px;
  align-items: end;
`;

const ListItemName = styled.div`
  font-family: var(--font-family-golos);
  font-size: 12px;
  font-weight: 500;
  color: var(--daylight-50-color);
`;

const ListItemContent = styled.div`
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-weight: 500;
  color: var(--daylight-color);
  white-space: nowrap;
`;

const NotificationCardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #141722bf;
  padding: 16px;
  position: relative;
  gap: 8px;
  border-radius: 8px;
  flex-grow: 1;
`;

const NotificationCardContainer = styled.div<{ styles?: FlattenSimpleInterpolation }>`
  background: radial-gradient(
    90% 93.57% at 0% 100%,
    rgba(126, 132, 201, 0.45) 20%,
    rgba(66, 73, 145, 0.3375) 90%,
    rgba(57, 68, 125, 0) 200%
  );
  gap: 16px;
  min-height: 250px;
  height: 250px;

  min-width: 230px;
  width: 100%;

  position: relative;
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  ${({ styles }) =>
    styles &&
    css`
      ${styles}
    `}
`;

const NotificationCardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const NotificationTitleContainer = styled.div`
  font-family: var(--font-family-exo);
  font-size: 16px;
  font-style: italic;
  font-weight: 800;
  line-height: 20.8px;
  text-align: left;
  text-transform: uppercase;
  & > :nth-child(1) {
    margin-right: 5px;
    color: var(--daylight-color);
  }
  & > :nth-child(2) {
    color: var(--bloody-mary);
  }
`;
