import { IUserDto } from "interfaces";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAppContext } from "contexts";
import router from "next/router";
import { useMemo } from "react";

export default function useCurrentUser() {
  const { apiService } = useAppContext();

  return useQuery<IUserDto | null>({
    queryKey: ["user/me"],
    queryFn: async () => {
      try {
        const response = await apiService.request("/user/me?withStats=true&withCrypto=true");

        const data = await response?.json();

        if (!response?.ok || !data.user) {
          return Promise.reject(data);
        }

        return data.user;
      } catch (error) {
        return null;
      }
    },
    retry: false,
    initialData: null,
    structuralSharing: (oldData, newData) => newData,
    notifyOnChangeProps: ["data", "isFetching", "refetch"],
  });
}

export function useUserClientActions() {
  const queryClient = useQueryClient();
  const { apiService, profile, accessURL, isProfileLoading } = useAppContext();

  const login = async () => {
    try {
      const url = accessURL(`/auth/twitch`) as URL;
      router.push(url.href);
    } catch (error) {
      console.log({ error });
    }
  };
  const isLoadingUserInfo = useMemo(
    () => apiService.getCanRefresh() && !profile && isProfileLoading,
    [apiService, isProfileLoading, profile]
  );
  const isNotLoggedIn = useMemo(() => !isLoadingUserInfo && !profile, [isLoadingUserInfo, profile]);

  const logout = () => {
    queryClient.setQueryData<IUserDto | null>(["user/me"], null);
    apiService.setCanRefresh(false);
  };

  return { login, logout, isLoadingUserInfo, isNotLoggedIn };
}
