import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import styled from 'styled-components';
import { Flex } from 'components/common';
import { Img, useMediaQuery } from '@chakra-ui/react';
import Image from "next/image";
import { TheButton } from 'components/TheComponents';
import { ItemProps } from '../types';
import { formatNumber } from 'components/pachinko/Prizes';

const Slide = styled(Flex)`
    overflow: hidden;
    position: relative;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    background: #151C2C;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
    width: 100%;
    height: 90%;
    min-height: 90%;

    @media (min-width: 768px) {
        flex-direction: row;
        align-items: start;
    }
`;

const SlideImgs = styled(Flex)`
    align-items: center;
    justify-content: center;
    flex: 1 0;
    position: relative;
    height: 250px;
    max-height: 250px;

    @media (min-width: 768px) {
       position: absolute;
       top: 0;
       right: 5%;
    }

    @media (min-width: 1024px) {
        height: 100%;
        max-height: 100%;
        right: 10%;
    }

    @media (min-width: 1400px) and (max-width: 1919px) {
        height: 250px;
        max-height: 250px;
    }
`;

const BgImg = styled(Img)`
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    animation: fadeInOut 3s linear infinite;
    object-position: center;
    mix-blend-mode: lighten;
    height: 100%;
    max-height: 100%;
    max-width: unset;
    transform: translate(-50%, -55%) scale(1.5);
    height: 100%;

    @keyframes fadeInOut {
        0% {
            opacity: 0.4;
        }
        50% {
            opacity: 1;
        }
        100% {
            opacity: 0.4;
        }
    }
`;

const SlideImg = styled(Img)`
    z-index: 1;
    height: 100%;
    max-width: unset;
    min-width: 250px;
    max-height: 250px;
    min-height: 250px;

    @media (min-width: 1024px) {
        max-height: 100%;
    }

    @media (min-width: 1920px) {
        min-width: 320px;
    }
`;

const SlideContent = styled(Flex)`
    z-index: 3;
    flex: 1 0;
    flex-direction: column;
    padding: 20px;
    width: 100%;

    @media (min-width: 768px) {
        height: 100%;
        max-width: 60%;
        padding: 20px 0 20px 20px;
    }

    @media (min-width: 1920px) {
        padding: 48px 0 48px 48px;
    }
`;

const Title = styled.h2`
    color: #D5E0F5;
    text-align: center;
    font-family: Exo;
    font-size: 24px;
    font-style: italic;
    font-weight: 800;
    text-transform: uppercase;
    margin: 0;
    width: 100%;

    @media (min-width: 768px) {
        text-align: left;
    }

    @media (min-width: 1024px) {
        font-size: 32px;
    }

    @media (min-width: 1400px) and (max-width: 1919px) {
        font-size: 24px;
    }
`;

const TitleSecondary = styled.h3`
    margin: 12px 0 0 0;
    color: var(--pure-sky);
    text-align: center;
    font-family: Exo;
    font-size: 40px;
    font-style: italic;
    font-weight: 900;
    line-height: 116.754%; /* 46.702px */
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 768px) {
        text-align: left;
        font-size: 32px;
    }

    @media (min-width: 1024px) {  
        font-size: 48px;
        margin: 20px 0 0 0;

    }

    @media (min-width: 1400px) and (max-width: 1919px) {
        font-size: 40px;
    }

    @media (min-width: 1920px) {  
        font-size: 48px;
    }
`;

const TitleTertiary = styled.h4`
    margin: 24px 0 0 0;
    color:rgba(200, 215, 245, 0.75);
    text-align: center;
    font-family: "Golos Text";
    font-size: 16px;
    font-weight: 600;
    line-height: 150%; /* 24px */
    text-transform: uppercase;
    width: 100%;

    @media (min-width: 768px) {
        text-align: left;
    }

    @media (min-width: 1920px) {
        margin: 32px 0 0 0;
    }
`;

const BtnsBlock = styled(Flex)`
    width: 100%;
    flex-direction: column;
    gap: 8px;
    margin-top: 24px;

    @media (min-width: 620px) {
        flex-direction: row;

    }

    @media (min-width: 768px) {
        gap: 16px;
    }

    @media (min-width: 1024px) {
        padding-top: 24px;
        margin-top: auto;
    }
`;

const Button = styled.div`
    width: 100%;
    height: 46px;
    font-size: 14px;

    @media (min-width: 768px) {
        width: max-content;
    }

    @media (min-width: 1400px) and (max-width: 1919px) {
        height: 40px;
    }
`;

interface CarouselProps {
    scrollToTarget: () => void;
    onSelectItem: (item: any) => void;
    items: ItemProps[];
}

const Carousel: React.FC<CarouselProps> = ({ scrollToTarget, onSelectItem, items }) => {
    const isMobile = useMediaQuery('(max-width: 767px)')[0];

    return (
        <Swiper
            style={{
                width: '100%',
                marginTop: isMobile ? "24px" : "0"
            }}
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            pagination={{
                clickable: true,
            }}
            navigation={false}
            modules={[Pagination, Navigation]}
        >
            {items?.map((item, index) => (
                <SwiperSlide key={index}>
                    <Slide>
                        <SlideImgs>
                            {item?.imageUrl && (
                                <>
                                    {!isMobile && (
                                        <BgImg mg src="/images/PointShopComponent/fire-ring.png" alt="Img" />
                                    )}
                                    <SlideImg src={item.imageUrl} alt="Img" />
                                </>
                            )}
                        </SlideImgs>

                        <SlideContent>
                            <Title>Become the owner of the</Title>

                            <TitleSecondary>{item?.title}</TitleSecondary>

                            <TitleTertiary>Get points and exchange them for <span style={{ color: "var(--pure-sky)" }}>items in our shop</span></TitleTertiary>

                            <BtnsBlock>
                                <Button style={{ fontFamily: "Golos Text" }}>
                                    <TheButton
                                        type="button"
                                        preset="dark-blue"
                                        height={"100%"}
                                        width={isMobile ? '100%' : "max-content"}
                                        onClick={(e) => { e.preventDefault(); scrollToTarget() }}
                                    >
                                        <Image src="/images/PointShopComponent/coin_2.svg" height={21} width={20} style={{ flexShrink: 0, marginRight: "8px" }} alt="image" />

                                        How to get W-Points
                                    </TheButton>
                                </Button>

                                <Button style={{ color: "#131635", fontFamily: "var(--font-family-golos)" }}>
                                    <TheButton
                                        type="button"
                                        preset="blue"
                                        height={"100%"}
                                        onClick={(e) => { e.preventDefault(); onSelectItem(item) }}
                                        width={isMobile ? '100%' : "max-content"}
                                    >
                                        Buy item for <CarouselBtnText>{formatNumber(item?.price)}</CarouselBtnText>
                                    </TheButton>
                                </Button>
                            </ BtnsBlock>
                        </SlideContent>
                    </Slide>
                </SwiperSlide>
            ))}
        </Swiper>
    );
};

export default Carousel

const CarouselBtnText = styled.span`
    font-weight: 700;
    font-size: 16px;
`