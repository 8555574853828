import React from "react";
import { IStyles, ITheFieldset, TheFieldset } from "components";

export type TheFieldsetWithInputProps = {
  inputKey: string;
  title?: string;
  placeholder?: string;
  type?: "text" | "number";
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  errors?: Record<string, string>;
} & IStyles &
  ITheFieldset;

export const TheFieldsetWithInput: React.FC<TheFieldsetWithInputProps> = ({
  inputKey,
  value,
  onChange,
  errors,
  ...props
}): React.ReactElement => {
  const numProps =
    props.type === "number"
      ? {
          pattern: "^[d]*$",
        }
      : {};

  return (
    <TheFieldset {...props} valid={!errors?.[inputKey]}>
      <input
        type="text"
        placeholder={errors?.[inputKey] ?? props.placeholder}
        value={value}
        onChange={onChange}
        {...numProps}
      />
    </TheFieldset>
  );
};

export default TheFieldsetWithInput;
