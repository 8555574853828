import React from "react";
import styled, { css } from "styled-components";
import { $styles, IStyles, ITheTableCell, TheTable } from "components";
import * as luxon from "luxon";
import { DualRingInner, DualRingLoader, Flex } from "components/common";
import Image from "next/image";
import { PointsTransaction } from "./PointsShopTab";
import { TRANSACTION_STATUS } from "helpers/constants";

export type PointsShopTableOptions = {};

export interface ProfileRafflesTableProps {
  $keys?: string[];
  items?: PointsTransaction[];
  skip: number;
  take: number;
  isLoading: boolean;
}

export const ProfilePointsShopTable: React.FC<ProfileRafflesTableProps> = ({
  $keys = ["id", "name", "price", "status", "createAt"],
  items,
  isLoading,
}): React.ReactElement | null => {
  const alignLeft = css`
    text-align: left;
  `;
  const setup = React.useMemo(
    () => [
      {
        $key: "id",
        render: (props: ITheTableCell<PointsTransaction, PointsShopTableOptions>) => {
          const id: number | undefined = props.cell;
          return <Prize style={{ color: "#A1B4C8" }}>{id}</Prize>;
        },
        title: (
          <Title>
            <Prize>ID</Prize>
          </Title>
        ),
      },
      {
        $key: "name",
        render: (props: ITheTableCell<PointsTransaction, PointsShopTableOptions>) => {
          const item: PointsTransaction | null = props.row;
          return (
            <Prize styles={alignLeft} style={{ color: "#A1B4C8" }}>
              {item?.pointsShopItem?.title}
            </Prize>
          );
        },
        title: (
          <Title>
            <Prize styles={alignLeft}>Item Name</Prize>
          </Title>
        ),
      },
      {
        $key: "price",
        render: (props: ITheTableCell<PointsTransaction, PointsShopTableOptions>) => {
          const item: PointsTransaction | null = props.row;
          return (
            <Flex width="100%" justify="flex-start">
              <Image src="/images/PointShopComponent/coin.png" height={30} width={30} alt="image" />
              <Prize style={{ color: "#5EBD3D", marginLeft: 6 }}>
                {item?.pointsShopItem?.price ?? 0}
              </Prize>
            </Flex>
          );
        },
        title: <Title styles={alignLeft}>Price</Title>,
      },
      {
        $key: "status",
        render: (props: ITheTableCell<PointsTransaction, PointsShopTableOptions>) => {
          const status: string | undefined = props.cell;
          return (
            <Title
              styles={alignLeft}
              style={{
                color:
                  status === TRANSACTION_STATUS.APPROVED
                    ? "#448237"
                    : status === TRANSACTION_STATUS.REJECTED
                    ? "#9C392F"
                    : status === TRANSACTION_STATUS.PENDING
                    ? "#3B8BE9"
                    : "white",
              }}
            >
              {status}
            </Title>
          );
        },
        title: <Title styles={alignLeft}>Status</Title>,
      },
      {
        $key: "createdAt",
        render: (props: ITheTableCell<PointsTransaction, PointsShopTableOptions>) => {
          const endDate: string = props.cell;
          return (
            <Prize styles={alignLeft} style={{ color: "#A1B4C8" }}>
              {luxon.DateTime.fromISO(endDate).toFormat("dd.MM.yyyy")}
            </Prize>
          );
        },
        title: (
          <Title>
            <Prize styles={alignLeft}>Completed</Prize>
          </Title>
        ),
      },
    ],
    [alignLeft]
  );

  const rowProps = ({ row }: { row: PointsTransaction | null }) => {
    if (!row) {
      return {};
    }
    // const routerFunction = () => router.push(`/admin/raffles/${row?.id}`);
    // return {
    //   onClick: routerFunction,
    //   styles: css`
    //     cursor: pointer;
    //   `,
    // };
    return {};
  };

  return (
    <Box>
      {isLoading ? (
        <Flex align="center" justify="center" padding="60px 0px">
          <DualRingLoader>
            <DualRingInner />
          </DualRingLoader>
        </Flex>
      ) : (
        <div style={{ overflowX: "auto" }}>
          <TheTable<PointsTransaction, PointsShopTableOptions>
            RowProps={rowProps}
            $keys={$keys}
            $setup={setup}
            rows={items ?? []}
            boxStyles={css`
              margin: 0px !important;
            `}
            styles={css`
              border-spacing: 0 5px;
            `}
          />
        </div>
      )}
    </Box>
  );
};

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  text-align: left;
  ${$styles}
`;

const Title = styled.div`
  color: rgba(210, 234, 255, 0.75);
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  ${$styles}
`;

const Prize = styled.div<IStyles>`
  font-family: "Rubik";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  text-align: center;
  &[data-value] {
    color: #5ebd3d;
  }
  &[data-value]::before {
    content: "$";
  }
  ${$styles}
`;
