import React from "react";
import * as luxon from "luxon";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { ITheDatePicker } from "interfaces";
import "react-datepicker/dist/react-datepicker.css";

export function TheDatePicker({
  value,
  onChange,
  ...props
}: ITheDatePicker & Omit<ReactDatePickerProps, "value" | "onChange">): React.ReactElement {
  const { readOnly } = props;
  const [isOpen, setIsOpen] = React.useState(false);
  const cast1 = (value: string | null): Date | null => {
    const date = value ? luxon.DateTime.fromISO(value).toLocal() : null;
    return date?.isValid ? date.toJSDate() : null;
  };

  const cast2 = (value: Date | null): string | null => {
    const date = value ? luxon.DateTime.fromJSDate(value) : null;
    return date?.isValid ? date.toISO() : null;
  };

  return (
    <div style={{ zIndex: 100 }}>
      <DatePicker
        open={isOpen}
        onInputClick={() => {
          if (!readOnly) {
            setIsOpen(true);
          }
        }}
        onClickOutside={() => setIsOpen(false)}
        dateFormat="dd/MM/yyyy" // "MMMM d, yyyy h:mm aa"
        locale="en"
        selected={cast1(value)}
        onChange={(value: Date | null) => onChange && onChange(cast2(value))}
        {...props}
      />
    </div>
  );
}
