import React from "react";
import styled, { css } from "styled-components";
import Image from "next/image";
import { useMediaQuery } from "react-responsive";
import { TheModalBox } from "components";
import { $styles, Button, ButtonBlue, Flex, IStyles } from "components/common";

interface GamdomGuideModalModalProps {
  onClose: () => void;
}

const GamdomGuideModal: React.FC<GamdomGuideModalModalProps> = ({
  onClose,
}): React.ReactElement => {
  const isMobile = useMediaQuery({
    query: "(max-width: 750px)",
  });
  const styles = css`
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 20px;
    padding-top: 0px;
    box-sizing: border-box;
    height: 575px;
    @media (min-width: 601px) {
      width: 555px;
    }
    @media (min-width: 750px) {
      width: 735px;
    }
    @media (max-width: 600px) {
      width: 455px;
    }
    @media (max-width: 450px) {
      width: 355px;
    }
  `;

  const modalBoxProps = {
    hasBlur: true,
    isFixed: true,
    hasBorder: true,
    showClose: true,
    onClose: () => onClose && onClose(),
    styles,
  };

  //

  return (
    <TheModalBox {...modalBoxProps}>
      <Flex justify="center" column>
        <Flex style={{ marginBottom: 20, marginLeft: -10 }} align="center" justify="start">
          <Image src="/images/TheModalBox/gamdom-big.png" width={50} height={59} alt="Gamdom" />
          <Title>FIND YOUR GAMDOM USER ID</Title>
        </Flex>
        <AdaptiveFlex column>
          <Text>
            1. On Gamdom click on the{" "}
            <a target="_blank" style={{ color: "#316fb8" }} href="https://gamdom.com/help/support">
              &quot;Support&quot;
            </a>{" "}
            tab
          </Text>
          {isMobile ? null : (
            <Image src="/images/TheModalBox/gamdom-1.png" width={600} height={88} alt="Gamdom" />
          )}
        </AdaptiveFlex>
        <Flex style={{ marginBottom: 20 }} column>
          <Text>2. You will find your User ID bottom left of the page.</Text>
          <Flex maxWidth={600} overflow>
            <Image
              src={
                isMobile
                  ? "/images/TheModalBox/gamdom-mobile.png"
                  : "/images/TheModalBox/cropped-gamdom.png"
              }
              width={isMobile ? 315 : 777}
              height={isMobile ? 400 : 590}
              alt="Gamdom"
            />
          </Flex>
        </Flex>
        <Flex style={{ marginTop: 15 }} width="100%" justify="space-between">
          <BoxItem
            styles={css`
              margin-top: 8px;
            `}
          >
            <Button
              onClick={() => onClose()}
              styles={css`
                cursor: pointer;
              `}
              preset="blue"
              isDark
            >
              <ButtonBlue isDark>Close</ButtonBlue>
            </Button>
          </BoxItem>
        </Flex>
      </Flex>
    </TheModalBox>
  );
};

export default GamdomGuideModal;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;
const AdaptiveFlex = styled(Flex)<IStyles>`
  margin-bottom: 20px;
  @media (max-width: 750px) {
    margin-bottom: 0px;
  }
  ${$styles}
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: var(--font-family-golos);
  font-size: 22px;
  text-align: center;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;

const Text = styled.div<IStyles>`
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  text-align: left;

  ${$styles}
`;
