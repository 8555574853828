import React from 'react';
import styled, { css, } from 'styled-components';
import { TheScreenBox, } from 'components';
import { ICommingSoonComponent, } from 'interfaces';

const cssBox = css`
	position: relative;
`;

const cssScreenBox = css`
`;

const BoxContent = styled.div`
	position: relative;
	margin: calc( 98px + 60px ) auto 183px;
	height: calc( 980px - 98px - 60px - 183px ); // 639px
	font-size: 16px;
	text-align: center;
`;

const BoxH1 = styled.div`
	display: flex;
	justify-content: center;
	margin: 24px 0 24px;
	color: #d2eaff;
	font-size: 40px;
	font-style: italic;
	font-weight: 900;
	letter-spacing: 0.03em;
	line-height: 130%;
	text-transform: uppercase;

	& [data-blue] {
		color: #3b8be9;
	}
`;

export function CommingSoonComponent(
	{
		id,
	}: ICommingSoonComponent,
): React.ReactElement {
	const boxProps = {
		styles: cssBox,
	};

	return (
		<TheScreenBox id={ id } height={ 980 } styles={ cssScreenBox } boxProps={ boxProps }>
			<BoxContent>
				<BoxH1>
					<span>Coming</span>
					&nbsp;
					<span data-blue>soon</span>
					&nbsp;
					<span>...</span>
				</BoxH1>
			</BoxContent>
		</TheScreenBox>
	);
}
