import React, { useEffect } from "react";
import * as luxon from "luxon";
import styled from "styled-components";
import { IStyles, $styles } from "components";
import { Flex } from "components/common";
import Image from "next/image";
import { SubHeader } from "../ItemsTable/ItemsTable";
import { formatNumber } from "components/pachinko/Prizes";
import { IAppContext, withApp } from "contexts";
import TransactionReviewActions from "./TransactionReviewActions";
import { TRANSACTION_STATUS } from "helpers/constants";
import { triggerNotification } from "components/TheComponents/Notification";
import { SelectedTransaction } from "../types";
import { useProfileContext } from "components/public/ProfileLayout/ProfileContext";
import {
  BalanceTitle,
  PointInfo,
  PointName,
  PointValue,
  BalanceBlock,
  PointBalance,
  Balance,
  Balances,
} from "components/public/ProfileLayout/components/MainInfo/styles";
import InfoSvg from "/public/images/icons/info2.svg";

const Card = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 18px 0px;
  gap: 8px;
  width: 100%;

  background: var(--rainy-morning);
  border-radius: 8px;
`;

export const BorderBottomDiv = styled.div`
  border-bottom: 1px solid #1d2431;
  margin: auto;
  width: 100%;
  height: 100%;
`;

interface TransactionsProps {
  onAction: () => void;
  selectedTransaction: SelectedTransaction;
}

interface ReviewLayoutProps {
  transactionsProps: TransactionsProps;
}

const ReviewModalLayout: React.FC<IAppContext & ReviewLayoutProps> = ({
  accessFetch,
  transactionsProps,
}): React.ReactElement => {
  const { profile: user } = useProfileContext();
  const { selectedTransaction, onAction } = transactionsProps;

  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const actionTransaction = React.useCallback(
    async ({ action, reason }: { action: string; reason: string | undefined }) => {
      setIsLoading(true);
      try {
        const response = await accessFetch(`/point-shop/purchases/${selectedTransaction.id}`, {
          method: "PATCH",

          body: JSON.stringify({
            status: action,
            rejectionReason: action === TRANSACTION_STATUS.REJECTED ? reason : undefined,
          }),
        });
        const data = await response?.json();
        if (response && (response.status === 200 || response.status === 201)) {
          triggerNotification({
            text: `Purchase ${action === TRANSACTION_STATUS.REJECTED ? "Rejected" : "Approved"}`,
            type: "success",
          });
          onAction();
        } else {
          triggerNotification({ text: data?.message, type: "error" });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    },
    [accessFetch, selectedTransaction, onAction]
  );

  const colorByStatus = (status: string) => {
    switch (status) {
      case TRANSACTION_STATUS.PENDING:
        return "#3B8BE9";
      case TRANSACTION_STATUS.APPROVED:
        return "#448237";
      case TRANSACTION_STATUS.REJECTED:
        return "#9C392F";
      default:
        return "white";
    }
  };

  return (
    <Flex
      width="100%"
      flexWrap={true}
      padding="8px"
      gap={8}
      background="var(--global-bg-dark-1)"
      borderRadius="8px"
    >
      <BalanceBlock>
        <BalanceTitle>User Details</BalanceTitle>
        <Balances>
          <Balance>
            <PointBalance>
              <Image src="/images/TheIcon/reset.svg" alt="reset" width={24} height={24} />
              <PointInfo>
                <PointName>Status:</PointName>
                <PointValue> {selectedTransaction.status}</PointValue>
              </PointInfo>
            </PointBalance>
            <InfoSvg className={"info-icon"} alt="info" width={16} height={16} />
          </Balance>
          <Balance>
            <PointBalance>
              <Image
                src="/images/RafflesComponent/raffle-ticket.svg"
                alt="g-coin"
                width={24}
                height={24}
              />
              <PointInfo>
                <PointName>Raffles won:</PointName>
                <PointValue>{user?.rafflesWon}</PointValue>
              </PointInfo>
            </PointBalance>
            <InfoSvg className={"info-icon"} alt="info" width={16} height={16} />
          </Balance>
          <Balance>
            <PointBalance>
              <Image src="/images/coins/w-coin.svg" alt="g-coin" width={24} height={24} />
              <PointInfo>
                <PointName> Points spent:</PointName>
                <PointValue>
                  {" "}
                  {typeof user?.pointsSpent !== "undefined" ? formatNumber(user?.pointsSpent) : "0"}
                </PointValue>
              </PointInfo>
            </PointBalance>
            <InfoSvg className={"info-icon"} alt="info" width={16} height={16} />
          </Balance>
        </Balances>
      </BalanceBlock>

      <ItemInfoContainer>
        <BalanceTitle>Item Details</BalanceTitle>

        <ItemInfo>
          <Image
            src={selectedTransaction.pointsShopItem.imageUrl}
            height={180}
            width={180}
            alt="image"
          />
        </ItemInfo>
        <ItemInfo>
          <PointName>Description</PointName>
          <DescriptionContainer>
            {selectedTransaction?.pointsShopItem?.description}
          </DescriptionContainer>
        </ItemInfo>
        <ItemInfo>
          <PointBalance>
            <PointInfo>
              <PointName> Item created date</PointName>
              <PointValue>
                {luxon.DateTime.fromISO(selectedTransaction?.pointsShopItem?.createdAt).toFormat(
                  "dd.MM.yy"
                )}
              </PointValue>
            </PointInfo>
          </PointBalance>
        </ItemInfo>
        <ItemInfo>
          <PointBalance>
            <PointInfo>
              <PointName> Price</PointName>
              <PointValue> {formatNumber(selectedTransaction.pointsShopItem.price)}</PointValue>
            </PointInfo>
          </PointBalance>
        </ItemInfo>
      </ItemInfoContainer>
      {selectedTransaction.status === TRANSACTION_STATUS.REJECTED && (
        <>
          <Flex column margin="10px 0px 20px" width="100%" padding="0 8px">
            <Title style={{ fontSize: 12, marginBottom: 15, color: "#9C392F" }}>
              Rejection Reason
            </Title>
            <Card style={{ height: 60, border: "none" }}>
              <Flex style={{ marginLeft: 30 }} width="100%" align="center">
                <Flex style={{ marginLeft: 5 }} column>
                  <SubHeader style={{ fontSize: 12, color: "#6C788D", fontWeight: 300 }}>
                    {selectedTransaction.rejectionReason}
                  </SubHeader>
                </Flex>
              </Flex>
            </Card>
          </Flex>
          <TransactionReviewActions
            isLoading={isLoading}
            status={TRANSACTION_STATUS.REJECTED}
            actionTransaction={actionTransaction}
          />
        </>
      )}
      {selectedTransaction.status === TRANSACTION_STATUS.APPROVED && (
        <TransactionReviewActions
          isLoading={isLoading}
          status={TRANSACTION_STATUS.APPROVED}
          actionTransaction={actionTransaction}
        />
      )}
      {selectedTransaction.status === TRANSACTION_STATUS.PENDING && (
        <Flex gap={10} width="100%" justify="space-between">
          <TransactionReviewActions
            isLoading={isLoading}
            status={TRANSACTION_STATUS.APPROVED}
            actionTransaction={actionTransaction}
            style={{ marginRight: 10 }}
          />
          <TransactionReviewActions
            isLoading={isLoading}
            status={TRANSACTION_STATUS.REJECTED}
            actionTransaction={actionTransaction}
          />
        </Flex>
      )}
    </Flex>
  );
};

export const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;
  ${$styles}
`;

const DescriptionContainer = styled.div`
  color: #abbadb;
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  max-height: 200px;
  overflow-y: auto;
`;

const ItemInfo = styled.div`
  flex: 1 1 0px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 8px 12px;
  background: #141722;
  border-radius: 6px;
  box-sizing: border-box;
`;

const ItemInfoContainer = styled.div`
  padding: 24px;
  background: var(--rainy-morning);
  border-radius: 8px;
  gap: 5px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
`;

const Title = styled.div<IStyles>`
  box-sizing: border-box;
  color: #d2eaff;
  font-family: var(--font-family-golos);
  font-size: 18px;
  margin-bottom: 20px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;

  ${$styles}
`;

export default withApp(ReviewModalLayout);
