import React from "react";
import styled, { css } from "styled-components";

import { IDaoLeaderboard } from "interfaces";
import { IStyles, $styles, Button, ButtonBlue } from "components";
import { Flex } from "components/common";
import LeaderboardPrizesHeader from "./LeaderboardPrizesHeader";
import LeaderboardTieredPrizesLayout from "./LeaderboardTieredPrizesLayout";
import LeaderboardRandomPrizesLayout from "./LeaderboardRandomPrizesLayout";

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;

  ${$styles}
`;

interface ILeaderboardPrizesModalProps {
  onClose: () => void;
  setPayload: (values: any) => void;
  payload: IDaoLeaderboard;
}

const LeaderboardPrizesModalLayout: React.FC<ILeaderboardPrizesModalProps> = ({
  onClose,
  payload,
  setPayload,
}) => {
  const [selectedTab, setSelectedTab] = React.useState<string>("tiered");

  const additionalPrizes = payload.additionalPrizes;
  const randomPrizes = payload.randomPrizes;
  const randomPrizeThreshold = payload.randomPrizeThreshold;
  const prize1 = payload?.prize1 || "";
  const prize2 = payload?.prize2 || "";
  const prize3 = payload?.prize3 || "";
  const [checked, setChecked] = React.useState<boolean>(
    randomPrizeThreshold !== "0" || !!payload.randomPrizes.length || false
  );

  const prizesArray = [prize1, prize2, prize3];

  const randomPrizesNotValid =
    randomPrizeThreshold === "" ||
    +randomPrizeThreshold < 0 ||
    !randomPrizes.length ||
    !!randomPrizes.find((el: any) => el.amount === "" || el.amount === 0);

  const tieredPrizesNotValid =
    !!additionalPrizes.find((el: any) => el.amount === "" || el.amount === 0) ||
    prizesArray.includes("");

  const isDisabled = checked ? randomPrizesNotValid || tieredPrizesNotValid : tieredPrizesNotValid;

  return (
    <Flex justify="center" column>
      <LeaderboardPrizesHeader selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {selectedTab === "tiered" ? (
        <LeaderboardTieredPrizesLayout
          prizes={[prize1, prize2, prize3]}
          additionalPrizes={additionalPrizes}
          setPayload={setPayload}
        />
      ) : (
        <LeaderboardRandomPrizesLayout
          prizes={randomPrizes}
          setPayload={setPayload}
          randomPrizeThreshold={randomPrizeThreshold}
          setChecked={setChecked}
          checked={checked}
        />
      )}
      <BoxItem
        styles={css`
          margin-top: 8px;
          height: 50px;
          @media (max-width: 600px) {
            width: 50%;
          }
        `}
        onClick={() => {
          if (!isDisabled) {
            const newPayload = {
              ...payload,
              prize1,
              prize2,
              prize3,
              randomPrizeThreshold,
              randomPrizes,
              additionalPrizes,
            };

            setPayload(newPayload);
            onClose();
          }
        }}
      >
        <Button disabled={isDisabled} height={44}>
          <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
            Save prizes
          </ButtonBlue>
        </Button>
      </BoxItem>
    </Flex>
  );
};

export default LeaderboardPrizesModalLayout;
