import { SVGProps } from "react";

export type ArrowTriangleProps = SVGProps<SVGSVGElement>;

const ArrowTriangle: React.FC<ArrowTriangleProps> = (props) => {
  return (
    <svg {...props} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame 1295">
        <path
          id="Polygon 1"
          d="M9.88734 5.72817C10.514 6.11984 10.514 7.0325 9.88734 7.42417L4.77414 10.6199C4.10809 11.0362 3.24414 10.5574 3.24414 9.77193L3.24414 3.38042C3.24414 2.59499 4.10809 2.11614 4.77414 2.53242L9.88734 5.72817Z"
          fill={props.fill ?? "#C8D7F5"}
        />
      </g>
    </svg>
  );
};

export default ArrowTriangle;
