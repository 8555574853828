/* eslint-disable react/display-name */
import { Button, ButtonBlue, Flex } from "components/common";
import { formatNumber } from "components/pachinko/Prizes";
import { TheIcon } from "components/TheComponents";
import Image from "next/image";
import React, { forwardRef, HTMLAttributes, CSSProperties } from "react";
import styled, { css } from "styled-components";
import { ItemProps } from "../types";
import { SubHeader, Card } from "./ItemsTable";
import apiService from "services/apiService";
import { Circle } from "@chakra-ui/react";

const FavoriteButton = styled(Flex)`
  z-index: 1;
  position: absolute; 
  width: 40px;
  height: 40px;
  top: 8px;
  right: 8px;
  justify-content: center;
  align-items: center;

  border-radius: 8px;
  background: #1D2234;
  cursor: pointer;
  transition: background 0.2s;

  svg {
    color: #ABBADB;
    opacity: 0.5;
    transition: all 0.2s;
  }

  &:hover {
    background: #202536;
    svg {
      opacity: 1;
    }
  }

  &:active {
    background: #171b26;
  }

 &.__is-favorite {
    svg {
      color: var(--pure-sky);
      opacity: 1;
    }
  }
`;

const ItemQuantity = styled(Flex)`
  align-items: center;
  gap: 8px;
  color: #ABBADB;
  font-family: "Golos Text";
  font-size: 14px;
  line-height: 150%; /* 21px */
  margin-top: 24px;
`;

const ItemQuantityPrice = styled.div`
  color: #131635;
  font-family: "Golos Text";
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
`;

const ItemLabel = styled.div`
  position: absolute;
  display: inline-flex;
  min-width: 122px;
  height: 32px;
  padding: 8px;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 0px 0px 8px 8px;
  background: linear-gradient(98deg, #F32257 20.16%, #D21587 62.28%, #8719DC 132.48%);

  color: #D5E0F5;
  font-family: "Golos Text";
  font-size: 14px;
`;

export type ItemСomponentProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
  onSelectItem?: (item: ItemProps) => void;
  onOpenDelete?: () => void;
  setSelectedItem?: React.Dispatch<any>;
  isAdminPage?: boolean;
  disableDND?: boolean;
  item: ItemProps;
  isFavorite?: boolean;
  fetchData: () => void;
  profile: any;
  onOpenLogin: () => void;
};

const Item = forwardRef<HTMLDivElement, ItemСomponentProps>(
  (
    {
      id,
      withOpacity,
      isDragging,
      style,
      item,
      onSelectItem,
      onOpenDelete,
      setSelectedItem,
      isAdminPage,
      disableDND,
      isFavorite,
      fetchData,
      profile,
      onOpenLogin,
      ...props
    },
    ref
  ) => {
    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? "0.5" : "1",
      transformOrigin: "50% 50%",
      cursor: !disableDND ? (isDragging ? "grabbing" : "grab") : "default",
      display: "flex",
      color: "black",
      width: "100%",
      alignItems: "center",
      boxShadow: isDragging
        ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
        : "none",
      transform: isDragging ? "scale(1.05)" : "none",
      ...style,
    };

    const [favorite, setFavorite] = React.useState<boolean | undefined>(isFavorite);

    const handleIsFavoriteItem = async (e: React.MouseEvent, id: string) => {
      e.stopPropagation();
      if (!profile) {
        onOpenLogin();
      } else {
        await apiService.post(`/point-shop/${favorite ? "remove-favorite" : "add-favorite"}/${id}`);
        setFavorite(!favorite);
      }
    }

    const itemSoldSum = item.stockQuantity - item.quantity;

    return (
      <Card ref={ref} style={inlineStyles} {...props}>
        {item.quantity == item.stockQuantity &&
          <ItemLabel>
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
              <g clipPath="url(#clip0_6947_2703)">
                <path d="M13.9658 9.36778C13.7825 6.98156 12.6715 5.48622 11.6914 4.16665C10.7838 2.94503 10 1.89009 10 0.333904C10 0.208904 9.93 0.0946543 9.819 0.037373C9.70766 -0.020252 9.57387 -0.0108145 9.47266 0.062748C8.00066 1.11606 6.77247 2.89134 6.34344 4.58522C6.04559 5.7645 6.00619 7.09025 6.00066 7.96584C4.64128 7.6755 4.33334 5.64212 4.33009 5.61997C4.31478 5.5145 4.25034 5.42272 4.15659 5.37259C4.06188 5.32312 3.95053 5.31953 3.8545 5.36706C3.78322 5.40156 2.10481 6.25437 2.00716 9.6594C2.00031 9.77269 2 9.88631 2 9.9999C2 13.3079 4.69172 15.9995 8 15.9995C8.00456 15.9998 8.00944 16.0005 8.01334 15.9995C8.01466 15.9995 8.01594 15.9995 8.01756 15.9995C11.3177 15.99 14 13.3021 14 9.9999C14 9.83356 13.9658 9.36778 13.9658 9.36778ZM8 15.3329C6.89713 15.3329 6 14.3772 6 13.2025C6 13.1625 5.99969 13.1221 6.00259 13.0726C6.01594 12.5772 6.11003 12.239 6.21322 12.0141C6.40659 12.4294 6.75228 12.8112 7.31381 12.8112C7.49806 12.8112 7.64716 12.6622 7.64716 12.4779C7.64716 12.0034 7.65694 11.4559 7.77509 10.9617C7.88025 10.5236 8.13153 10.0575 8.44991 9.68384C8.5915 10.1688 8.86756 10.5614 9.13709 10.9445C9.52284 11.4926 9.92159 12.0593 9.99159 13.0257C9.99581 13.083 10.0001 13.1407 10.0001 13.2025C10 14.3772 9.10287 15.3329 8 15.3329Z" fill="#D5E0F5" />
              </g>
              <defs>
                <clipPath id="clip0_6947_2703">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>

            Limited offer
          </ItemLabel>
        }

        <FavoriteButton
          className={favorite ? "__is-favorite" : "not-in-favorite"}
          onClick={(e) => handleIsFavoriteItem(e, item.id)}
        >
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="bookmark 1">
              <path id="Vector" d="M12.6672 0H3.38379C2.62773 0 1.96387 0.621981 1.96387 1.3582V15.0911C1.96387 15.3376 2.03246 15.543 2.143 15.7016C2.2752 15.8913 2.48806 16.0001 2.71631 16C2.93211 16 3.16188 15.9039 3.37406 15.7227L7.52744 12.1963C7.65572 12.0867 7.83998 12.0239 8.03156 12.0239C8.22307 12.0239 8.40695 12.0867 8.5356 12.1966L12.6751 15.7222C12.888 15.9039 13.102 16.0001 13.3174 16.0001C13.6817 16.0001 14.0368 15.7191 14.0368 15.0912V1.3582C14.0368 0.621981 13.4232 0 12.6672 0Z" fill="currentColor" />
            </g>
          </svg>
        </FavoriteButton>

        <Flex align="center" width="100%" column height="100%" gap={4}>
          <Image src={item.imageUrl ? item.imageUrl : '/images/PointShopComponent/w-logo.png'} height={144} width={144} alt="image" />

          <SubHeader
            style={{
              height: 42,
              paddingBottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            showTooltip={item.title.length > 10 || false} title={item.title}
          >
            {item.title.length > 30
              ? item.title.slice(0, 30) + "..."
              : item.title}
          </SubHeader>

          {isAdminPage ? (
            <Flex>
              <Button
                styles={css`
                  width: 120%;
                  cursor: pointer;
                  white-space: nowrap;
                  height: 44px;
                  margin-right: 10px;
                `}
                preset="blue"
                isDark
                onClick={() => {
                  onSelectItem?.(item);
                }}
              >
                <ButtonBlue isDark>Edit item</ButtonBlue>
              </Button>
              <Button
                styles={css`
                  cursor: pointer;
                  height: 44px;
                  padding: 10px;
                `}
                preset="blue"
                isDark
                onClick={() => {
                  onOpenDelete?.()
                  setSelectedItem?.(item);
                }}
              >
                <ButtonBlue isDark>
                  {" "}
                  <TheIcon icon="action:remove" size={16} />
                </ButtonBlue>
              </Button>
            </Flex>
          ) : (
            <Button
              styles={css`
                min-width: 180px;
                height: 46px;
                margin-top: auto;
                font-size: 14px;
                padding: 10px 15px;
              `}
              preset="blue"
              isDark={item.quantity === 0 || false}
              disabled={item.quantity === 0 || false}
              onClick={() => {
                if (item.quantity > 0) {
                  onSelectItem?.(item);
                }
              }}
            >
              <ButtonBlue
                isDark={item.quantity === 0 || false}
                transparent
                style={{ whiteSpace: "nowrap" }}
              >
                {item.quantity === 0 ? "Sold Out" : "Buy for"}
              </ButtonBlue>

              <Flex align="center" gap={4}>
                <Image src="/images/PointShopComponent/coin_2.svg" height={24} width={24} alt="image" />

                <ItemQuantityPrice style={{ color: item.quantity !== 0 ? '#131635' : '#8999ab' }}>{formatNumber(item.price)}</ItemQuantityPrice>
              </Flex>
            </Button>
          )}

          <ItemQuantity>
            {item.quantity > 0 && `${item.quantity} Left`}

            {(item.quantity > 0 && itemSoldSum > 0) && (
              <Circle minWidth={1} minHeight={1} backgroundColor={"rgba(171, 186, 219, 0.50)"}></Circle>
            )}

            {itemSoldSum > 0 &&
              <span style={{ color: "rgba(171, 186, 219, 0.50)" }}>{`Sold ${itemSoldSum} times`}</span>
            }
          </ItemQuantity>
        </Flex>
      </Card >
    );
  }
);

export default Item;