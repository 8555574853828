import {
  ProfileTab,
  ProfileTabHeader,
  ProfileTabTitle,
  ProfileTabDescription,
  ProfileTabBlock,
  DescriptionLink,
} from "../styles";
import AddressInput, { WalletAssetEnum } from "../components/AddressInput";
import TextInput from "../components/TextInput";
import { useProfileContext } from "components/public/ProfileLayout/ProfileContext";
import styled from "styled-components";
import Image from "next/image";
import { useAppContext } from "contexts";
import { useMemo } from "react";
import { IUserDto } from "interfaces";

export type MainTabProps = { user: IUserDto };

const MainTab: React.FC<MainTabProps> = ({ user: profile }) => {
  const { updateAddress, updateUser, isAdminCanEdit } = useProfileContext();
  const { profile: mainUserProfile } = useAppContext();
  const isUserOwnProfile = useMemo(() => {
    return profile?.id === mainUserProfile?.id;
  }, [mainUserProfile?.id, profile?.id]);

  const isEditDisabled = useMemo(() => {
    if (isAdminCanEdit) return false;
    return !isUserOwnProfile;
  }, [isAdminCanEdit, isUserOwnProfile]);

  return profile ? (
    <ProfileTab>
      <ProfileTabBlock>
        <ProfileTabHeader>
          <ProfileTabTitle>Main</ProfileTabTitle>
          <ProfileTabDescription>
            Set up your crypto wallets to instantly receive your winnings
          </ProfileTabDescription>
          <Info>
            <Image width={48} height={48} src={"/images/icons/alert.svg"} alt="" />
            <p>
              Please ensure that you use your own wallet addresses and not the deposit addresses of
              casinos, as these can change periodically. If a prize is paid out to an incorrect
              address, we will not be able to refund it.
            </p>
          </Info>
        </ProfileTabHeader>
        <AddressInput
          disabled={isEditDisabled}
          asset={WalletAssetEnum.BTC}
          address={profile.btcAddress ?? ""}
          saveNewAddress={async (value) => {
            await updateAddress({
              field: "btcAddress",
              value,
            });
          }}
        />
        <AddressInput
          disabled={isEditDisabled}
          asset={WalletAssetEnum.ETH}
          address={profile.ethAddress ?? ""}
          saveNewAddress={async (value) => {
            await updateAddress({
              field: "ethAddress",
              value,
            });
          }}
        />
        <AddressInput
          disabled={isEditDisabled}
          asset={WalletAssetEnum.LTC}
          address={profile.ltcAddress ?? ""}
          saveNewAddress={async (value) => {
            await updateAddress({
              field: "ltcAddress",
              value,
            });
          }}
        />
        <AddressInput
          disabled={isEditDisabled}
          asset={WalletAssetEnum.USDT_ERC}
          address={profile.usdtErc20Address ?? ""}
          saveNewAddress={async (value) => {
            await updateAddress({
              field: "usdtErc20Address",
              value,
            });
          }}
        />
      </ProfileTabBlock>
      <ProfileTabBlock>
        <ProfileTabHeader>
          <ProfileTabTitle>Steam Trade URL</ProfileTabTitle>
          <ProfileTabDescription>
            Input{" "}
            <DescriptionLink
              target="_blank"
              href="https://steamcommunity.com/id/me/tradeoffers/privacy"
              rel="noopener noreferrer"
            >
              Steam Tradelink
            </DescriptionLink>{" "}
            below
          </ProfileTabDescription>
        </ProfileTabHeader>
        <TextInput
          disabled={isEditDisabled}
          text={profile.steamTradeLink ?? ""}
          placeholder="Your Trade link"
          saveNewValue={async (value) => {
            await updateUser({
              field: "steamTradeLink",
              value,
            });
          }}
        />
      </ProfileTabBlock>
    </ProfileTab>
  ) : (
    <></>
  );
};

const Info = styled.div`
  border: 1px solid #bc1c2e;
  padding: 10px 20px 10px 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 1rem;
  background: #c842301a;

  p {
    line-height: 150%;
    font-size: 14px;

    @media (max-width: 760px) {
      font-size: 12px;
    }
    color: #d2eaff;
    font-weight: 500;
    font-family: var(--font-family-golos), sans-serif;
    margin: 0;
  }
`;

export default MainTab;
