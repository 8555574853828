import { SVGProps } from "react";

export type OthersProps = SVGProps<SVGSVGElement>;

const Others: React.FC<OthersProps> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21 1.25H15C14.0335 1.25 13.25 2.0335 13.25 3V9C13.25 9.9665 14.0335 10.75 15 10.75H21C21.9665 10.75 22.75 9.9665 22.75 9V3C22.75 2.0335 21.9665 1.25 21 1.25Z"
        fill={props.fill ?? "#E9F4FF"}
      />
      <path
        d="M22.7333 16.7627L19.2372 13.2666C18.9086 12.9392 18.4637 12.7554 17.9999 12.7554C17.5361 12.7554 17.0911 12.9392 16.7626 13.2666L13.2665 16.7627C12.9389 17.0911 12.7549 17.5361 12.7549 18C12.7549 18.4639 12.9389 18.9089 13.2665 19.2373L16.7626 22.7334C17.091 23.0611 17.536 23.2451 17.9999 23.2451C18.4638 23.2451 18.9088 23.0611 19.2372 22.7334L22.7333 19.2373C23.0611 18.909 23.2452 18.464 23.2452 18C23.2452 17.536 23.0611 17.091 22.7333 16.7627Z"
        fill={props.fill ?? "#E9F4FF"}
      />
      <path
        d="M9 1.25H3C2.0335 1.25 1.25 2.0335 1.25 3V9C1.25 9.9665 2.0335 10.75 3 10.75H9C9.9665 10.75 10.75 9.9665 10.75 9V3C10.75 2.0335 9.9665 1.25 9 1.25Z"
        fill={props.fill ?? "#E9F4FF"}
      />
      <path
        d="M9 13.25H3C2.0335 13.25 1.25 14.0335 1.25 15V21C1.25 21.9665 2.0335 22.75 3 22.75H9C9.9665 22.75 10.75 21.9665 10.75 21V15C10.75 14.0335 9.9665 13.25 9 13.25Z"
        fill={props.fill ?? "#E9F4FF"}
      />
    </svg>
  );
};

export default Others;
