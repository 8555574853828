import React from "react";
import styled from "styled-components";
import { $stylesReset, IStyles } from "./$styles";
import { fixed, fixedRound } from "helpers";

export interface ITotalGivenReward {
  img?: JSX.Element;
  title?: string;
  value?: number;
}

export const TotalGivenReward: React.FC<ITotalGivenReward & IStyles> = ({
  img,
  title = "?",
  value = 0,
  styles,
}): JSX.Element => {
  const rounded = React.useMemo(() => {
    const format = "0,0";
    const rounded = fixedRound(value);
    return fixed(format, rounded, 1);
  }, [value]);

  return (
    <Box styles={styles}>
      <Icon>{img}</Icon>
      <Data>
        <Title>{title}</Title>
        <Value>{rounded}</Value>
      </Data>
    </Box>
  );
};

const Box = styled.div<IStyles & ITotalGivenReward>`
  ${$stylesReset}
  ${({ styles }) => styles}
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row nowrap;
  gap: 24px;
  height: 100%;
  overflow: hidden;
  background-color: var(--global-bg-dark-1);
  border-radius: 8px;
  padding: 0 16px 0 10px;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50px;
    display: block;
    height: 100%;
    width: 0;
    border-radius: 50%;
    box-shadow: 0px 4px 66px 8px rgba(69, 24, 188, 0.5);
    @media (max-width: 700px) {
      left: 0;
    }
  }

  @media (max-width: 700px) {
    gap: 10px;
    padding: 16px;
  }
`;

const Icon = styled.div`
  ${$stylesReset}
  display: flex;
  height: 100%;

  img {
    object-fit: contain;
  }
`;

const Data = styled.div`
  ${$stylesReset}
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 9px;
  color: #d0dfff;
  font-family: var(--font-family-exo);
  font-style: italic;
  text-transform: uppercase;
`;

const Title = styled.div`
  ${$stylesReset}
  font-size: 16px;
  font-weight: 700;
`;

const Value = styled.div`
  ${$stylesReset}
  font-size: 24px;
  font-weight: 900;

  &::before {
    content: "$";
    color: #3b8be9;
  }

  &::after {
    content: "+";
  }
`;
