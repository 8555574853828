import React, { FC, memo, useCallback, useContext, useState } from "react";
import styled from "styled-components";
import News from "./News";
import NewsIcon from "assets/icons/news.svg";
import { useShowPageLayoutContext } from "contexts/ShowPageLayoutContext";
import { useGetActiveNotificationList } from "hooks/notification/useGetActiveNotificationList";
import { DualRingInner, DualRingLoader } from "components";

interface NewsSidebarProps {
  show?: boolean;
}

const NewsSidebar: FC<NewsSidebarProps> = ({ show }) => {
  const { showChatSidebar, setShowChatSidebar, isReviewed } = useShowPageLayoutContext();
  const [isLoading, setIsLoading] = useState(false);
  const { refetch } = useGetActiveNotificationList();

  const handleToggleChatSidebar = useCallback(async () => {
    const refretchNotificationsOnOpen = () => {
      if (!showChatSidebar) {
        return refetch();
      }
    };

    const setNotificationsReviewedOnOpen = () => {
      if (!isReviewed.value && !showChatSidebar) {
        isReviewed.setValue(true);
      }
    };

    try {
      setIsLoading(true);
      await refretchNotificationsOnOpen();
    } finally {
      setIsLoading(false);
    }
    setNotificationsReviewedOnOpen();
    setShowChatSidebar((prev) => !prev);
  }, [setShowChatSidebar, showChatSidebar, refetch, isReviewed]);

  return (
    <Sidebar show={show}>
      <ChatToggleBtn data-reviewed={isReviewed.value} onClick={handleToggleChatSidebar}>
        {isLoading ? (
          <DualRingLoader smallRing={true}>
            <DualRingInner smallRing={true}></DualRingInner>
          </DualRingLoader>
        ) : (
          <NewsIcon />
        )}
      </ChatToggleBtn>
      <News />
    </Sidebar>
  );
};

const Sidebar = styled.aside<NewsSidebarProps>`
  height: 100%;
  width: 100%;
  position: relative;
  background-color: var(--global-bg-dark-1);
  width: var(--sidebar-width);
  height: calc(100vh - var(--header-height));
  top: calc(var(--header-height) + 1px);
  right: 0;
  position: fixed;
  z-index: 7;
  transform: ${({ show }) => (show ? "translateX(0)" : "translateX(100%)")};
  transition: transform 0.3s;

  @media (max-width: 500px) {
    height: calc(100vh - var(--mobile-header-height));
    top: calc(var(--mobile-header-height) + 1px);
  }
`;

const ChatToggleBtn = styled.div`
  display: flex;
  width: 48px;
  height: 48px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 14px;
  background-color: var(--global-bg-dark-2);
  border-radius: 0px 0px 0px 8px;
  cursor: pointer;
  transform: translateX(-100%);
  margin-left: -1px;
  & > svg {
    color: #dddcdc;
  }

  &[data-reviewed="false"] {
    & > svg {
      color: var(--pure-sky);
      height: 24px;
      width: 24px;
    }
  }
`;

export default memo(NewsSidebar);
