import { useEffect, useState } from "react";
import { useRouter } from "next/router";
import Draggable from "react-draggable";
import { $styles, IStyles } from "components";
import styled, { css } from "styled-components";

import Image from "next/image";
import { TwitchEmbed } from "react-twitch-embed";
import {
  HYUS_USERNAME,
  PKLE_USERNAME,
  STREAMING_PLATFORMS,
  WATCH_GAMES_USERNAME,
  WREWARDS_USERNAME,
} from "helpers/constants";
import { Stream } from "helpers/types";
import { useMediaQuery } from "react-responsive";
import { useAppContext } from "contexts";

const StreamFloatingBlock: React.FC = () => {
  const { liveStreams } = useAppContext();

  const isDesktop = useMediaQuery({
    query: "(min-width: 1280px)",
  });

  const watchgamestvLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === WATCH_GAMES_USERNAME
  );

  const hyusLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === HYUS_USERNAME
  );

  const wrewardsLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === WREWARDS_USERNAME
  );

  const pkleLiveStream = liveStreams?.find(
    (el: Stream) => el.isLive === true && el.streamer === PKLE_USERNAME
  );

  const liveStreamToShow =
    watchgamestvLiveStream || hyusLiveStream || wrewardsLiveStream || pkleLiveStream;

  const { pathname } = useRouter();

  const [isStreamVisible, setIsStreamVisible] = useState<boolean>(false);
  const [isStreamWindowWasClosed, setIsStreamWindowWasClosed] = useState<boolean>(false);

  useEffect(() => {
    if (isDesktop && !isStreamWindowWasClosed && !!liveStreamToShow) {
      setIsStreamVisible(true);
    }
  }, [liveStreamToShow, isDesktop, isStreamWindowWasClosed]);

  if (pathname.includes("/admin")) return null;

  if (!isStreamVisible) {
    return (
      <StreamDraggable
        styles={css`
          animation: float ease 20s infinite;
          width: 50px;
          height: 50px;
        `}
      >
        <Button onClick={() => setIsStreamVisible(true)}>
          <Image width={25} height={25} src="/images/icons/live-button.svg" alt="live" />
        </Button>
      </StreamDraggable>
    );
  }

  return (
    <Draggable handle="#draggable-handle">
      <StreamDraggable
        styles={css`
          width: 350px;
          height: 225px;
        `}
      >
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <Control className="stream-draggable-control">
            <ControlCell
              styles={css`
                cursor: move;
                img {
                  pointer-events: none;
                  transform: rotate(45deg);
                  width: 14px;
                }
              `}
              id="draggable-handle"
            >
              <Image width={24} height={24} src="/images/icons/drag.svg" alt="move" />
            </ControlCell>

            <ControlCell
              styles={css`
                cursor: pointer;
                img {
                  pointer-events: none;
                  width: 14px;
                }
              `}
              onClick={() => {
                setIsStreamWindowWasClosed(true);
                setIsStreamVisible(false);
              }}
              className="stream-draggable-control-cell stream-draggable-close"
            >
              <Image width={24} height={24} src="/images/icons/close.svg" alt="X" />
            </ControlCell>
          </Control>

          {(liveStreamToShow?.platform === STREAMING_PLATFORMS.TWITCH && (
            <TwitchEmbed
              channel={liveStreamToShow?.streamer}
              id="twitch-embed"
              style={{ width: "100%", height: "100%", borderRadius: "10px", aspectRatio: "16/9" }}
              withChat={false}
            />
          )) || (
            <iframe
              src={`https://player.kick.com/${liveStreamToShow?.streamer || WATCH_GAMES_USERNAME}`}
              style={{ width: "100%", height: "100%", borderRadius: "10px", aspectRatio: "16/9" }}
              frameBorder="0"
              scrolling="no"
              allowFullScreen={true}
            />
          )}
        </div>
      </StreamDraggable>
    </Draggable>
  );
};

export default StreamFloatingBlock;

const StreamDraggable = styled.div<IStyles>`
  position: fixed;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  bottom: 20px;
  right: 35px;
  z-index: 999;
  position: fixed;
  border-radius: 8px 0 8px 8px;
  background: transparent;
  filter: drop-shadow(4px 4px 16px rgba(4, 4, 26, 0.16))
    drop-shadow(-4px 4px 16px rgba(4, 4, 26, 0.16)) drop-shadow(4px 12px 24px rgba(4, 4, 26, 0.16));
  ${$styles}
`;

// Cta styles
const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: linear-gradient(265.52deg, rgb(71, 150, 242), rgb(71, 211, 242));
  border-radius: 50%;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  user-select: none;
  outline: none;
  border: 0;

  img {
    pointer-events: none;
  }
`;

// Control styles
const Control = styled.div`
  border-radius: 8px 8px 0 0;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateY(-100%);
  display: flex;
  gap: 6px;
  justify-content: flex-end;
  align-items: center;
  padding: 6px;
  width: 66px;
  margin-left: auto;
  margin-right: 0;
  z-index: 35;
`;

// ControlCell styles
const ControlCell = styled.button<IStyles>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  background: rgba(154, 169, 184, 0.24);
  border-radius: 4px;
  color: #9aa9b8;
  outline: none;
  border: 0;

  ${$styles}
`;
