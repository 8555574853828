import { FC } from "react";
import styled from "styled-components";

type BackdropProps = {
  show?: boolean;
};

const Backdrop: FC<BackdropProps> = ({ show }) => {
  return <BackdropContainer show={show}></BackdropContainer>;
};

const BackdropContainer = styled.div<BackdropProps>`
  width: 0;
  height: 0;
  z-index: -1;
  display: flex;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 6;
  justify-content: center;
  align-items: center;
  overflow: auto;
  overscroll-behavior-y: none;
  background-color: var(--global-bg-dark);
  opacity: 0.8;

  ${({ show }) =>
    show &&
    `
        width: 100vw;
        height: var(--chakra-vh);
    `}

  @media (max-width: 500px) {
    width: 0;
  }
`;

export default Backdrop;
