import { SVGProps } from "react";

export type BurgerProps = SVGProps<SVGSVGElement>;

const Burger: React.FC<BurgerProps> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="burger-icon_3452671">
                <g id="Group">
                    <path id="Vector" d="M3.5 16.5H20.5C21.3286 16.5 22 17.1714 22 18C22 18.8286 21.3286 19.5 20.5 19.5H3.5C2.67139 19.5 2 18.8286 2 18C2 17.1714 2.67139 16.5 3.5 16.5Z" fill="currentColor" />
                    <path id="Vector_2" d="M20.5 13.5H3.5C2.67139 13.5 2 12.8286 2 12C2 11.1714 2.67139 10.5 3.5 10.5H20.5C21.3286 10.5 22 11.1714 22 12C22 12.8286 21.3286 13.5 20.5 13.5Z" fill="currentColor" />
                    <path id="Vector_3" d="M20.5 7.5H3.5C2.67139 7.5 2 6.82861 2 6C2 5.17139 2.67139 4.5 3.5 4.5H20.5C21.3286 4.5 22 5.17139 22 6C22 6.82861 21.3286 7.5 20.5 7.5Z" fill="currentColor" />
                </g>
            </g>
        </svg>
    );
};

export default Burger;
