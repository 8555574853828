export enum ProfileTabs {
  Main = "Main",
  Connections = "Connections",
  Raffles = "Raffles",
  Shop = "Points Shop",
  Bets = "Bets History",
  Transactions = "Transactions",
}

export const ProfileTabsListPublic = [
  ProfileTabs.Main,
  ProfileTabs.Connections,
  ProfileTabs.Raffles,
  ProfileTabs.Shop,
  ProfileTabs.Bets,
];

export const ProfileTabsListRestricted = [...ProfileTabsListPublic, ProfileTabs.Transactions];
