import { SVGProps } from "react";

export type GraphixIconProps = SVGProps<SVGSVGElement>;

const GraphixIcon: React.FC<GraphixIconProps> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="fi_4211004">
                <g id="12-Bar-chart">
                    <g id="Vector">
                        <path d="M20.3571 19.7143H4.92857C4.75808 19.7143 4.59456 19.6466 4.474 19.526C4.35344 19.4054 4.28571 19.2419 4.28571 19.0714V3.64286C4.28571 3.47236 4.21799 3.30885 4.09743 3.18829C3.97687 3.06773 3.81335 3 3.64286 3C3.47236 3 3.30885 3.06773 3.18829 3.18829C3.06773 3.30885 3 3.47236 3 3.64286V19.0714C3 19.5829 3.20319 20.0735 3.56487 20.4351C3.92654 20.7968 4.41708 21 4.92857 21H20.3571C20.5276 21 20.6912 20.9323 20.8117 20.8117C20.9323 20.6912 21 20.5276 21 20.3571C21 20.1866 20.9323 20.0231 20.8117 19.9026C20.6912 19.782 20.5276 19.7143 20.3571 19.7143Z" fill="#ABBADB"/>
                        <path d="M13.9286 12H11.3571C11.0021 12 10.7143 12.2878 10.7143 12.6429V17.7857C10.7143 18.1408 11.0021 18.4286 11.3571 18.4286H13.9286C14.2836 18.4286 14.5714 18.1408 14.5714 17.7857V12.6429C14.5714 12.2878 14.2836 12 13.9286 12Z" fill="#ABBADB"/>
                        <path d="M19.0714 4.28571H16.5C16.145 4.28571 15.8571 4.57353 15.8571 4.92857V17.7857C15.8571 18.1408 16.145 18.4286 16.5 18.4286H19.0714C19.4265 18.4286 19.7143 18.1408 19.7143 17.7857V4.92857C19.7143 4.57353 19.4265 4.28571 19.0714 4.28571Z" fill="#ABBADB"/>
                        <path d="M8.78571 8.14286H6.21429C5.85925 8.14286 5.57143 8.43067 5.57143 8.78571V17.7857C5.57143 18.1408 5.85925 18.4286 6.21429 18.4286H8.78571C9.14075 18.4286 9.42857 18.1408 9.42857 17.7857V8.78571C9.42857 8.43067 9.14075 8.14286 8.78571 8.14286Z" fill="#ABBADB"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default GraphixIcon;
