import { SVGProps } from "react";

export type GearIconProps = SVGProps<SVGSVGElement>;

const GearIcon: React.FC<GearIconProps> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="fi_6319374">
                <g id="Layer 2">
                    <path id="Vector" d="M21.82 11L17.77 4C17.5913 3.69032 17.3328 3.43421 17.0215 3.25833C16.7102 3.08246 16.3575 2.99325 16 3H8C7.64252 2.99325 7.28976 3.08246 6.97846 3.25833C6.66717 3.43421 6.40871 3.69032 6.23 4L2.18 11C2.00446 11.304 1.91205 11.6489 1.91205 12C1.91205 12.3511 2.00446 12.696 2.18 13L6.23 20C6.40871 20.3097 6.66717 20.5658 6.97846 20.7417C7.28976 20.9175 7.64252 21.0067 8 21H16C16.3507 20.9996 16.6952 20.9071 16.9988 20.7315C17.3025 20.556 17.5546 20.3037 17.73 20L21.78 13C21.9617 12.6995 22.061 12.3564 22.068 12.0054C22.075 11.6543 21.9895 11.3075 21.82 11ZM12 15C11.4067 15 10.8266 14.8241 10.3333 14.4944C9.83994 14.1648 9.45542 13.6962 9.22836 13.1481C9.0013 12.5999 8.94189 11.9967 9.05764 11.4147C9.1734 10.8328 9.45912 10.2982 9.87868 9.87868C10.2982 9.45912 10.8328 9.1734 11.4147 9.05764C11.9967 8.94189 12.5999 9.0013 13.148 9.22836C13.6962 9.45542 14.1648 9.83994 14.4944 10.3333C14.824 10.8266 15 11.4067 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15Z" fill="#ABBADB"/>
                </g>
            </g>
        </svg>
    );
};

export default GearIcon;
