import { animated, useSpring } from "@react-spring/web";
import { IStyles } from "components/common";
import { useBalanceContext } from "contexts/Balance/BalanceProvider";
import useWrewardsNumbers from "hooks/userWrewardsNumbers";
import numeral from "numeral";
import { memo } from "react";
import styled from "styled-components";

export interface IThePointsBalance {
  value?: number | null | undefined;
  isFixed?: boolean;
  precision?: number;
}

const fixed = (format: string, value: number, length: number = 1): string => {
  const fixed = numeral(value).format(format).padStart(length, "0");
  return fixed;
};

const ThePointsBalanceComponent: React.FC<IStyles & IThePointsBalance> = ({
  value,
  isFixed,
  precision = 2,
  ...props
}): React.ReactElement => {
  const { formatNumber } = useWrewardsNumbers();
  const { value: animatedValue } = useSpring({
    from: { value: 0 },
    to: { value },
    config: { duration: 200, precision: 2 },
  });

  return (
    <>
      {value !== null && value !== undefined ? (
        isFixed ? (
          fixed("0,0", value)
        ) : (
          <AnimatedBalance>{animatedValue.to((val) => formatNumber(val))}</AnimatedBalance>
        )
      ) : (
        <Label {...props}>API DOWN</Label>
      )}
    </>
  );
};

const ThePointsBalance = memo(ThePointsBalanceComponent);
export { ThePointsBalance };
const AnimatedBalance = styled(animated.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
  font-family: "Outfit";
  color: var(--Starlight-100, #fff);
`;

const Label = styled.div<IStyles>`
  font-size: 0.6rem;
  font-style: italic;
  white-space: nowrap;
  ${({ styles }) => styles}
`;
