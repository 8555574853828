export const hiddenText = (text: string) => {
  const length = text.length;

  // If the length is less than or equal to 2, return as is.
  if (length <= 2) return text;

  // Calculate a simple hash by summing the ASCII values.
  const sum = text.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0);

  // Determine the index of the character to reveal.
  const revealIndex = (sum % (length - 1)) + 1;

  // Create an array of '*' with a length of `length`.
  const hiddenNameArray = Array(length).fill("*");

  // Set the first character and the chosen character to their actual values.
  hiddenNameArray[0] = text[0];
  hiddenNameArray[revealIndex] = text[revealIndex];

  // Convert the array back to a string and return.
  return hiddenNameArray.join("");
};
