import { SVGProps } from "react";

export type DropdownProps = SVGProps<SVGSVGElement>;

const Dropdown: React.FC<DropdownProps> = (props) => {
  return (
    <svg
      {...props}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.80003 10.6838C8.43382 11.1053 7.56883 11.1054 7.20244 10.6839L3.1471 6.01884C2.60184 5.39159 3.68812 4.69533 4.51484 5.14219L7.43318 6.71961C7.76942 6.90135 8.23578 6.90122 8.57182 6.71927L11.4845 5.1422C12.311 4.69474 13.3982 5.39082 12.853 6.01835L8.80003 10.6838Z"
        fill={props.fill ?? "#E9F4FF"}
      />
    </svg>
  );
};

export default Dropdown;
