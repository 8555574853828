import React from "react";
import { IStyles, $styles } from "components";
import styled from "styled-components";

export interface SelectOption {
  value: string;
  label: string;
  disabled: boolean;
  hidden: boolean;
}

export interface TheSelectProps {
  options: SelectOption[];
  setSorter: (value: string) => void;
  defaultValue: string;
  style?: any;
}

export const SelectContainer = styled.div<IStyles>`
  position: relative;
  width: 100%;

  @media (min-width: 540px) {
    width: 200px;
  }

  ${$styles}
`;

export const Select = styled.select<IStyles>`
  width: 100%;
  height: 50px;
  padding: 0px 15px; /* Adjust left and right padding */
  border: 1px solid rgba(36, 39, 70, 0.5);
  border-radius: 8px;
  background-color: #171b2d;
  color: #464e62;
  font-size: 16px;
  outline: none;
  /* Remove the default arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: url("/images/PachinkoComponent/arrow-select.png");
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-position: 94% center;
  background-size: 10px 6px;
  &:hover {
    border-color: #3b8be9;
  }
  ${$styles}
`;

export const TheSelect: React.FC<TheSelectProps> = ({
  options,
  setSorter,
  defaultValue,
  style,
}): React.ReactElement => {
  return (
    <SelectContainer>
      <Select
        style={style}
        placeholder="SORT BY"
        defaultValue={defaultValue}
        onChange={(e) => setSorter(e.target.value)}
      >
        {options.map((o) => (
          <option key={o.label} disabled={o.disabled} hidden={o.hidden} value={o.value}>
            {o.label}
          </option>
        ))}
      </Select>
    </SelectContainer>
  );
};
