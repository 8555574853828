import styled from "styled-components";
import { Pages as UIPages } from "components/common";

export const Root = styled.div`
  width: 100%;
`;

export const Pages = styled(UIPages)`
  flex-wrap: wrap !important;
  margin-top: 12px !important;
`;
