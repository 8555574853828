import { triggerNotification } from "components/TheComponents/Notification";
import { useAppContext } from "contexts";
import { IUserDto } from "interfaces";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { ExceptionHandlingType } from "types/apiServiceTypes";

export interface ExternalConnection {
  platform: string;
  username: string;
}

export type UseConnectionsTabReturn = {
  unlinkDiscord: () => Promise<void>;
  verifyDiscord: () => Promise<void>;
  unlinkPlatform: ({ platform, otp }: { platform: string; otp: string }) => Promise<void>;
  isPlatformCanConnect: (platform: string) => Promise<boolean>;
  isLoading: boolean;
  showVerifyModal: boolean;
  platform: string | null;
  setPlatform: Dispatch<SetStateAction<string | null>>;
  kickUsername?: string;
  discordUsername?: string;
  isDiscordLinked?: boolean;
  hasVerifiedRoleOnDiscordGuild?: boolean;
  isVerifyLoading?: boolean;
};

export const useConnectionsTab = (user: IUserDto): UseConnectionsTabReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showVerifyModal, setShowVerifyModal] = useState<boolean>(false);
  const [externalConnections, setExternalConnections] = useState<ExternalConnection[]>([]);
  const [platform, setPlatform] = useState<string | null>(null);
  const [isVerifyLoading, setIsVerifyLoading] = useState<boolean>(false);

  const { accessFetch } = useAppContext();

  const isPlatformCanConnect = useCallback(
    async (platform: string): Promise<boolean> => {
      try {
        const hypedropCodeResponse = await accessFetch(
          `/user/connection-code?platform=${platform}`
        );
        const hypedropCodeData = await hypedropCodeResponse?.json();
        if (hypedropCodeData.code === "bot is busy") {
          const capitalizePlatform = platform.charAt(0).toUpperCase() + platform.slice(1);
          triggerNotification({
            text: `${capitalizePlatform} verification is currently not available`,
            type: "error",
          });
          return false;
        }
        return true;
      } catch (error) {
        console.error("Error fetching data:", error);
        return false;
      }
    },
    [accessFetch]
  );

  const getConnections = useCallback(async () => {
    try {
      if (user.id) {
        setIsLoading(true);
        const connectionsResponse = await accessFetch(`/user/${user.id}/get-connections`);
        const connectionsData = await connectionsResponse?.json();
        setExternalConnections(connectionsData);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [accessFetch, user.id]);

  const unlinkPlatform = useCallback(
    async ({ platform, otp }: { platform: string; otp: string }) => {
      try {
        setIsLoading(true);
        const unlinkResponse = await accessFetch(`/user/${user.id}/unlink-external-platform`, {
          method: "PATCH",
          headers: {
            "x-otp": otp,
          },
          body: JSON.stringify({
            platform: platform.toLowerCase(),
          }),
        });
        const json = await unlinkResponse?.json();
        if (unlinkResponse && (unlinkResponse.status === 200 || unlinkResponse.status === 201)) {
          triggerNotification({
            text: `${platform} unlinked`,
            type: "success",
          });
          getConnections();
          setShowVerifyModal(false);
          setPlatform(null);
        } else {
          triggerNotification({ text: json?.message, type: "error" });
        }
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [accessFetch, user.id, getConnections]
  );

  useEffect(() => {
    getConnections();
  }, [getConnections]);

  const unlinkDiscord = useCallback(async () => {
    try {
      setIsLoading(true);
      const unlinkResponse = await accessFetch(
        `/user/${user.id}/unlink-discord`,
        {
          method: "PATCH",
        },
        ExceptionHandlingType.AUTOMATIC
      );
      const json = await unlinkResponse?.json();

      if (unlinkResponse && (unlinkResponse.status === 200 || unlinkResponse.status === 201)) {
        setIsLoading(false);
        triggerNotification({ text: "Discord unlinked", type: "success" });
        // fetchUser();
      } else {
        triggerNotification({ text: json?.message, type: "error" });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [accessFetch, user.id]);

  const verifyDiscord = useCallback(async () => {
    try {
      setIsVerifyLoading(true);
      const verifyResponse = await accessFetch(
        `/user/${user.id}/verify-discord`,
        {},
        ExceptionHandlingType.AUTOMATIC
      );
      const data = await verifyResponse?.json();

      triggerNotification({
        text: `Discord ${data?.result ? "" : " not "} verified`,
        type: data?.result ? "success" : "error",
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      triggerNotification({
        text: (error as any).message ?? "Error",
        type: "error",
      });
    } finally {
      setIsVerifyLoading(false);
    }
  }, [accessFetch, user]);

  const kickUsername = Array.isArray(externalConnections)
    ? externalConnections.find((el: ExternalConnection) => el?.platform === "kick")?.username
    : undefined;
  const discordUsername = user?.discordUsername ?? undefined;
  const isDiscordLinked = !!user?.discordId;
  const hasVerifiedRoleOnDiscordGuild = user?.hasVerifiedRoleOnDiscordGuild;

  return {
    unlinkDiscord,
    verifyDiscord,
    isLoading,
    unlinkPlatform,
    isPlatformCanConnect,
    showVerifyModal,
    platform,
    setPlatform,
    isVerifyLoading,
    //
    kickUsername,
    discordUsername,
    isDiscordLinked,
    hasVerifiedRoleOnDiscordGuild,
  };
};
