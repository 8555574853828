"use client";

import { io } from "socket.io-client";
const isBrowser = typeof window !== "undefined";

const URL = `${process.env.NEXT_PUBLIC_APP_BE_ORIGIN ?? "http://localhost:4000"}/events`;
const _io = io(URL, {
  withCredentials: true,
  transports: ["websocket"],
});

export const socket = isBrowser ? _io : ({} as typeof _io);
