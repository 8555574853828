import React from "react";
import styled, { css } from "styled-components";
import { ILeaderboardWithWinnerDto } from "interfaces";
import { LeaderboardHistoryCard } from "components/leaderboard/LeaderboardHistoryCard";

export interface ILeaderboardTopWinnerCardList {
  leaderboards: ILeaderboardWithWinnerDto[];
  doHref?: (leaderboard?: ILeaderboardWithWinnerDto) => string;
}

export const LeaderboardHistoryItems: React.FC<ILeaderboardTopWinnerCardList> = ({
  leaderboards,
  doHref,
}): React.ReactElement => {
  if (!leaderboards.length) {
    return <></>;
  }

  return (
    <List>
      {leaderboards.map((leaderboard, index) => {
        return (
          <Item key={index} order={index + 1}>
            <LeaderboardHistoryCard leaderboard={leaderboard} doHref={doHref} />
          </Item>
        );
      })}
    </List>
  );
};

const List = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 320px));
  justify-content: center;
  gap: 16px;

  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
`;

const Item = styled.div<{ order: number }>`
  box-sizing: border-box;
  display: inline-block;
  ${({ order }) =>
    css`
      order: ${order};
    `}
`;
