import { useAppContext } from "contexts/AppProvider";
import React, { useEffect, useMemo, useState } from "react";

interface IBalanceContext {
  gPointsBalance: number;
  localGPointsBalance: number;
  setIsLocalBalance: React.Dispatch<React.SetStateAction<boolean>>;
  setLocalGPointsBalance: React.Dispatch<React.SetStateAction<number>>;
}

const BalanceContextInitialValues = {
  gPointsBalance: 0,
  localGPointsBalance: 0,
  setIsLocalBalance: () => {},
  setLocalGPointsBalance: () => {},
};

export const BalanceContext = React.createContext<IBalanceContext>(BalanceContextInitialValues);

export const BalanceProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { profile } = useAppContext();
  const [isLocalBalance, setIsLocalBalance] = useState<boolean>(false);
  const [localGPointsBalance, setLocalGPointsBalance] = useState<number>(0);

  const gPointsBalance = useMemo(() => {
    return (isLocalBalance ? localGPointsBalance : profile?.gPointsBalance) ?? 0;
  }, [isLocalBalance, localGPointsBalance, profile?.gPointsBalance]);

  return (
    <BalanceContext.Provider
      value={{ gPointsBalance, setIsLocalBalance, localGPointsBalance, setLocalGPointsBalance }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

export function useBalanceContext() {
  const context = React.useContext(BalanceContext);
  if (context === undefined) {
    throw new Error("useBalanceContext must be used within a BalanceContext");
  }
  return context;
}

export default BalanceProvider;
