import React from "react";
import Countdown, { zeroPad } from "react-countdown";
import GenericNotificationCard from "components/admin/notifications/NotificationCard";
import { NotificationToastProps, NotificationType } from "types/notification";
import { EndedRaffleData } from "types/raffles";
import { css } from "styled-components";

const RaffleEndWrapper = ({ props, notificationData }: NotificationToastProps<EndedRaffleData>) => {
  const { id, deadline } = props;
  const { description, title, info, type } = notificationData;

  const renderer = ({
    hours,
    minutes,
    seconds,
  }: {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    completed: boolean;
  }) => {
    return (
      <p style={{ whiteSpace: "nowrap" }}>
        {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
      </p>
    );
  };

  return (
    <GenericNotificationCard
      containerStyles={css`
        max-width: 240px;
      `}
      title={title}
      description={description}
      type={type}
      info={{
        actionButtonText: info.actionButtonText,
        onActionClickLink: `/raffles/${id}`,
      }}
      contentRenderer={<Countdown date={deadline} renderer={renderer} />}
    />
  );
};

export default RaffleEndWrapper;
