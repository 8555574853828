import { IUserDto } from "interfaces";
import { ITopMenuItem } from "../dto";
import { ADMIN_ROLES, ROLE_TO_PATH_MAPPING } from "helpers/constants";
import {
  Seven,
  Roulette,
  Gift,
  Star,
  SlotMachine,
  SlotsReview,
  ProvablyFair,
} from '../components/icons';

const addWithFeatureFlag = (
  flag: string,
  items: ITopMenuItem[],
  isFeatureEnabled: (code: string) => boolean,
  section: ITopMenuItem,
  group?: ITopMenuItem
) => {
  if (isFeatureEnabled(flag)) {
    if (group) {
      const existedGroup = items.find((i) => i.name === group.name);

      if (existedGroup) {
        if (!existedGroup?.items?.find((i) => i.name === section.name)) {
          let index = items.findIndex((obj) => obj.name === existedGroup.name);
          items[index] = { ...items[index], items: [...(items[index].items ?? []), section] };
        }
      } else {
        items.splice(items.length - 1, 0, {
          ...group,
          items: [...(group.items ?? []), section],
        });
      }
    } else {
      if (!items.find((item) => item.name === section.name)) {
        items.push(section);
      }
    }
  }
};

const filterByRole = (items: ITopMenuItem[], profile?: IUserDto | null, isAdmin?: boolean) => {
  const filteredMenuItems = items
    .filter(
      (item) =>
        (profile && item.type !== "login") ||
        (!profile && item.type !== "logout" && item.type !== "profile")
    )
    .filter((item) => (isAdmin ? true : item.type !== "admin"))
    .map((item) => {
      if (item.type === "admin") {
        const roles = profile?.roles ? profile.roles?.map((role) => role.name) : [];

        const matchedRole = roles.find((role) =>
          ADMIN_ROLES.some((adminRole) => adminRole.name === role)
        );

        const role = matchedRole || "";
        const path = (ROLE_TO_PATH_MAPPING as Record<string, string>)[role] || "/";
        return {
          ...item,
          href: path,
        };
      } else {
        return item;
      }
    });
  return filteredMenuItems;
};

export const filterNavItems = (
  items: ITopMenuItem[],
  isFeatureEnabled: (code: string) => boolean,
  profile?: IUserDto | null,
  isAdmin?: boolean
) => {
  const newItems = items;

  addWithFeatureFlag(
    "ALLOW_FREE_CASES",
    newItems,
    isFeatureEnabled,
    {
      name: "Free Case",
      href: "/free-case",
      image: <Star />
    },
    {
      name: "All Rewards",
      image: <Gift />,
    }
  );

  addWithFeatureFlag(
    "ALLOW_GAMES",
    newItems,
    isFeatureEnabled,
    {
      name: "Games",
      href: "/games",
      image: <Roulette />,
    },
    {
      name: "House Games",
      image: <Roulette />,
    }
  );

  addWithFeatureFlag(
    "ALLOW_GAMES",
    newItems,
    isFeatureEnabled,
    {
      name: "Provably Fair",
      href: "/provably-fair",
      image: <ProvablyFair />
    },
    {
      name: "House Games",
      image: <Seven />,
    }
  );

  addWithFeatureFlag(
    "ALLOW_SLOT_GAMES",
    newItems,
    isFeatureEnabled,
    {
      name: "Slot Games",
      href: "/games/slots",
      image: <SlotMachine />
    },
    {
      name: "Free Slots",
      image: <Seven />,
    }
  );

  addWithFeatureFlag(
    "ALLOW_SLOTS_REVIEW",
    newItems,
    isFeatureEnabled,
    {
      name: "Slots Review",
      href: "/slots",
      image: <SlotsReview />
    },
    {
      name: "Free Slots",
      image: <Seven />,
    }
  );

  return filterByRole(newItems, profile, isAdmin);
};
