import React from "react";
import styled, { css } from "styled-components";

import { IStyles, $styles } from "components";
import { Flex } from "components/common";
import { TheFieldset, TheIcon } from "components/TheComponents";
import { ILeaderboardPrizesModalProps } from "./LeaderboardPrizesModal";
import Switch from "components/TheComponents/TheSwitch";
import Image from "next/image";
import { ManagePrizesButton } from "./LeaderboardCreateLayout";
import { LeaderboardPrize } from "interfaces";
import { addCommasToNumber } from "helpers/addCommasToNumber";
import { PRIZES_OUTSIDE_ADDITIONAL_PRIZES_COUNT } from "helpers/constants";

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  width: 100%;

  ${$styles}
`;

export interface LeaderboardPrizesModalLayoutProps {
  prizes: LeaderboardPrize[];
  randomPrizeThreshold: string;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
  checked: boolean;
  setPayload: (value: any) => void;
}

const LeaderboardPrizesModalLayout: React.FC<LeaderboardPrizesModalLayoutProps> = ({
  prizes,
  setPayload,
  randomPrizeThreshold,
  setChecked,
  checked,
}) => {
  const numProps = { pattern: "^[d]*$", type: "text" };

  const onLabelClick = React.useMemo(() => {
    return (checked: boolean, setChecked: Function) => (event: React.MouseEvent) => {
      event.preventDefault();
      setPayload?.((prev: any) => ({
        ...prev,
        randomPrizes: [],
        randomPrizeThreshold: "",
      }));
      const targetElement = event.target as Element;
      if (!targetElement.closest("a")) {
        setChecked(!checked);
      }
    };
  }, [setPayload]);

  const onCheckboxChange = React.useMemo(() => {
    return () => (event: React.ChangeEvent) => {
      event.preventDefault();
    };
  }, []);

  const addRandomPrize = () => {
    setPayload?.((prev: any) => ({
      ...prev,
      randomPrizes: [
        ...prev.randomPrizes,
        {
          prizeNumber: prev.randomPrizes.length + 1,
          amount: "",
        },
      ],
    }));
  };

  const boxItemWidthStyle = `calc(${
    100 / Math.min(prizes.length + PRIZES_OUTSIDE_ADDITIONAL_PRIZES_COUNT, 5)
  }% - 20px)`;

  return (
    <Flex width="100%" justify="center" align="center" column>
      <Flex width="100%" margin="40px 0px 20px" justify="center" align="center">
        <Label
          onClick={onLabelClick(checked, setChecked)}
          styles={css`
            margin: 0px 10px;
            justify-content: center;
            align-items: center;
            gap: 22px;
          `}
        >
          <Switch checked={checked} onChange={onCheckboxChange()} />
          <Text style={{ marginLeft: 10 }}>RANDOM PRIZES</Text>
        </Label>
      </Flex>
      {checked ? (
        <>
          <Flex margin="20px 0px 20px" width="100%" justify="center" align="center">
            <BoxItem
              styles={css`
                max-width: 250px;
              `}
            >
              <TheFieldset
                styles={css`
                  height: 45px;
                `}
                title="Random Winner Threshold"
              >
                <input
                  {...numProps}
                  placeholder="Random Winner Threshold"
                  value={addCommasToNumber(randomPrizeThreshold.toString())}
                  onChange={(e) => {
                    const input = e.target.value;
                    const digitsOnly = input.replace(/\D/g, ""); // Filter out non-digit characters
                    setPayload((prevPayload: any) => ({
                      ...prevPayload,
                      randomPrizeThreshold: +digitsOnly,
                    }));
                  }}
                />
              </TheFieldset>
            </BoxItem>
          </Flex>
          <Flex style={{ gap: 20 }} width="100%" flexWrap>
            {prizes.map((randomPrize: { amount: number }, index: number) => (
              <BoxItem
                key={index}
                style={{
                  flex: `1 0 ${boxItemWidthStyle}`,
                  position: "relative",
                }}
              >
                <TheFieldset
                  styles={css`
                    height: 45px;
                    margin: 0;
                  `}
                  title={`Prize ${index + 1}`}
                >
                  <input
                    {...numProps}
                    placeholder={`Prize ${index + 1}`}
                    value={addCommasToNumber(randomPrize.amount.toString())}
                    onChange={(e) => {
                      const input = e.target.value;
                      const digitsOnly = input.replace(/\D/g, ""); // Filter out non-digit characters

                      setPayload((prevPayload: any) => {
                        const updatedRandomPrizes = prevPayload.randomPrizes.map(
                          (randomPrize: any, i: number) =>
                            i === index ? { ...randomPrize, amount: +digitsOnly } : randomPrize
                        );

                        return {
                          ...prevPayload,
                          randomPrizes: updatedRandomPrizes,
                        };
                      });
                    }}
                  />
                </TheFieldset>
                {index + 1 === prizes.length ? (
                  <div
                    style={{ position: "absolute", right: 10, top: 18, cursor: "pointer" }}
                    onClick={() =>
                      setPayload((prev: any) => ({
                        ...prev,
                        randomPrizes: prev.randomPrizes.slice(0, -1),
                      }))
                    }
                  >
                    <TheIcon icon="action:remove" size={16} />
                  </div>
                ) : null}
              </BoxItem>
            ))}
          </Flex>
          <Flex
            style={{
              border: "1px solid rgba(36, 39, 70, 0.5)",
              borderRadius: 8,
              cursor: "pointer",
              height: 40,
              marginTop: 25,
              marginBottom: 20,
            }}
            width="100%"
            background="rgba(36, 39, 70, 0.5)"
            height={37}
            align="center"
            onClick={addRandomPrize}
            justify="center"
          >
            <Image
              style={{ marginRight: 8 }}
              src="/images/icons/add-icon.png"
              alt="plus"
              width={15}
              height={15}
            />
            <ManagePrizesButton>Add Random Prize</ManagePrizesButton>
          </Flex>
        </>
      ) : null}
    </Flex>
  );
};

export default LeaderboardPrizesModalLayout;

const Label = styled.label<IStyles>`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;

  ${$styles}
`;
const Text = styled.label<IStyles>`
  font-family: var(--font-family-golos);
  font-size: 15px;
  font-style: italic;
  font-weight: 800;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-align: center;
  white-space: nowrap;
  ${$styles};
`;
