import React from "react";
import { Button, ButtonBlue, Flex } from "components/common";
import styled, { css } from "styled-components";
import Image from "next/image";

interface FailedPurchaseLayoutProps {
  onClose: () => void;
}

const FailedPurchaseLayout: React.FC<FailedPurchaseLayoutProps> = ({
  onClose,
}): React.ReactElement => {
  const ButtonFlex = styled(Flex)`
    margin-top: 65px !important;
    margin: auto;
    @media (min-width: 600px) {
      width: 50%;
    }
  `;

  return (
    <Flex height="100%" justify="center" column>
      <Flex width="100%" align="center" column>
        <Image alt="guy" width={128} height={122} src="/images/PointShopComponent/sad-pepe.png" />
        <ButtonFlex>
          <Button
            styles={css`
              margin: auto;
            `}
            onClick={onClose}
          >
            <ButtonBlue>Okay :(</ButtonBlue>
          </Button>
        </ButtonFlex>
      </Flex>
    </Flex>
  );
};

export default FailedPurchaseLayout;
