import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  :root {
    --scrollbar-color: #152C46;
    --scrollbar-bg-color: #121723; 
  }

html,
body {
  color: #ffffff;
  padding: 0;
  margin: 0;
  font-family: var(--font-family-golos) ;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-color) var(--scrollbar-bg-color);
}

  /* For Webkit-based browsers (Chrome, Safari, etc.) */
  ::-webkit-scrollbar {
    width: 12px;
    width: 0;
  }

  ::-webkit-scrollbar-track {
    background-color: var(--scrollbar-bg-color);
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--scrollbar-color);
    border-radius: 6px;
  }


  /* Firefox-specific styling */
  @-moz-document url-prefix() {
    body {
      scrollbar-width: thin;
      scrollbar-color: var(--scrollbar-color) var(--scrollbar-bg-color);
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  * {
    box-sizing: border-box;
  }
`;
