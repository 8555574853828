import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles, Ident, TheIcon, LeaderboardHistoryItems, Pages } from "components";
import { ILeaderboardWithWinnerDto, TheLeaderboardSourceKeys } from "interfaces";
import { Button, ButtonBlue, Flex, NoEntitiesText } from "components/common";
import { useRouter } from "next/router";
import LeaderboardProviderSwitcher from "./LeaderboardProviderSwitcher";
import LeaderboardProviderBG from "./LeaderboardProviderBG";
import WrewardsButton from "components/wrewards-ui-kit/Button";

export interface ILeaderboardTopWinnersBlock {
  title?: JSX.Element | string;
  switcher: string;
  setSwitcher: (switcher: string) => void;
  leaderboards: ILeaderboardWithWinnerDto[];
  showBackground?: boolean;
  showBlockHeaderLogo?: boolean;
  showBlockHeader?: boolean;
  showSwitcher?: boolean;
  showPages?: boolean;
  showViewHistoryButton?: boolean;
  range?: number;
  skip?: number;
  take?: number;
  total?: number;
  onPage?: (index: number) => void;
  doHref?: (leaderboard?: ILeaderboardWithWinnerDto) => string;
}

export const LeaderboardTopWinnersBlock: React.FC<ILeaderboardTopWinnersBlock> = ({
  title = <>You can check out the previous leaderboards and top winners below.</>,
  switcher = TheLeaderboardSourceKeys.gamdom,
  setSwitcher,
  leaderboards,
  showBackground = false,
  showBlockHeaderLogo = false,
  showBlockHeader = false,
  showSwitcher = true,
  showPages = false,
  showViewHistoryButton = false,
  range,
  skip,
  take,
  total,
  onPage,
  doHref,
}): React.ReactElement => {
  const router = useRouter();

  return (
    <Box className="container-fixed-w">
      {showBackground && <LeaderboardProviderBG casinoProvider={switcher} />}
      {showBlockHeaderLogo && showBlockHeader ? (
        <>
          {/* <Ident height={40} /> */}
          <BoxItem>
            <TheIcon icon="wcrown:40" size={40} />
          </BoxItem>
          {/* <Ident height={8} /> */}
          <TextTitle>
            <span data-blue>LEADERBOARD</span>
          </TextTitle>
          {/* <Ident height={16} />
          <TextSubTitle>History</TextSubTitle>
          <Ident height={32} /> */}
        </>
      ) : showBlockHeader ? (
        <>
          {/* <Ident height={87} /> */}
          <TextTitle>
            <span data-blue>Leaderboard History</span>
          </TextTitle>
          {/* <Ident height={16} /> */}
          {/* <TextSubTitle>History</TextSubTitle> */}
          {/* <Ident height={32} /> */}
        </>
      ) : null}
      {showSwitcher ? (
        <>
          <BoxItem style={{zIndex:10, position: 'relative'}}>
            <LeaderboardProviderSwitcher value={switcher} onChange={setSwitcher} />
            {/* <SlideSwitcher title={title} value={switcher} onChange={setSwitcher} /> */}
          </BoxItem>
          <Ident height={46} />
        </>
      ) : null}
      <BoxItem>
        {leaderboards?.length ? (
          <LeaderboardHistoryItems leaderboards={leaderboards} doHref={doHref} />
        ) : (
          <NoEntitiesText>No leaderboard history</NoEntitiesText>
        )}
      </BoxItem>
      <Ident height={40} />
      {showPages && leaderboards?.length ? (
        <>
          <Ident height={72} />
          <BoxItem styles={cssList}>
            <Pages
              name="Items"
              range={range}
              skip={skip}
              take={take}
              total={total}
              showInfo={false}
              onClick={onPage}
            />
          </BoxItem>
          <Ident height={101} />
        </>
      ) : null}
      {showViewHistoryButton ? (
        <>
          <Ident height={56} />
          <Flex width="100%" justify="center">
            <WrewardsButton
              style={{ maxWidth: 200 }}
              onClick={(event: React.MouseEvent) => {
                event.preventDefault();
                router.push("/leaderboards/history");
              }}
              variant='game-action-blue'
            >
              View History
            </WrewardsButton>
          </Flex>
          <Ident height={77} />
        </>
      ) : null}
    </Box>
  );
};

const cssList = css`
  max-width: 1007px;
  align-items: left;
  margin: 0 auto;
`;

const Box = styled.div`
  box-sizing: border-box;
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  text-align: center;
  ${({ styles }) => styles}
`;

const Text = styled.div`
  ${$stylesReset}
  color: rgba(210, 234, 255, 0.75);
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;
`;

// const TextSubTitle = styled(Text)`
//   color: rgb(210, 234, 255); // #d2eaff;
//   font-size: 20px;
//   font-style: normal;
//   font-weight: 600;
//   letter-spacing: 0.03em;
//   text-transform: uppercase;

//   @media (max-width: 800px) {
//     color: #d2eaff;
//     font-size: 28px;
//   }
// `;

const TextTitle = styled(Text)`
  color: var(--daylight-daylight-main, #d5e0f5);
  font-family: var(--font-family-exo);
  font-size: 20px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  padding: 2rem 1rem;
  text-align: left;
  position: relative;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.55);

`;
