import React from "react";
import styled from "styled-components";
import { SlotGrid } from "components/games/SlotGames/elements";
import { useMediaQuery } from "react-responsive";
import SlotGameCardNew from "components/new-games/SlotGameCardNew";
import useSlotGames, { FindSlotGamesSort } from "hooks/slots/useSlotGames";

export interface ISlotsComponent {
  id?: string;
}

export const SlotsComponent: React.FC<ISlotsComponent> = ({ id }): JSX.Element => {
  const xl = useMediaQuery({
    query: "(max-width: 1920px) and (min-width: 940px)",
  });
  const xxl = useMediaQuery({
    query: "(min-width: 1920px)",
  });
  const { data } = useSlotGames({
    take: xxl ? 10 : xl ? 8 : 6,
    sort: FindSlotGamesSort.MOST_POPULAR,
  });
  return (
    <>
      <Box id={id} className="container">
        <SectionSlotGrid>
          {data?.data?.map?.((slot) => {
            return (
              <SlotGameCardNew
                codeSlug={slot.codeSlug}
                key={slot.code}
                code={slot.code}
                imageCode={slot.imageCode}
                name={slot.name}
                provider={slot.provider}
                isFavorite={slot.isFavorite}
              />
            );
          })}
        </SectionSlotGrid>
      </Box>
    </>
  );
};

const Box = styled.div`
  padding: 16px;
`;

const SectionSlotGrid = styled(SlotGrid)`
  grid-template-columns: repeat(6, 1fr);

  @media (min-width: 1921px) {
    grid-template-columns: repeat(10, 1fr);
  }

  @media (max-width: 1920px) {
    grid-template-columns: repeat(8, 1fr);
  }

  @media (max-width: 939px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 767px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 670px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;
