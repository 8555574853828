import { SVGProps } from "react";

export type CrownProps = SVGProps<SVGSVGElement>;

const Crown: React.FC<CrownProps> = (props) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5626 10.2448L20.5639 19.0767C20.5242 19.2614 20.4214 19.4266 20.2731 19.5438C20.1248 19.6609 19.9403 19.7227 19.7514 19.7185H4.18385C3.99491 19.7227 3.81041 19.6609 3.66211 19.5438C3.51381 19.4266 3.41099 19.2614 3.37135 19.0767L1.4376 10.2448C1.40506 10.0956 1.41524 9.94017 1.46697 9.79646C1.51871 9.65275 1.60991 9.52653 1.7301 9.43229C1.85063 9.3377 1.99544 9.27913 2.14783 9.26331C2.30022 9.2475 2.45397 9.27509 2.59135 9.34291L7.33635 11.6423L11.2689 4.68729C11.3397 4.56224 11.4425 4.45824 11.5667 4.38588C11.6908 4.31352 11.832 4.27539 11.9757 4.27539C12.1195 4.27539 12.2606 4.31352 12.3848 4.38588C12.509 4.45824 12.6117 4.56224 12.6826 4.68729L16.6151 11.6504L21.3926 9.33479C21.531 9.26144 21.6876 9.22964 21.8436 9.24322C21.9996 9.2568 22.1484 9.31517 22.272 9.41131C22.3956 9.50746 22.4888 9.63727 22.5403 9.78514C22.5919 9.93301 22.5996 10.0926 22.5626 10.2448Z"
        fill={props.fill ?? "#E9F4FF"}
      />
    </svg>
  );
};

export default Crown;
