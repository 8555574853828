
const formattedCurrency = new Intl.NumberFormat('en-US', {
    maximumFractionDigits: 2,
    minimumFractionDigits: 0
});

export function formatNumber(value: number): string {
    const item = formattedCurrency.format(value ?? 0);
    return item;
}
