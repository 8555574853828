import Link from "next/link";
import styled from "styled-components";

export const ProfileTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 64px;
`;

export const ProfileTabBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ProfileTabHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ProfileTabTitle = styled.h2`
  font-family: Golos Text;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #d5e0f5;
`;

export const ProfileTabDescription = styled.p`
  font-family: Golos Text;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  color: #abbadb;
`;

export const DescriptionLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.75;
  }
`;
