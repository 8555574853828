export type TTheLeaderboardSourceKeys =
  | "GAMDOM"
  | "HYPEDROP"
  | "PACKDRAW"
  | "WREWARDS"
  | "CLASH"
  | "CASES"
  | "RAIN"
  | "LUXDROP";

export const TheLeaderboardSourceKeys = {
  gamdom: "GAMDOM",
  hypedrop: "HYPEDROP",
  packdraw: "PACKDRAW",
  wrewards: "WREWARDS",
  clash: "CLASH",
  cases: "CASES",
  rain_gg: "RAIN",
  luxdrop: "LUXDROP",
};
