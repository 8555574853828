import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles } from "components";
import { Button, ButtonBlue } from "components/common";
import { useRouter } from "next/router";
import { JackInTheBox } from "react-awesome-reveal";

export interface IRafflesComponent {
  id?: string;
}

export const RafflesComponent: React.FC<IRafflesComponent> = ({ id }): JSX.Element => {
  const router = useRouter();
  return (
    <>
      <Box id={id}>
        <Screen>
          <Content>
            <Side data-text>
              <TheTitle>
                <span>Join</span>
                &nbsp;
                <span data-gold>exclusive</span>
                &nbsp;
                <span data-blue>Raffles</span>
              </TheTitle>
              <TheMessage>
                Exclusive Raffles for users under any of the WGTV codes, get lucky & join for FREE
                or for W Points.
                <br />
                Win amazing cash prizes and special casino bonuses!
              </TheMessage>
              <Button
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  router.push("/raffles");
                }}
                style={{ height: 50, cursor: "pointer", width: 209 }}
              >
                <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
                  JOIN RAFFLE
                </ButtonBlue>
              </Button>
            </Side>
            <Side data-pic>
              <JackInTheBox triggerOnce>
                <Img
                  src="/images/RafflesComponent/main-coin.png"
                  alt="raffle"
                  width={405}
                  height={420}
                  styles={css`
                    @media (max-width: 600px) {
                      width: 255px;
                      height: 270px;
                    }
                    animation: float ease 10s infinite;
                  `}
                />
              </JackInTheBox>
              <AbsoluteDiv
                styles={css`
                  right: -90px;
                  top: 0px;
                  animation: float ease 5s infinite;
                `}
              >
                <JackInTheBox triggerOnce>
                  <Img
                    src="/images/RafflesComponent/right-coin.png"
                    alt="raffle"
                    width={164}
                    height={164}
                  />
                </JackInTheBox>
              </AbsoluteDiv>
              <AbsoluteDiv
                styles={css`
                  left: -20px;
                  top: 5px;
                  animation: float ease 8.3s infinite;
                `}
              >
                <JackInTheBox triggerOnce>
                  <Img
                    src="/images/RafflesComponent/left-coin.png"
                    alt="raffle"
                    width={99}
                    height={85}
                  />
                </JackInTheBox>
              </AbsoluteDiv>
              <AbsoluteDiv
                styles={css`
                  left: -20px;
                  bottom: 5px;
                  animation: float ease 7s infinite;
                  @media (max-width: 600px) {
                    bottom: -10px;
                  }
                `}
              >
                <JackInTheBox triggerOnce>
                  <Img
                    src="/images/RafflesComponent/bottom-coin.png"
                    alt="raffle"
                    width={60}
                    height={47}
                  />
                </JackInTheBox>
              </AbsoluteDiv>
            </Side>
          </Content>
        </Screen>
      </Box>
    </>
  );
};

const Box = styled.div`
  ${$stylesReset}
  background-color: #0b0e16;
`;

const AbsoluteDiv = styled.div<IStyles>`
  position: absolute;
  z-index: 1;
  ${({ styles }) => styles}
`;

const Screen = styled.div`
  ${$stylesReset}
  position: relative;
  max-width: 1920px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  overflow: hidden;
`;

const Content = styled.div`
  ${$stylesReset}
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
  margin: 24px;
  padding-top: 50px;
  & [data-pic] {
    order: 1;
  }
  & [data-text] {
    order: 2;
  }
  @media (min-width: 1600px) {
    // 1068px
    & [data-pic] {
      order: 2;
    }
    & [data-text] {
      order: 1;
    }
    flex-direction: row;
    gap: 48px;
    max-width: 1152px; // 1068px;
    max-height: 630px;
    padding-top: calc(82px - 24px);
    padding-bottom: calc(90px - 24px);
  }
`;

const Side = styled.div`
  --width: 556px;
  --height: 500px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &[data-pic] {
    height: var(--height);
  }
  &[data-text] {
    /* text-align: center; */
  }
  @media (max-width: 600px) {
    &[data-pic] {
      height: calc(var(--height) * 0.6);
    }
    &[data-text] {
      width: 100%;
    }
  }
  @media (max-width: calc( 1068px - 1 )) {
    max-width: var(--width);
    width: 100%;
  }
  @media (min-width: 1068px) {
    width: var(--width);
    &[data-text] {
      align-items: flex-start;
      height: var(--height);
      text-align: left;
    }
  }
`;


const Img = styled(Image)<IStyles>`
  ${({ styles }) => styles}
`;

const TheTitle = styled.div`
  /* width: 463px; */
  font-size: 24px;
  @media (min-width: 1068px) {
    font-size: 40px;
  }
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 130%;
  text-transform: uppercase;

  // Gloden letters
  & span[data-gold] {
    color: #f2c94c;
  }

  // Blue letters
  & span[data-blue] {
    color: #3b8be9;
  }
`;

const TheMessage = styled.div`
  /* width: 464px; */
  margin: 24px 0 48px;
  letter-spacing: 0.02em;
  line-height: 150%;
  font-weight: 500;
  color: rgba(210, 234, 255, 0.75);
`;
