import React from "react";
import styled, { css } from "styled-components";

export const SwitchContainer = styled.label<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}
`;

export const Input = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

export const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 34px;
  transition: 0.4s;

  &:before {
    content: "";
    position: absolute;
    height: 16px;
    width: 16px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    border-radius: 50%;
    transition: 0.4s;
  }
`;

export const CheckedSlider = styled(Slider)`
  background-color: #2196f3;

  &:before {
    transform: translateX(16px);
  }
`;

const Switch = ({
  checked,
  disabled = false,
  onChange,
}: {
  disabled?: boolean;
  checked: boolean;
  onChange: (event: React.ChangeEvent) => void;
}) => {
  return (
    <SwitchContainer disabled={disabled}>
      <Input type="checkbox" checked={checked} onChange={onChange} />
      {checked ? <CheckedSlider /> : <Slider />}
    </SwitchContainer>
  );
};

export default Switch;
