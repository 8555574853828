import { memo } from "react";
import styled from "styled-components";
import { ISwitchButton } from "types/webkit/switch-buttons";

interface ISwitchButtonsProps {
  buttons: ISwitchButton[];
}

const SwitchButtonsSquaredComponent = ({ buttons }: ISwitchButtonsProps) => {
  return (
    <SwitchButtonsContainer>
      <SwitchButtons>
        {buttons.map((button, index) => (
          <SwitchButton
            key={index}
            disabled={button.disabled}
            isActive={button.isActive}
            onClick={() => {
              button.onClick();
            }}
          >
            {button.text}
          </SwitchButton>
        ))}
      </SwitchButtons>
    </SwitchButtonsContainer>
  );
};

const SwitchButtonsSquared = memo(SwitchButtonsSquaredComponent);
export default SwitchButtonsSquared;

export const SwitchButtonsContainer = styled.div`
  display: flex;
  border-radius: 0.375rem;
  padding: 0.375rem 0.375rem 0;
  gap: 0.2rem;
  overflow: hidden;
  overflow-x: auto;
  width: 100%;
`;

export const SwitchButtons = styled.div`
  display: flex;
  overflow: auto;
  overflow-y: hidden;
  padding-bottom: 0.375rem;
  width: 100%;
  gap: 0.2rem;
`;

export const SwitchButton = styled.button<{ isActive: boolean }>`
  transition: 0.2s all;
  border: 0;
  position: relative;
  height: 48px;
  flex: 1 1;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  color: ${({ isActive }) => (isActive ? " white" : "#e9f4ff80")};
  font-size: 0.875rem;

  background-color: ${({ isActive }) => (isActive ? "var(--cloudy)" : "var(--dark-ghosty)")};
  &:disabled {
    color: rgb(73, 76, 104);
    cursor: not-allowed;
    opacity: 1;
  }

  &:active {
    transform: scale(0.95);
  }

  &:hover {
    background: ${({ isActive }) => (isActive ? " " : "#1d2234")};
  }
`;
