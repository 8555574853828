import { useQuery } from "@tanstack/react-query";
import { useAppContext } from "contexts";
import { IDtoLeaderboardUser } from "interfaces";

export default function useLeaderboardCurrentUser(leaderboardId?: string, enabled?: boolean) {
  const { accessFetch, profile } = useAppContext();
  return useQuery<IDtoLeaderboardUser>({
    queryKey: ["leaderboard-current-user-position", leaderboardId, profile?.id],
    queryFn: async () => {
      const userResponse = await accessFetch(`/leaderboard-user/${leaderboardId}/me`, undefined);

      const userData = await userResponse?.json();

      if (!userResponse?.ok) {
        return Promise.reject(userData);
      }

      return userData;
    },
    retry: false,
    enabled: enabled && !!leaderboardId,
  });
}
