import React from "react";
import { $styles, $stylesReset, Button, ButtonBlue, Flex, IStyles } from "components/common";
import styled, { css } from "styled-components";
import Image from "next/image";

interface AuthEnabledModalProps {
  onClose: () => void;
}

const AuthEnabledModal: React.FC<AuthEnabledModalProps> = ({ onClose }): React.ReactElement => {
  const ButtonFlex = styled(Flex)`
    margin-top: 15px !important;
    margin: auto;
    @media (min-width: 600px) {
      width: 50%;
    }
  `;

  return (
    <Flex justify="center" column>
      <Image src={"/images/HeaderComponent/logo.svg"} width={170} height={32} alt="logo" />{" "}
      <Title
        styles={css`
          text-align: center;
        `}
      >
        Second factor authentification was enabled
      </Title>
      <Flex width="100%" align="center" column>
        <Text>
          Second factor authentification was successfully enabled ! from now on, during the login
          you will need to enter the code from the application.{" "}
        </Text>
        <ButtonFlex>
          <Button
            styles={css`
              margin: auto;
            `}
            onClick={onClose}
          >
            <ButtonBlue>Continue</ButtonBlue>
          </Button>
        </ButtonFlex>
      </Flex>
    </Flex>
  );
};

export default AuthEnabledModal;

const Title = styled.div<IStyles>`
  font-family: var(--font-family-golos);
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(210, 234, 255, 1);
  padding: 10px 0px;

  text-transform: uppercase;

  ${$styles}
`;
const Text = styled.div<IStyles>`
  ${$stylesReset}
  font-family: var(--font-family-golos);
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: center;

  ${$styles}
`;
