import React from "react";
import styled, { css } from "styled-components";
import { IStyles, $styles } from "components";
import { Button, ButtonBlue, Flex } from "components/common";
import { IAppContext, useAppContext, withApp } from "contexts";

const Box = styled.div<IStyles>`
  box-sizing: border-box;
  max-width: 586px;
  z-index: 99;

  // text
  color: rgba(210, 234, 255, 0.75);
  font-family: var(--font-family-golos);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.02em;
  line-height: 150%;
  text-align: center;

  ${$styles}
`;

const BoxItem = styled.div<IStyles>`
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${$styles}
`;

const BoxForm = styled.form<IStyles>`
  box-sizing: border-box;
`;

const Logo = styled.div<IStyles>`
  --width: 170px;
  --height: 32px;
  box-sizing: border-box;
  width: var(--width);
  height: var(--height);
  background-clip: border-box;
  background-image: url("/images/TwitchLoginFrameComponent/WRewards.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: var(--width) var(--height);

  ${$styles}
`;

const Title = styled.div<IStyles>`
  color: #d2eaff;
  font-family: var(--font-family-golos);
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  letter-spacing: 0.03em;
  line-height: 23px;
  text-transform: uppercase;

  ${$styles}
`;

interface LogoutModalProps {
  onClose: (action: string) => void;
  onLogout: () => void;
}

const LogoutLayout: React.FC<LogoutModalProps & IAppContext> = ({
  onClose,
  accessFetch,
  onLogout,
}): React.ReactElement => {
  const { apiService } = useAppContext();

  const fetchData = React.useCallback(async () => {
    try {
      await accessFetch("/auth/logout");
      onLogout();
      apiService.reconnectSocket();
      onClose("logout");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [accessFetch, onLogout, apiService, onClose]);

  return (
    <Box>
      <BoxForm>
        <BoxItem
          styles={css`
            margin-top: 16px;
          `}
        >
          <Logo />
        </BoxItem>
        <Flex margin="20px 0px 0px" column>
          <BoxItem
            styles={css`
              margin: 0;
            `}
          >
            <Title>DO YOU WANT TO SIGN OUT?</Title>
          </BoxItem>
          <BoxItem
            styles={css`
              margin-top: 16px;
              padding: 0 9px;
            `}
          >
            You are about to end your current session. <br />
            Are you certain you want to log out now?
          </BoxItem>
        </Flex>
        <Flex margin="40px 0px 0px" justify="center" width="100%">
          <Button
            onClick={() => onClose("logout")}
            styles={css`
              margin-right: 20px;
              border: 1px #469df2 solid;
            `}
            isDark
          >
            <ButtonBlue style={{ color: "#469df2", cursor: "pointer" }} isDark>
              Cancel
            </ButtonBlue>
          </Button>
          <Button onClick={fetchData}>
            <ButtonBlue>Sign Out</ButtonBlue>
          </Button>
        </Flex>
      </BoxForm>
    </Box>
  );
};

export default withApp(LogoutLayout);
