import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles } from "./$styles";
import { fixed } from "helpers";

export interface ITotalGivenCounter {
  length?: number;
  value?: number;
}

export interface ITotalGivenCounterItem {
  isDelimiter?: boolean;
  isGreat?: boolean;
  value?: string;
}

export const TotalGivenCounter: React.FC<ITotalGivenCounter & IStyles> = ({
  length = 1,
  value = 0,
  styles,
}): JSX.Element => {
  const items: ITotalGivenCounterItem[] = React.useMemo(() => {
    const format = "0,0";
    const formated = fixed(format, value, length);

    const stringWithoutLeadingZeros = formated.replace(/^0+/, "");

    const normalAmount = stringWithoutLeadingZeros.length;

    const values = formated.split("").reverse();

    return values
      .map((value: string, index: number, items: string[]): ITotalGivenCounterItem => {
        if (!Number.isInteger(+value)) {
          return {};
        }
        const itemPrev = index < items.length - 1 ? +items[index + 1] : 0;
        const isDelimiter = !Number.isInteger(itemPrev);
        const isGreat = normalAmount <= index;
        return { isDelimiter, isGreat, value };
      })
      .filter((item: ITotalGivenCounterItem) => {
        return item?.value;
      })
      .reverse();
  }, [length, value]);

  return (
    <Box styles={styles}>
      <List>
        <DigitUnit>$</DigitUnit>
        {items
          .filter((item) => {
            return item?.value;
          })
          .map(({ isGreat, isDelimiter, value = "" }, index) => (
            <DigitValue key={index} isGreat={isGreat} isDelimiter={isDelimiter}>
              {value}
            </DigitValue>
          ))}
      </List>
    </Box>
  );
};

const Box = styled.div<IStyles>`
  ${$stylesReset}
  ${({ styles }) => styles}
  @media (min-width: 941px) {
    margin-left: auto;
  }
`;

const List = styled.div`
  overflow: hidden;
  display: inline-flex;
  background-color: var(--global-bg-dark);
  border-radius: 8px;
`;

const Digit = styled.div`
  ${$stylesReset}
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 87px;
  padding: 16px;
  box-sizing: border-box;
  font-family: var(--font-family-exo);
  font-style: italic;
  font-weight: 900;
  line-height: 30px;
  text-transform: uppercase;
  &:not(:last-child) {
    border-right: 1px solid rgba(27, 32, 53, 1);
  }
  @media (max-width: 670px) {
    height: 48px;
    width: 38px;
  }
`;

const DigitUnit = styled(Digit)`
  font-size: 56px;
  color: var(--pure-sky);
  padding: 0 24px;

  @media (max-width: 670px) {
    font-size: 26px;
  }

  @media (min-width: 671px) {
    width: unset;
  }
`;

const DigitValue = styled(Digit)<ITotalGivenCounterItem>`
  color: var(--global-border-grey);
  font-size: 50px;
  ${({ isGreat }) =>
    isGreat
      ? css`
          color: rgba(210, 234, 255, 0.25);
        `
      : css`
          color: rgb(210, 234, 255);
        `}

  ${({ isDelimiter }) =>
    isDelimiter
      ? css`
          &::before {
            content: ",";
            position: absolute;
            width: 20px;
            height: 28px;
            left: -0.15em;
            margin-bottom: -0.6em;
            z-index: 1;
            line-height: 0;
            background-color: var(--global-bg-dark);
            color: var(--global-border-grey);

            @media (max-width: 670px) {
              width: 10px;
              height: 12px;
              margin-bottom: -1em;
            }
          }
        `
      : null}

@media (max-width: 670px) {
    font-size: 18px;
  }
`;
