/* eslint-disable react/display-name */
import React, { forwardRef, HTMLAttributes, CSSProperties, Dispatch } from "react";
import { SetStateAction } from "react";
import { IReward } from "./StartEarningRewardsComponent";
import StartEarningRewardsComponentCard from "./StartEarningRewardsComponentCard";

export type ItemСomponentProps = HTMLAttributes<HTMLDivElement> & {
  id: string;
  withOpacity?: boolean;
  isDragging?: boolean;
  item: IReward;
  onOpenEdit: () => void;
  onOpenDelete: () => void;
  setSelectedReward: Dispatch<SetStateAction<IReward | null>>;
  isAdminPage?: boolean;
  disableDND?: boolean;
};

const Item = forwardRef<HTMLDivElement, ItemСomponentProps>(
  (
    {
      id,
      isDragging,
      style,
      withOpacity,
      disableDND,
      item,
      isAdminPage,
      onCopy,
      onOpenDelete,
      onOpenEdit,
      setSelectedReward,
      ...props
    },
    ref
  ) => {
    const inlineStyles: CSSProperties = {
      opacity: withOpacity ? "0.5" : "1",
      transformOrigin: "50% 50%",
      cursor: !disableDND ? (isDragging ? "grabbing" : "grab") : "default",
      display: "flex",
      color: "black",
      width: "calc(100% / 3 - 12px)",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: isDragging
        ? "rgb(63 63 68 / 5%) 0px 2px 0px 2px, rgb(34 33 81 / 15%) 0px 2px 3px 2px"
        : "none",
      transform: isDragging ? "scale(1.05)" : "none",
      ...style,
    };

    return (
      <div ref={ref} style={inlineStyles} {...props}>
        <StartEarningRewardsComponentCard
          item={item}
          isAdminPage={isAdminPage}
          onOpenDelete={onOpenDelete}
          onOpenEdit={onOpenEdit}
          setSelectedReward={setSelectedReward}
          {...props}
        />
      </div>
    );
  }
);

export default Item;
