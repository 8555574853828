import React from "react";
import { $styles, $stylesReset, Button, ButtonBlue, Flex, IStyles } from "components/common";
import styled, { css } from "styled-components";
import Image from "next/image";

interface RecoveryCodesModalProps {
  onClose: () => void;
  recoveryCodes: string[];
}

const RecoveryCodesModal: React.FC<RecoveryCodesModalProps> = ({
  onClose,
  recoveryCodes,
}): React.ReactElement => {
  const ButtonFlex = styled(Flex)`
    margin-top: 15px !important;
    margin: auto;
    @media (min-width: 600px) {
      width: 50%;
    }
  `;

  return (
    <Flex justify="center" column>
      <Image src={"/images/HeaderComponent/logo.svg"} width={170} height={32} alt="logo" />
      <Title
        styles={css`
          text-align: center;
        `}
      >
        Save your recovery codes
      </Title>
      <Flex width="100%" align="center" column>
        <Text
          styles={css`
            text-align: left;
            margin: 10px 0px;
          `}
        >
          Use these recovery codes to log in to your account if your device is lost. Each code can
          only be used once. We suggest taking a screenshot and saving it somewhere safe and
          inaccessible.
        </Text>
        <Card>
          <GridContainer>
            {recoveryCodes?.map((c, index) => (
              <Text key={index}>{c}</Text>
            ))}
          </GridContainer>
        </Card>
        <Flex margin="20px 0px" justify="center" align="center">
          <Image
            style={{ marginRight: 10 }}
            src={"/images/TheIcon/info.png"}
            height={25}
            width={25}
            alt="info"
          />
          <Text>Please save them now because they won’t be shown again.</Text>
        </Flex>
        <ButtonFlex>
          <Button
            styles={css`
              margin: auto;
            `}
            onClick={onClose}
          >
            <ButtonBlue>Continue</ButtonBlue>
          </Button>
        </ButtonFlex>
      </Flex>
    </Flex>
  );
};

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 8px; /* Gap between items */
`;

export default RecoveryCodesModal;

const Card = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding: 24px;
  gap: 8px;
  width: 100%;
  // height: 115px;
  margin-top: 10px;
  color: #0c8ce9;
  border: 1px solid #0c8ce9;
  background: #0b101b;
  border-radius: 8px;
`;

const Text = styled.div<IStyles>`
  ${$stylesReset}
  font-family: var(--font-family-golos);
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  text-align: center;

  ${$styles}
`;

const Title = styled.div<IStyles>`
  font-family: var(--font-family-golos);
  font-size: 20px;
  font-style: italic;
  font-weight: 900;
  line-height: 23px;
  letter-spacing: 0.03em;
  text-align: left;
  color: rgba(210, 234, 255, 1);
  padding: 10px 0px;

  text-transform: uppercase;

  ${$styles}
`;
