import React, { useState } from "react";
import styled, { css } from "styled-components";
import { IStyles, $styles, Button, ButtonBlue, Flex } from "components";
import { TheButton, TheFieldset } from "components/TheComponents";
import { useDebouncedCallback } from "use-debounce";
import Image from "next/image";
import { TAppAccessFetch } from "contexts";
import { TheSelect } from "components/TheComponents/TheSelect";
import { useMediaQuery } from "@chakra-ui/react";

export interface TableHeader {
  setValue: (value: string) => void;
  setSorter: (value: string) => void;
  refetchTable: () => void;
  onOpenRestock: () => void;
  onOpenCreate: () => void;
  isAdminPage: boolean | undefined;
  accessFetch: TAppAccessFetch;
  setSkip: (skip: number) => void;
  skip: number;
}

const BoxContent = styled.div<IStyles>`
  box-sizing: border-box;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
  width: 100%;

  ${$styles}
`;

const BoxItem = styled.div<IStyles>`
  width: 100%;
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;

  ${$styles}
`;

const ButtonTitle = styled.div<IStyles>`
  box-sizing: border-box;
  display: inline-flex;
  color: rgba(210, 234, 255, 0.75);
  font-family: var(--font-family-golos);
  font-size: 18px;
  font-style: italic;
  font-weight: 800;
  letter-spacing: 0.02em;
  line-height: 21px;
  text-transform: uppercase;
  ${$styles}
`;

const ToggleButton = styled(Flex)`
  align-items: center;
  gap: 16px;
  color: #abbadb;
  font-family: "Golos Text";
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
`;

const ButtonIcon = styled.div`
  display: flex;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 19px 16px;
  align-items: center;
  border-radius: 8px;
  border: 1px solid #181e30;
  background: #141722;
  transition: background 0.3s ease;

  &:hover {
    background: #222531;
  }
`;

export const TableHeader: React.FC<TableHeader> = ({
  setValue,
  setSorter,
  isAdminPage,
  onOpenCreate,
  onOpenRestock,
  skip,
  setSkip,
}): React.ReactElement => {
  const isMobile = useMediaQuery("(max-width: 539px)")[0];

  const [searchValue, setSearchValue] = React.useState("");
  const [activeSort, setActiveSort] = useState("order:asc");

  const debounced = useDebouncedCallback((v) => {
    setValue(v);
    if (skip !== 0) {
      setSkip(0);
    }
  }, 400);

  const options = [
    {
      value: "order:asc",
      disabled: false,
      hidden: false,
      label: "ORDER",
    },
    {
      value: "quantity",
      disabled: false,
      hidden: false,
      label: "QUANTITY",
    },
    {
      value: "createdAt",
      disabled: false,
      hidden: false,
      label: "NEW",
    },
  ];

  const togglePriceSort = () => {
    const newSortOrder = activeSort === "price:desc" ? "price:asc" : "price:desc";
    setSorter(newSortOrder);
    setActiveSort(newSortOrder);
  };

  const handleSelectChange = (sorter: string) => {
    setSorter(sorter);
    setActiveSort(sorter);
  };

  return (
    <>
      <BoxContent id="pointShopContainerFlex">
        <BoxItem
          styles={css`
            flex-grow: 1;
          `}
        >
          <TheFieldset
            styles={css`
              width: 100%;
              border: 1px #1e213a solid;
              height: 50px;
              line-height: 35px;
              border-radius: 8px;
              background-image: url("/images/PachinkoComponent/search-prefix.png");
              background-repeat: no-repeat;
              background-size: 15px 15px;
              background-position: 1rem center;
              text-indent: 2rem;
            `}
          >
            <input
              type="text"
              placeholder="Search"
              value={searchValue}
              onChange={(event) => {
                setSearchValue(event.target.value);
                debounced(event.target.value);
              }}
            />
          </TheFieldset>
        </BoxItem>

        <Flex
          width="100%"
          justify="space-between"
          align="center"
          gap={8}
          style={{
            flexDirection: isMobile ? "column" : "row",
          }}
        >
          <ToggleButton
            onClick={togglePriceSort}
            style={{
              width: isMobile ? "100%" : "max-content",
            }}
          >
            <ButtonIcon
              style={{
                backgroundColor: activeSort.includes("price") ? "#222531" : "#141722",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
              >
                <path
                  d="M1.00312 2.00586H14.9937C15.5469 2.00586 15.9969 2.45586 15.9969 3.00898C15.9969 3.56211 15.5469 4.01211 14.9937 4.01211H1.00312C0.45 4.01523 0 3.56523 0 3.00898C0 2.45586 0.45 2.00586 1.00312 2.00586Z"
                  fill="#ABBADB"
                />
                <path
                  d="M1.00312 6.99609H9.93125C10.4844 6.99609 10.9344 7.44609 10.9344 7.99922C10.9344 8.55234 10.4844 9.00234 9.93125 9.00234H1.00312C0.45 9.00234 0 8.55234 0 7.99922C0 7.44609 0.45 6.99609 1.00312 6.99609Z"
                  fill="#ABBADB"
                />
                <path
                  d="M1.00312 11.9844H4.86875C5.42188 11.9844 5.87187 12.4344 5.87187 12.9875C5.87187 13.5406 5.42188 13.9906 4.86875 13.9906H1.00312C0.45 13.9938 0 13.5438 0 12.9906C0 12.4344 0.45 11.9844 1.00312 11.9844Z"
                  fill="#ABBADB"
                />
              </svg>
            </ButtonIcon>

            {activeSort === "price:desc" ? "From Low to High" : "From High to Low"}
          </ToggleButton>

          <TheSelect options={options} defaultValue="order:asc" setSorter={handleSelectChange} />

          {isAdminPage && (
            <>
              <TheButton
                preset="dark-blue"
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  onOpenRestock();
                }}
                styles={css`
                  height: 50px;
                `}
              >
                <Image
                  src="/images/PointShopComponent/refresh.png"
                  width={26}
                  height={24}
                  alt="Logo"
                />
                <ButtonTitle>RESTOCK</ButtonTitle>
              </TheButton>

              <Button
                onClick={(event: React.MouseEvent) => {
                  event.preventDefault();
                  onOpenCreate();
                }}
                styles={css`
                  height: 50px;
                `}
              >
                <ButtonBlue style={{ whiteSpace: "nowrap" }} transparent>
                  Add Item
                </ButtonBlue>
              </Button>
            </>
          )}
        </Flex>
      </BoxContent>
    </>
  );
};
