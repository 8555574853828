import React from "react";
import styled, { css, FlattenSimpleInterpolation } from "styled-components";
import { IStyles } from "./$styles";

export type TTheTableSetupKey = string;
export type TTableSetupTitle<T extends Object, O extends Object> =
  | React.ReactNode
  | ((props: ITheTableCell<T, O>) => React.ReactNode);
export type TTheTableCellRender<T extends Object, O extends Object> =
  | React.ReactNode
  | ((props: ITheTableCell<T, O>) => React.ReactNode);

export type TTheTableCellProps<T extends Object, O extends Object> = (
  props: ITheTableCell<T, O>
) => ITheTableCellProps<T, O>;
export type TTheTableRowProps<T extends Object, O extends Object> = (
  props: ITheTableRow<T, O>
) => ITheTableRowProps<T, O>;

export interface ITheTableCellProps<T extends Object, O extends Object>
  extends IStyles,
    Partial<Pick<ITheTableCell<T, O>, "isHead" | "isBody" | "rowId" | "$key" | "cellId">> {}
export interface ITheTableRowProps<T extends Object, O extends Object>
  extends IStyles,
    Partial<Pick<ITheTableRow<T, O>, "isHead" | "isBody" | "rowId">> {}

export interface ITheTableCell<T extends Object, O extends Object> extends ITheTableRow<T, O> {
  $key: TTheTableSetupKey;
  render: TTheTableCellRender<T, O>;
  title: TTableSetupTitle<T, O>;
  cellId?: number;
  cell?: any;
}
export interface ITheTableRow<T extends Object, O extends Object> extends ITheTable<T, O> {
  rowId: number;
  row: T | null;
  isHead?: boolean;
  isBody?: boolean;
  rowNumber?: number;
}

export interface ITheTableSetup<T extends Object, O extends Object> {
  $key: TTheTableSetupKey;
  render: TTheTableCellRender<T, O>;
  title: TTableSetupTitle<T, O>;
  options?: O;
}

export interface ITheTable<T extends Object, O extends Object> extends IStyles {
  $keys: TTheTableSetupKey[];
  $setup: ITheTableSetup<T, O>[];
  rows: T[];
  boxStyles?: FlattenSimpleInterpolation;
  CellProps?: TTheTableCellProps<T, O>;
  RowProps?: TTheTableRowProps<T, O>;
}

export function TheTable<T extends Object, O extends Object>(
  props: ITheTable<T, O>
): React.ReactElement {
  const { $keys, $setup, rows, styles, boxStyles } = props;
  const setupKeys: TTheTableSetupKey[] = $setup.map(({ $key }) => $key);
  const keys: TTheTableSetupKey[] = $keys.filter(($key) => setupKeys.includes($key));
  const setup = keys.map((key) => $setup.find((item) => item.$key === key) as ITheTableSetup<T, O>);
  const propsBase = {
    ...props,
    $keys: keys,
    $setup: setup,
    rowId: NaN,
    row: null,
  };

  return (
    <Box styles={boxStyles}>
      <Table styles={styles}>
        <THead>
          <TheTableRow<T, O> {...propsBase} isHead={true} />
        </THead>
        <TBody>
          {rows.map((row, rowId) => {
            const propsRow = { ...propsBase, rowId, row };
            return (
              <TheTableRow<T, O>
                key={(row as any).rowId ?? rowId}
                {...propsRow}
                isBody={true}
                rowNumber={rowId}
              />
            );
          })}
        </TBody>
      </Table>
    </Box>
  );
}

export function TheTableRow<T extends Object, O extends Object>(
  props: ITheTableRow<T, O>
): React.ReactElement {
  const {
    $setup,
    RowProps,
    isHead = false,
    isBody = false,
    rowId = NaN,
    row = null,
    rowNumber,
  } = props;

  const rowProps: ITheTableRowProps<T, O> = {
    isHead,
    isBody,
    rowId,
    ...(RowProps && RowProps({ ...props, rowNumber })),
  };

  return (
    <Tr {...rowProps}>
      {$setup.map((setup, cellId) => {
        const { $key, render, title } = setup;
        const propsCell: ITheTableCell<T, O> = {
          ...props,
          $key,
          render,
          title,
          cellId,
          cell: (row as any)?.[$key],
        };
        return <TheTableCell<T, O> key={cellId} {...propsCell} />;
      })}
    </Tr>
  );
}

export function TheTableCell<T extends Object, O extends Object>(
  props: ITheTableCell<T, O>
): React.ReactElement {
  const { CellProps, isHead, isBody, rowId, $key, render, title, cellId = NaN } = props;

  const cellProps: ITheTableCellProps<T, O> = {
    isHead,
    isBody,
    rowId,
    $key,
    cellId,
    ...(CellProps && CellProps(props)),
  };

  if (isHead) {
    const _ = typeof title === "function" ? title(props) : title;
    return <Td {...cellProps}>{_}</Td>;
  } else if (isBody) {
    const _ = typeof render === "function" ? render(props) : render;
    return <Td {...cellProps}>{_}</Td>;
  }
  return (
    <Td {...cellProps}>
      <Warn>? {$key ?? cellId} ?</Warn>
    </Td>
  );
}

const Box = styled.div<IStyles>`
  box-sizing: border-box;

  ${({ styles }) => styles}
`;

const Table = styled.table<IStyles>`
  box-sizing: border-box;
  display: table;
  width: 100%;
  overflow: hidden;

  // table
  table-layout: auto;
  /* border-collapse: collapse; */
  border-collapse: separate;
  border-spacing: 0 6px;
  border: none;

  ${({ styles }) => styles}
`;

const TBody = styled.tbody<IStyles>`
  box-sizing: border-box;
  display: table-row-group;

  ${({ styles }) => styles}
`;

const THead = styled.thead<IStyles>`
  box-sizing: border-box;
  display: table-row-group;
  font-family: var(-font-family-golos);

  ${({ styles }) => styles}
`;

const Tr = styled.tr<ITheTableRowProps<{}, {}>>`
  box-sizing: border-box;
  display: table-row;
  color: var(--daylight-color);
  font-family: var(--font-family-exo);
  font-size: 14px;
  font-style: italic;
  font-weight: 800;
  line-height: normal;
  text-transform: uppercase;
  height: 55px;
  ${({ isBody }) =>
    isBody
      ? css`
          background: radial-gradient(
              152.76% 130.7% at 50% 0%,
              rgba(101, 101, 101, 0.05) 0%,
              rgba(101, 101, 101, 0) 100%
            ),
            #151c2c;
          border: 1px solid #252c48;

          /* .item {
            overflow: hidden;
            max-height: 0;
            transition: max-height 300ms linear 300ms;

            &.active {
              max-height: 100px;
              transition-delay: 300ms;
            }
          } */
        `
      : null}

  ${({ styles }) => styles}
`;

const Td = styled.td<ITheTableCellProps<{}, {}>>`
  box-sizing: border-box;
  display: table-cell;
  padding: 8px;

  // table
  empty-cells: show;
  white-space: nowrap;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }

  ${({ styles }) => styles}
`;

const Warn = styled.div<IStyles>`
  color: red;

  ${({ styles }) => styles}
`;
