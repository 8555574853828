import styled from "styled-components";

export const Root = styled.button<{ active?: boolean }>`
  margin: 8px;
  display: flex;
  gap: 4px;
  min-width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
  background: ${({ active }) => (active ? "#00B2FF0D" : "#1b2035")};
  border: ${({ active }) => (active ? "none" : "1px solid #2a3046")};
  border-radius: 8px;
  color: #00b2ff;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.75;
  }
`;
