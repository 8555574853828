export enum ExceptionHandlingType {
  SILENT = "0",
  AUTOMATIC = "1",
  MANUAL = "2",
}

export interface ApiException {
  code: string;
  message: string;
  statusCode: number;
}
