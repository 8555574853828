import { SVGProps } from "react";

export type LogoutProps = SVGProps<SVGSVGElement>;

const Logout: React.FC<LogoutProps> = (props) => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="fi_2623065">
                <g id="Group">
                    <g id="Group_2">
                        <g id="_x30_1_Logout">
                            <g id="Group_3">
                                <g id="Vector">
                                    <path d="M3.55409 15.0156C4.03542 14.7812 4.61768 14.9883 4.85059 15.4727C5.49495 16.8203 6.49643 17.9609 7.75023 18.7734C9.03507 19.6055 10.5257 20.0469 12.0628 20.0469C16.4724 20.0469 20.0591 16.4375 20.0591 12C20.0591 7.5625 16.4724 3.95312 12.0628 3.95312C10.5257 3.95312 9.03507 4.39453 7.74634 5.22266C6.49255 6.03125 5.49107 7.17578 4.8467 8.52344C4.6138 9.00781 4.03543 9.21484 3.55021 8.98047C3.06888 8.74609 2.86315 8.16406 3.09605 7.67578C3.89568 6 5.13783 4.58203 6.69828 3.57812C8.29755 2.54688 10.153 2 12.0628 2C14.7179 2 17.2138 3.03906 19.0887 4.92969C20.9675 6.81641 22 9.32812 22 12C22 14.6719 20.9675 17.1836 19.0887 19.0703C17.21 20.9609 14.7179 22 12.0628 22C10.153 22 8.29755 21.4531 6.69828 20.418C5.14171 19.4102 3.89568 17.9922 3.09605 16.3203C2.86703 15.832 3.06888 15.25 3.55409 15.0156Z" fill="#ABBADB"/>
                                    <path d="M11.5465 15.8008C11.3563 15.9922 11.1079 16.0859 10.8595 16.0859C10.6111 16.0859 10.3626 15.9922 10.1724 15.8008L7.73082 13.3437C7.3737 12.9844 7.17962 12.5078 7.17962 12.0039C7.17962 11.5 7.37758 11.0195 7.73082 10.6641L10.1724 8.20703C10.5528 7.82422 11.1661 7.82422 11.5465 8.20703C11.927 8.58984 11.927 9.20703 11.5465 9.58984L10.1142 11.0312H15.6301C16.1658 11.0312 16.6005 11.4687 16.6005 12.0078C16.6005 12.5469 16.1658 12.9844 15.6301 12.9844H10.1103L11.5427 14.4258C11.9231 14.8008 11.9231 15.418 11.5465 15.8008Z" fill="#ABBADB"/>
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
        
    );
};

export default Logout;
