import Image from "next/image";
import React from "react";
import styled, { css } from "styled-components";
import { $stylesReset, IStyles, Hr, TotalGivenCounter, TotalGivenReward } from "components";
import { IAppContext, withApp } from "contexts";
import { socket } from "socket";
import { SocketEvent } from "helpers/constants";
import { Fade } from "react-awesome-reveal";
import { ExceptionHandlingType } from "types/apiServiceTypes";
import ArrowInCircle from "../../../public/images/icons/arrow-in-circle.svg";
import { Gift } from "layout/HeaderComponent/components/icons";

export interface ITotalGivenAwayComponent {
  id?: string;
  nextScrollHref?: string;
}

interface StatsProps {
  leaderboards: number;
  total: number;
  pointsShop: number;
  raffles: number;
  pachinko: number;
}

const TotalGivenAwayComponent: React.FC<ITotalGivenAwayComponent & IAppContext> = ({
  id,
  nextScrollHref = "#",
  accessFetch,
}): JSX.Element => {
  const [stats, setStats] = React.useState<StatsProps>({
    raffles: 0,
    pointsShop: 0,
    pachinko: 0,
    leaderboards: 0,
    total: 0,
  });
  const [isInfoOpen, setIsInfoOpen] = React.useState(false);
  const fetchData = React.useCallback(async () => {
    const response = await accessFetch("/stats", undefined, ExceptionHandlingType.SILENT);
    const data = await response?.json();
    setStats(data);
  }, [accessFetch]);

  React.useEffect(() => {
    socket.on(SocketEvent.DashboardStatsUpdate, (data) => {
      setStats(data);
    });

    return () => {
      socket.off(SocketEvent.DashboardStatsUpdate, () =>
        console.log("Not listening to DashboardStatsUpdate")
      );
    };
  }, []);

  React.useEffect(() => {
    fetchData(); // Initial data fetch
  }, [fetchData]);

  const styles = css`
    padding: 20px;
    gap: 12px;
    width: 100%;
    @media (max-width: 700px) {
      flex-directrion: column;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  `;

  return (
    <Box id={id} className="container">
      <TotalGABlock>
        <TotalGAHead>
          <Gift fill="var(--pure-sky)" height={50} width={50} />
          <TotalGATitle>
            <span>
              TOTAL <span data-blue>GIVEN AWAY</span>
            </span>
            <ToggleBlock onClick={() => setIsInfoOpen(!isInfoOpen)} isInfoOpen={isInfoOpen}>
              View detailed info
              <ArrowInCircle />
            </ToggleBlock>
          </TotalGATitle>
          <TotalGivenCounter length={7} value={stats?.total} />
        </TotalGAHead>

        <TotalGARewards isInfoOpen={isInfoOpen}>
          <TotalRewardList>
            <AnimatedScrollContainer delay={250} triggerOnce direction="up">
              <TotalGivenReward
                styles={styles}
                img={
                  <Img
                    src="/images/TotalGivenAwayComponent/Crown.png"
                    width={75}
                    height={49}
                    alt="crown"
                  />
                }
                title="Leaderboard"
                value={stats?.leaderboards}
              />
            </AnimatedScrollContainer>

            <AnimatedScrollContainer delay={450} triggerOnce direction="up">
              <TotalGivenReward
                styles={styles}
                img={
                  <Img
                    src="/images/TotalGivenAwayComponent/present.png"
                    width={75}
                    height={74}
                    alt="present"
                  />
                }
                title="Points Shop"
                value={stats?.pointsShop}
              />
            </AnimatedScrollContainer>

            <AnimatedScrollContainer delay={550} triggerOnce direction="up">
              <TotalGivenReward
                styles={styles}
                img={
                  <Img
                    src="/images/TotalGivenAwayComponent/ticket.png"
                    width={75}
                    height={56}
                    alt="tickets"
                  />
                }
                title="Raffles"
                value={stats?.raffles}
              />
            </AnimatedScrollContainer>
            <AnimatedScrollContainer delay={600} triggerOnce direction="up">
              <TotalGivenReward
                styles={styles}
                img={
                  <Img
                    src="/images/TotalGivenAwayComponent/pachinko.png"
                    width={97}
                    height={86}
                    alt="pachinko"
                    style={{
                      objectPosition: "bottom",
                    }}
                  />
                }
                title="Pachinko"
                value={stats?.pachinko}
              />
            </AnimatedScrollContainer>
          </TotalRewardList>
        </TotalGARewards>
      </TotalGABlock>
    </Box>
  );
};

export default withApp(TotalGivenAwayComponent);

const AnimatedScrollContainer = styled(Fade)`
  width: 100%;
`;

const Box = styled.div`
  padding: 16px;
`;

const TotalGABlock = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  background-color: var(--dark-space);
`;

const TotalGAHead = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 8px 8px 8px 24px;

  @media (max-width: 940px) {
    flex-direction: column;
    padding: 8px;
  }
`;

const TotalGATitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--clouds-100);
  font-family: var(--font-family-exo);
  font-size: 24px;
  font-style: italic;
  font-weight: 900;
  text-transform: uppercase;

  & [data-blue] {
    color: var(--pure-sky);
  }

  @media (max-width: 940px) {
    flex-direction: row;
    order: 2;
  }
  @media (max-width: 670px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ToggleBlock = styled.div<{ isInfoOpen: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  font-family: var(--font-family-golos);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  text-transform: none;
  cursor: pointer;

  & svg {
    transition: transform 0.3s;
    transform: ${({ isInfoOpen }) => (isInfoOpen ? "rotate(180deg)" : "rotate(0deg)")};
  }

  & path {
    transition: fill 0.3s;
    fill: ${({ isInfoOpen }) => (isInfoOpen ? "var(--pure-sky)" : "var(--clouds-100)")};
  }
`;

const TotalGARewards = styled.div<{ isInfoOpen: boolean }>`
  ${$stylesReset}
  overflow: hidden;
  transition: max-height 0.5s;
  max-height: ${({ isInfoOpen }) => (isInfoOpen ? "100vh" : "0")};

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 3px;
    background-color: var(--global-bg-dark-1);
    margin: 2px 0 10px 0;
  }
`;

const TotalRewardList = styled.div`
  ${$stylesReset}
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 87px;
  gap: 8px;
  width: 100%;
  padding: 0 8px 8px 8px;

  @media (max-width: 700px) {
    grid-auto-rows: auto;
  }

  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const Img = styled(Image)<IStyles>`
  ${({ styles }) => styles}
`;
