import { SVGProps } from "react";

export type StarProps = SVGProps<SVGSVGElement>;

const Star: React.FC<StarProps> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width="24px"
      height="24px"
    >
      <polygon
        points="32,5 39,25 60,25 43,37 50,55 32,45 14,55 21,37 4,25 25,25"
        fill={`
            ${props.fill ?? "#ABBADB"}
          `}
      />
    </svg>
  );
};

export default Star;
