import React from "react";

import { DualRingInner, DualRingLoader } from "components/common";
import { BoxItem } from "./ReviewModalLayout";
import RejectModal from "./RejectModal";
import { IAppContext, withApp } from "contexts";
import { useDisclosure } from "@chakra-ui/react";
import ChakraModal from "components/TheComponents/Modal";
import { TRANSACTION_STATUS } from "helpers/constants";
import WrewardsButton from "components/wrewards-ui-kit/Button";

const TransactionReviewActions = ({
  status,
  actionTransaction,
  style,
  isLoading,
}: {
  status: string;
  isLoading?: boolean;
  actionTransaction: ({
    action,
    reason,
  }: {
    action: string;
    reason: string | undefined;
  }) => Promise<void>;
  style?: object;
} & IAppContext) => {
  const { isOpen: isRejectOpen, onOpen: onOpenReject, onClose: onCloseReject } = useDisclosure();

  const isApproved = status === TRANSACTION_STATUS.APPROVED;

  return (
    <>
      <BoxItem>
        <WrewardsButton
          disabled={!isApproved && !!isLoading}
          variant={isApproved ? "game-action-red" : "game-action-green"}
          style={{
            height: 50,
            width: "100%",
            boxShadow: "none",

            ...style,
          }}
          onClick={() =>
            isApproved
              ? onOpenReject()
              : actionTransaction({ action: TRANSACTION_STATUS.APPROVED, reason: undefined })
          }
        >
          {!isApproved && !!isLoading ? (
            <DualRingLoader smallRing>
              <DualRingInner smallRing />
            </DualRingLoader>
          ) : isApproved ? (
            "Reject"
          ) : (
            "Approve"
          )}
        </WrewardsButton>
      </BoxItem>
      <ChakraModal
        isOpen={isRejectOpen}
        onClose={onCloseReject}
        content={
          <RejectModal
            isLoading={!!isLoading}
            actionTransaction={actionTransaction}
            onClose={onCloseReject}
          />
        }
      />
    </>
  );
};

export default withApp(TransactionReviewActions);
